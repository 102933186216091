import React, { useEffect, useState } from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  LoadingOverlay,
  Select,
  Switch,
} from "@mantine/core";
import { Link } from "react-router-dom";
import CardCollection from "../../../components/workshop/CardCollection";
import CardFormation from "../../../components/formation/CardCollection";
import CardTeamMeating from "../../../components/TeamMeeting/CardCollection";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import formation from "../../../api/formation";
import events from "../../../api/event";
import TeamMeating from "../../../api/teamMeeting";
import storage from "../../../auth/storage";
import InfosCountCardAccount from "../../../components/account/InfosCountCardAccount";
import users from "../../../api/users";
import { AiOutlineFrown } from "react-icons/all";
import Pagination from "@mui/material/Pagination";
import Footer from "../../Footer";

function EduAgenda() {
  const [data, setData] = useState<any>([]);
  const { id }: any = storage.getUser();
  const [isAllLoading, setIsAllLoading] = useState(false);
  const [checkedPassed, setCheckedPassed] = useState(false);
  const [checkedPilote, setCheckedPilote] = useState(false);
  const [checkedCoPilote, setcheckedCoPilote] = useState(false);
  const [checkedWorkshop, setCheckedWorkshop] = useState(true);
  const [checkedFormation, setcheckedFormation] = useState(true);
  const [checkedTeamMeating, setcheckedTeamMeating] = useState(true);
  const {
    data: dataWorkshop,
    loading: loadingWorkshop,
    request: requestWorkshop,
  } = useApi(workshop.collectionByFilterEdu);
  const {
    data: dataWorkshop2,
    loading: loadingWorkshop2,
    request: requestWorkshop2,
  } = useApi(workshop.collectionByFilterEdu);
  const {
    data: dataWorkshop3,
    loading: loadingWorkshop3,
    request: requestWorkshop3,
  } = useApi(workshop.collectionByFilterEdu);
  const {
    data: dataFormation,
    loading: loadingFormation,
    request: requestFormation,
  } = useApi(formation.collectionByFilterEdu);
  const {
    data: dataFormation2,
    loading: loadingFormation2,
    request: requestFormation2,
  } = useApi(formation.collectionByFilterEdu);
  const {
    data: dataFormation3,
    loading: loadingFormation3,
    request: requestFormation3,
  } = useApi(formation.collectionByFilterEdu);
  const {
    data: dataTeamMeating,
    loading: loadingTeamMeating,
    request: requestTeamMeating,
  } = useApi(TeamMeating.collectionByFilter);
  const {
    data: dataTeamMeating3,
    loading: loadingTeamMeating3,
    request: requestTeamMeating3,
  } = useApi(TeamMeating.collectionByFilterEdu);
  const {
    data: dataEvent,
    loading: loadingEvent,
    request: requestEvent,
  } = useApi(events.collectionByFilterEdu);

  const {
    data: dataEvent1,
    loading: loadingEvent1,
    request: requestEvent1,
  } = useApi(events.collectionByFilterEdu);
  const {
    data: dataUser,
    loading: loadingUser,
    request: requestUser,
  } = useApi(users.infos);
  const perPage = 10;
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [searchPlace, setSearchPlace] = useState<string>("");
  const [copilot, setCopilot] = useState(false); // ts-ignore

  useEffect(() => {
    setIsAllLoading(true);
    requestWorkshop("former", id);
    requestWorkshop3("guests", id);
    requestFormation("former", id);
    requestFormation3("guests", id);
    requestTeamMeating("former", id);
    requestTeamMeating3("guests", id);
    requestEvent("former", id).then((res) => {
      console.log("FORMER EVENTS",res.data)
    });
    requestEvent1("guests", id).then((res) => {
      console.log("GUESTS EVENTS",res.data)
    });
    requestUser(id);
  }, []);

  useEffect(() => {
    if (
      !loadingWorkshop &&
      !loadingWorkshop2 &&
      !loadingWorkshop3 &&
      !loadingFormation &&
      !loadingFormation2 &&
      !loadingFormation3 &&
      !loadingTeamMeating &&
      !loadingTeamMeating3 &&
      !loadingEvent &&
      !loadingEvent1 
    ) {
      const arrayData = [
        ...dataWorkshop,
        ...dataWorkshop2,
        ...dataWorkshop3,
        ...dataFormation,
        ...dataFormation2,
        ...dataFormation3,
        ...dataTeamMeating,
        ...dataTeamMeating3,
        ...dataEvent,
        ...dataEvent1,
      ];
      setData([...Array.from(new Set(arrayData))]);
      console.log([...Array.from(new Set(arrayData))])
      setIsAllLoading(false);
    }
  }, [
    dataWorkshop,
    dataFormation,
    dataWorkshop2,
    dataFormation2,
    dataWorkshop3,
    dataFormation3,
    dataTeamMeating,
    dataTeamMeating3,
    dataEvent,
    dataEvent1,
  ]);



  // useEffect(() => {
  //     if(data.length > 0){

  //     }
  // }, [data]);


  /*
  (checkedWorkshop
            ? checkedFormation
              ? checkedTeamMeating
                ? true
                : typeof w.isTeamMeating === "undefined"
              : checkedTeamMeating
              ? typeof w.isFormation === "undefined"
              : typeof w.isTeamMeating === "undefined" &&
                typeof w.isFormation === "undefined"
            : checkedFormation
            ? checkedTeamMeating
              ? typeof w.isWorkshop === "undefined"
              : typeof w.isWorkshop === "undefined" &&
                typeof w.isTeamMeating === "undefined"
            : checkedTeamMeating
            ? typeof w.isTeamMeating !== "undefined"
            : false)

            if (checkedPassed == true
        ? new Date(w.endDate) <= new Date() &&
        checkedPilote == true
            ? w.former?.id == id
            : w.former.id != id
        : new Date(w.endDate) >= new Date() &&
          checkedPilote == true
        ? w.former?.id == id
        : w.former.id != id
      /*&& (checkedCoPilote ? w.copilotApprouved.map((copilot:any) => copilot.id === token.id ? true : false ) :
            w.copilotApprouved.map((copilot:any) => copilot.id !== token.id ? false : true )
            )*/

  const filtered = !isAllLoading && data.filter((w: any) => {
      if (checkedPassed) {
        if(checkedPilote){
          return (
              w &&
              new Date(w.endDate).getTime() <= new Date().getTime()
              && w.former?.id == id
          );
        }else{
          return (
              w &&
              new Date(w.endDate).getTime() <= new Date().getTime()
              && w.former?.id != id
          );
        }
      } else {
        if(checkedPilote){
          return (
              w &&
              new Date(w.endDate).getTime() >= new Date().getTime()
              && w.former?.id == id
          );
        }else{
          return (
              w &&
              new Date(w.endDate).getTime() >= new Date().getTime()
              && w.former?.id != id
          );
        }
      }

  }).sort(
    (a: any, b: any) =>
      new Date(a.startDate).getTime() - new Date(b.startDate).getTime() 
  );


  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Mon Profil", href: `/auth/adherents/profil/${id}` },
    { title: "Mon agenda", href: `/auth/adherents/profil/agenda` },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));
  //console.log('dataUser', dataUser);

  return (
    <>
      <LoadingOverlay visible={loadingWorkshop} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl">Mon agenda pédagogique</div>
        <div className="grid grid-cols-3 gap-4 mb-10">
          <div className="col-span-2 mt-3 max-w-xl">
          
          </div>
          <div className="col-span-1">
            <InfosCountCardAccount
              title="Ateliers et formations pilotés"
              number={
                dataUser?.totalWorkshopCount + dataUser?.totalFormationCount
              }
            />
            <InfosCountCardAccount
              title="Ateliers et formations copilotés"
              number={
                dataUser?.totalWorkshopCopiloteCount +
                dataUser?.totalFormationsCopiloteCount
              }
            />
            <InfosCountCardAccount
              title="Ateliers / pilotés / copilotés et suivis "
              number={
                dataUser?.totalWorkshopCount + dataUser?.totalWorkshopGuestCount
              }
            />
            <InfosCountCardAccount
              title="Formations / pilotés / copilotés et suivis"
              number={
                dataUser?.totalFormationCount +
                dataUser?.totalFormationGuestCount
              }
            />
            <InfosCountCardAccount
              title="Ateliers et Formations suivis "
              number={
                dataUser?.totalFormationGuestCount +
                dataUser?.totalWorkshopGuestCount
              }
            />
          </div>
        </div>
        <div className="bg-white shadow rounded p-3 max-w-xs  text-left mb-3">
         
          <span className="text-buttonAccount font-bold text-lg">
            {filtered.length} événements {!checkedPassed && "à venir"}
          </span>
        </div>
        <div className="flex flex-wrap gap-4">
          <div className=" m-2">
            <Switch
              radius="xs"
              label="Passés"
              checked={checkedPassed}
              onChange={(event) =>
                setCheckedPassed(event.currentTarget.checked)
              }
            />
          </div>
          <div className=" m-2">
            <Switch
              radius="xs"
              label="Pilote"
              checked={checkedPilote}
              onChange={(event) =>
                setCheckedPilote(event.currentTarget.checked)
              }
            />
          </div>
          {/* <div className=" m-2">
                        <Switch radius="xs" label="Copilote"  checked={checkedCoPilote} onChange={(event) => setcheckedCoPilote(event.currentTarget.checked)} />
                    </div> */}
          {/*
          <div className=" m-2">
            <Switch
              radius="xs"
              label="Ateliers"
              checked={checkedWorkshop}
              onChange={(event) =>
                setCheckedWorkshop(event.currentTarget.checked)
              }
            />
          </div>

          <div className=" m-2">
            <Switch
              radius="xs"
              label="Formation"
              checked={checkedFormation}
              onChange={(event) =>
                setcheckedFormation(event.currentTarget.checked)
              }
            />
          </div>
          <div className=" m-2">
            <Switch
              radius="xs"
              label="Réunion d'équipe"
              checked={checkedTeamMeating}
              onChange={(event) =>
                setcheckedTeamMeating(event.currentTarget.checked)
              }
            />
          </div>
         */ }
        </div>
        <div className="m-2">
          {filtered.length ? (
            filtered
              .slice(page * perPage - perPage, perPage * page)
              .map((item: any) => {
                if (typeof item.isWorkshop !== "undefined") {
                  return <CardCollection data={item} />;
                }
                if (typeof item.isFormation !== "undefined") {
                  return <CardFormation data={item} />;
                }
                if (typeof item.isTeamMeating !== "undefined") {
                  return <CardTeamMeating data={item} />;
                }
                if (typeof item.isEvent !== "undefined") {
                  return <CardCollection data={item} />;
                }
              })
          ) : (
            <div className="flex py-10 title-font font-medium items-center md:justify-start justify-center text-gray-900">
              <span className="ml-3 text-xl">Vous n'avez pas d'évènements</span>
            </div>
          )}
        </div>
        {filtered.length > perPage ? (
          <div className="flex justify-center md:justify-end p-4">
            <Pagination
              count={Math.ceil(filtered.length / perPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              color="primary"
              hidePrevButton
              hideNextButton
              showFirstButton
              showLastButton
            />
          </div>
        ) : null}
        <Footer />
      </div>
    </>
  );
}

export default EduAgenda;
