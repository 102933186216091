import request from "./request";

const endpoint = '/dynasties'

const getAll = () => {
  return  request.get(endpoint)
}


const getDynasty = (id:number) => {
    return request.get(endpoint+ '/' + id )
}


const exportedDynasty = {
    getDynasty,getAll
}

export default exportedDynasty; 