
export const HABILITATION = [
    {value: 'nothing', label: "Aucune habilitation"},
    {value: 'All', label: "Toutes les habilitations"},
    { value: 'IMMOBILIER', label: 'Immobilier' },
    { value: 'IAS', label: 'IAS' },
    { value: 'CIF', label: 'CIF' },
    { value: 'IOBSP', label: 'IOBSP' },
    { value: 'AGENT', label: 'Agent lié' },
];

export const HABILITATION_USER = [
    { value: 'IMMOBILIER', label: 'Immobilier' },
    { value: 'IAS', label: 'IAS' },
    { value: 'CIF', label: 'CIF' },
    { value: 'IOBSP', label: 'IOBSP' },
    { value: 'AGENT', label: 'Agent lié' },
];

export const HABILITATION_WITHOUT_TOUS = [
    'IMMOBILIER',
    'IAS',
    'CIF',
    'IOBSP',
    'AGENT'
];

export const HABILITATION_WITHOUT_TOUS_NOTHING = [
    'nothing',
];