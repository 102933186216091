import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import event from "../../../api/event";
import CardCollection from "../../../components/event/CardCollection";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  LoadingOverlay,
  Select,
  Transition,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { DateRangePicker } from "@mantine/dates";
import "dayjs/locale/fr";
import HasRoles from "../../../components/Security/hasRoles";
import { ROLE_DXMAN_MORE, ROLE_XMAN } from "../../../constants/roles";
import { ImCross, BsPlusLg } from "react-icons/all";
import Pagination from "@mui/material/Pagination";
import Footer from "../../Footer";
import thematic from "../../../api/thematic";
import storage from "../../../auth/storage";
import site from "../../../api/site";
import exportedStorageFilters from "../../../filters/storage";

const ListingEvent = () => {
  const { id, role }: any = storage.getUser();
  const { data, loading, request } = useApi(event.collection);
  const [searchPlace, setSearchPlace] = useState<string>("");
  const [finalDate, setFinalDate] = useState<any>([0, 9999999999999]);
  const [page, setPage] = useState<number>(1);
  const perPage = 10;
  const [isRemove, setIsRemove] = useState(false);
  const [date, setDate] = useState<any>(null);
  const [filters, setFilters] = useState(false);

  const ResetSearch = () => {
    setSearchPlace("");
    setDate(null);
  };

  const [thematics, setThematics] = useState([]);
  const [sites, setSites] = useState([]);

  const { request: requestThematics, data: dataThematics } = useApi(
    thematic.collection,
  );
  // console.log("sites:",sites)

  const { request: requestSites, data: dataSites } = useApi(site.collection);

  // ts-ignore
  useEffect(() => {
    request();
    requestThematics("event").then((value) => {
      setThematics(
        value.data.map((thematic: any) => {
          return {
            value: thematic.wording,
            label: thematic.wording,
          };
        }),
      );
    });
    requestSites().then((value) => {
      setSites(
        value.data.map((site: any) => {
          //console.log(site);
          return {
            label: site.name,
            value: "" + site.id,
          };
        }),
      );
    });
    let storage = exportedStorageFilters.getEvent()
    if(storage){
      if(storage.place){
        setSearchPlace(storage.place);
      }
      if(storage.date1 && storage.date2){
        setDate([new Date(storage.date1), new Date(storage.date2)]);
      }
    }
    setIsRemove(true);
  }, []);

  useEffect(() => {

    if(searchPlace && searchPlace.length > 0){
      exportedStorageFilters.storePlaceEvent(searchPlace);
    }else{
      if (isRemove){
        exportedStorageFilters.removePlaceEvent()
      }
    }
    if (date !== null && date[0] !== null && date[1] !== null) {
      exportedStorageFilters.storeDateEvent({date1 : date[0], date2: date[1]});
    }else{
      if (isRemove){
        exportedStorageFilters.removeDateEvent()
      }
    }
  },[searchPlace, date])

  useEffect(() => {
    if (date !== null && date[0] !== null && date[1] !== null) {
      setFinalDate([date[0].getTime(), date[1].getTime()]);
    }
    if (date && date[0] == null && date[1] == null) {
      setFinalDate([0, 9999999999999]);
    }
  }, [date]);

  //filter events by thematic
  const filteredeventByThematic = data.filter(function (w: any) {
    if (w.site == null) {
      return (
        w &&
        new Date(w.startDate).getTime() >= finalDate[0] &&
        new Date(w.endDate).getTime() <= finalDate[1]
      );
    }

    if (w.site != null) {
      return (
        w &&
        w.site?.id?.toString().includes(searchPlace) &&
        new Date(w.startDate).getTime() >= finalDate[0] &&
        new Date(w.endDate).getTime() <= finalDate[1]
      );
    }
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Évenements", href: "/auth/event" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <>
      <LoadingOverlay visible={loading} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl">
          {filteredeventByThematic.length < 2 ? "ÉVENEMENT" : "ÉVENEMENTS"}
          {id == 3 && (
          <Link to="/auth/event/create">
              <Button className="ml-5 bg-gray-500 rounded" size="sm" compact>
                Créer
              </Button>
          </Link>
          )}
        </div>
        <div className="md:max-w-3xl mt-3 md:my-6 relative">
          <p className="text-sm md:text-md mr-6">
              Bienvenue sur la page Évènement. L'association vous permet de poursuivre votre professionnalisation : choisissez les modules qui vous intéressent et inscrivez-vous ! Nos formateurs bénévoles seront ravis de partager leur savoir.,
          </p>
        </div>

        {/* MOBILE FILTER */}
        <div className="flex justify-end mt-8 md:hidden">
          <button
            onClick={() => setFilters(!filters)}
            className="flex bg-gray-500 px-2 py-1 rounded text-white text-sm"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              />
            </svg>
          </button>
        </div>
        {filters ? (
          <div className="bg-gray-50 p-2 shadow-md mt-2 mb-4 rounded md:hidden">
            <div className="flex-1 m-2">
              <Select
                clearable
                placeholder="Sélectionner un lieu"
                data={[{ value: "", label: "Tous les lieux" }, ...sites]}
                value={searchPlace}
                onChange={(value) =>
                  setSearchPlace(value === null ? "" : value)
                }
              />
            </div>
            <div className="flex-1 m-2">
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                locale="fr"
                clearable
                placeholder="Sélectionner une période"
                value={date}
                onChange={(value) => setDate(value)}
              />
            </div>
            <div className="flex justify-end px-2">
              <button className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2">
                Tout afficher {filteredeventByThematic.length}
              </button>
            </div>
          </div>
        ) : null}
        {/* END MOBILE FILTER */}

        {/* DESKTOP FILTER */}
        <div className="md:flex justify-between hidden">
          <div className="flex flex-wrap p-3 pr-0">
            <div className="flex">
              <div className="m-2">
                <Select
                  clearable
                  placeholder="Sélectionner un lieu"
                  data={[{ value: "", label: "Tous les lieux" }, ...sites]}
                  value={searchPlace}
                  onChange={(value) =>
                    setSearchPlace(value === null ? "" : value)
                  }
                />
              </div>
            </div>
            <div className="m-2">
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                locale="fr"
                clearable
                placeholder="Sélectionner une période"
                value={date}
                onChange={(value) => setDate(value)}
              />
            </div>
          </div>
          <div className="m-2">
            <button
              onClick={ResetSearch}
              className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2"
              style={{ width: 120 }}
            >
              Tout afficher {filteredeventByThematic.length}{" "}
            </button>
          </div>
        </div>
        {/* END OF DESKTOP FILTER */}
        <div className="mt-2 md:mt-8 md:m-2">
          {filteredeventByThematic.length ? (
            filteredeventByThematic
              .slice(page * perPage - perPage, perPage * page)
              .map((event: any) => <CardCollection data={event} />)
          ) : (
            <span>Aucun Évenement n'a été trouvé</span>
          )}
        </div>
        {filteredeventByThematic.length > perPage ? (
          <div className="flex justify-center md:justify-end p-4">
            <Pagination
              count={Math.ceil(filteredeventByThematic.length / perPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              color="primary"
              hidePrevButton
              hideNextButton
              showFirstButton
              showLastButton
            />
          </div>
        ) : null}
        <Footer />
      </div>
    </>
  );
};

export default ListingEvent;
