import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import {
  Anchor,
  Badge,
  LoadingOverlay,
  SimpleGrid,
  Button,
  Grid,
  Col,
  Spoiler,
  Breadcrumbs,
  Text,
  Divider,
  ActionIcon,
  Tooltip,
  Popper,
} from "@mantine/core";
import { User } from "../../../entity/User";
import { useNotifications } from "@mantine/notifications";
import storage from "../../../auth/storage";
import { useModals } from "@mantine/modals";
import creditHistory from "../../../api/creditHistory";
import formation from "../../../api/formation";
import users from "../../../api/users";
import { FaUser } from "react-icons/fa";
import {
  FiUserCheck,
  FiUserX,
  IoIosCopy,
  IoMdOpen,
  MdOutlineCancel,
    FaTrash
} from "react-icons/all";
import { useClipboard, useWindowScroll } from "@mantine/hooks";

import HasRoles from "../../../components/Security/hasRoles";
import {
  ROLE_BEMAN,
  ROLE_BEMAN_MORE,
  ROLE_DXMAN_MORE,
  ROLE_XMAN,
} from "../../../constants/roles";
import { BiChevronLeft } from "react-icons/bi";
import { FaCopy } from "react-icons/fa";
import { RiFolderSharedLine } from "react-icons/ri";
import Footer from "../../Footer";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import workshop from "../../../api/workshop";
import { CSVLink, CSVDownload } from "react-csv";
import dayjs from "dayjs";
import { LinkProps } from "react-csv/components/Link";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import { Modal } from "@mantine/core";
import {
  format_d_MM_YYYY,
  format_d_MMMM_YYYY,
  format_dddd_d_MM_YYYY,
  format_dddd_d_MMMM_YYYY
} from "../../../utility/dateUtility";
import transactions from "../../../api/transactions";
import { formatItemName } from "../../../utility/formatItemName";

const ItemFormationWrapped = ({
  id,
  openedPaypal,
  setopenedPaypal,
  dataFormation,
  setDataFormation,
  totalPrice,
  setTotalPrice,
  setTotalBasePrice,
  Completed,
  setCompleted,
  setRequestLoading,
  requestLoading,
  request,
  loading,
}: any) => {
  const user: any = storage.getUser();
  const modals = useModals();
  const [godFatherEmails, setGodFatherEmails] = useState<any[]>([]);
  const [csv, setCsv] = useState<any[]>([]);
  const [isInWaitingZone, setIsInWaitingZone] = useState(false);
  const [isGuest, setIsGuest] = useState();
  const [isCopilot, setIsCopilot] = useState();
  const [isFormer, setIsFormer] = useState(Boolean);
  const notifications = useNotifications();
  const [canSeeGuest, setCanSeeGuest] = useState(false);
  const navigation = useNavigate();
  const { data: dataUser, request: requestGetUser } = useApi(users.me);
  const { request: requestEditUser } = useApi(users.edit);
  const clipboard = useClipboard({ timeout: 800 });
  const clipboard2 = useClipboard({ timeout: 800 });
  const [opened, setOpened] = useState(false);
  const [opened2, setOpened2] = useState(false);
  const [scroll, scrollTo] = useWindowScroll();
  const [creditId, setCreditId] = useState<string | number | null>(null);
    // @ts-ignore
  const [dataAvoir, setDataAvoir] = useState([]);
  const [transactionData, setTransactionData] = useState<{ id: number } | null>(null);
  const {request: getTransactionByAtelierUser} = useApi(transactions.getTransactionByAtelierUser);
  const {request: applyCreditOnTransaction} = useApi(transactions.applyCreditOnTransaction);
  const {request: requestTransaction} = useApi(transactions.CreateTransaction);
  const {request: getAvoirByUser} = useApi(transactions.getAvoirByUser);

  if(!openedPaypal){
    // @ts-ignore
    window.creditToPay = undefined;
  }


  useEffect(() => {
    // @ts-ignore
    setIsGuest(!!dataFormation?.guests.find((guest) => guest.id === user?.id));
    // @ts-ignore
    setIsCopilot(!!dataFormation?.coPilotWanted.find((coPilota) => coPilota.id === user?.id,),);
    // @ts-ignore
    setIsInWaitingZone(!!dataFormation?.waitingZone.find((guest) => guest.id === user?.id),);

    setIsFormer(dataFormation?.former?.id === user?.id);

    //console.log(dataFormation)
    setCsv(
        dataFormation?.guests.map((guest: any) => {
          return {
            prenom: guest.firstName,
            nom: guest.lastName,
            email: guest.email,
            telephone: "'" + guest.phoneNumber,
            parrain_prenom: guest.godFather
                ? guest.godFather?.firstName
                : "pas de parrain",
            parrain_nom: guest.godFather
                ? guest.godFather?.lastName
                : "pas de parrain",
            telephone_parrain:
                "'" + guest.godFather?.phoneNumber || "pas de parrain",
          };
        })
    );
    getTransactionByAtelierUser({user_id:user.id, atelier_id:id}).then((res: any) => {
        let result = res.data;
        let avoir = null;
        if (Array.isArray(result) && result.length > 0) {
            const transaction = result[0];
            setTransactionData(transaction);
        } else if (typeof result === 'object' && result !== null) {
            setTransactionData(result);
        } else {
            setTransactionData(null); // Si pas de transactions, réinitialiser l'état
        }
    });
    getAvoirByUser({user_id:user.id}).then((res: any) => {
        let result = res.data;
        if(result && result.avoir){
            setCreditId(result.avoir);
            setDataAvoir(result.avoirs)
        }
    });
  }, [dataFormation]);


  useEffect(() => {
    if (user.id == dataFormation?.former?.id) setCanSeeGuest(true)
    if (user.role[0] == "ROLE_XMAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_MAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_DXMAN") setCanSeeGuest(true)
    if (user.role[0] == "ROLE_DEVMAN") setCanSeeGuest(true)
    if (isGuest) setCanSeeGuest(true)
  }, [dataFormation, isGuest]);


  const handleCopilot = async () => {
    setRequestLoading(true);
    if (!isCopilot) {
      const result = await formation.registerCopilot(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'inscription n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Une notification a été envoyée au pilote ,Il validera votre co-animation si la place est toujours disponible et vous recevrez une notification. ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    if (isCopilot) {
      const result = await formation.unRegisterCopilot(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Vous n'êtes maintenant plus inscrit ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    request(id).then((r:any) => {
      setDataFormation(r.data);
    });
  };

  const handleRemoveGuest = async (userId: number) => {
    setRequestLoading(true);
    try {
      const result = await formation.unRegisterGuestByAdmin(Number(id), Number(userId));
        if (!result.ok) {
          notifications.showNotification({
            title: "Une erreur est survenue.",
            message: "L'annulation n'a pas pu aboutir ! 🤥",
            color: "red",
          });
          setRequestLoading(false);
        } else {
            notifications.showNotification({
                title: "Nice !",
                message: "L'invité a été désinscrit ! 😀",
                color: "green",
            });
            setRequestLoading(false);
          request(id).then((r:any) => {
            setDataFormation(r.data);
            requestGetUser();
          });
        }
    }catch (e) {
      console.log(e)
      notifications.showNotification({
        title: "Une erreur est survenue.",
        message: "L'annulation n'a pas pu aboutir ! 🤥",
        color: "red",
      });
      setRequestLoading(false);
    }
  }

  const handleGuest = async () => {
    //@ts-ignore
    window.creditToPay = 0;
    if (isGuest) {
      setRequestLoading(true);
      const result = await formation.unRegisterGuest(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: "L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        const date1 = new Date(dataFormation?.startDate);
        const date2 = new Date();
        // @ts-ignore
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        if (diffDays <= 7) {
          notifications.showNotification({
            title: "Nice !",
            message: "Vous n'êtes maintenant plus inscrit ! 😀",
            color: "green",
          });
          request(id).then((r:any) => {
            setDataFormation(r.data);
            requestGetUser();
          });
          setRequestLoading(false);
        } else {
          notifications.showNotification({
            title: "Nice !",
            message: "Vous n'êtes maintenant plus inscrit ! 😀",
            color: "green",
          });
          request(id).then((r:any) => {
            setDataFormation(r.data);
            requestGetUser();
          });

          setRequestLoading(false);
        }
      }
    }
    if (!isGuest) {
      setTotalPrice(dataFormation?.price);
      if (dataUser?.credit > 0) {
        if (dataUser?.credit >= dataFormation?.price) {
          let localPrice = dataFormation?.price;
          const result = await formation.registerGuest(Number(id));
          if (!result.ok) {
            notifications.showNotification({
              title: "Une erreur est survenue.",
              message: `L'inscription n'a pas pu aboutir ! 🤥`,
              color: "red",
            });
          } else {
            const newCredit = dataUser.credit - dataFormation.price;
            requestGetUser().then((res) => {
                requestTransaction({
                    user: res?.data?.id,
                    email: res?.data?.email,
                    lastName: res?.data?.lastName,
                    firstName: res?.data?.firstName,
                    society: res?.data?.society,
                    city: res?.data?.city,
                    address: res?.data?.address,
                    postalCode: res?.data?.postalCode,
                    createAt: new Date(),
                    paypalIdTransaction: "CREDIT PAYMENT",
                    paypalAccountStatus: "COMPLETED",
                    //@ts-ignore
                    paymentPaypalDate: new Date(),
                    paymentMethod: "PAYMENT_BY_CREDIT",
                    total: (0).toString(),
                    baseTotal: (dataFormation.price).toString(),
                    quantity: 1,
                    itemName: formatItemName("PAYMENT_BY_CREDIT", dataFormation.title),
                    workshop: undefined,
                    formation: Number(id),
                    idWorkshop: undefined,
                    IdFormation: Number(id),
                    disableChorusPro: true,
                })
                //@ts-ignore
                .then((response) => {
                  const transaction_id = response.data?.id;
                  applyCreditOnTransaction({
                    transaction_id,
                    credit_amount:localPrice,
                  })
                })
            })
            requestEditUser({ credit: newCredit }, dataUser.id).then(() => {
              creditHistory.save({
                user: dataUser.id,
                amount: - dataFormation.price,
                type: "débit",
                description: "Inscription à une formation",
                action: "register"
              }).then((response: any) => {
                const credit_id = response.data?.id;
                const transaction_id = transactionData?.id
            })
              notifications.showNotification({
                title: "Nice !",
                message: "Vous êtes maintenant inscrit ! 😀",
                color: "green",
              });
            });
          }
          request(id).then((r:any) => {
            setDataFormation(r.data);
            requestGetUser();
          });
        } else {
          setTotalPrice(dataFormation?.price - dataUser?.credit);
          setTotalBasePrice(dataFormation?.price);
          setopenedPaypal(true);
          //@ts-ignore
          window.creditToPay = dataUser?.credit;
        }
      } else {
        setTotalPrice(dataFormation?.price);
        setopenedPaypal(true);
      }
    }
  };

  // @ts-ignore
  const postPayment = () =>{
      // @ts-ignore
      const creditToPay = window.creditToPayFixed
      // @ts-ignore
      const lastInvoiceId = window.lastInvoiceId
      console.log({creditToPay})
      // @ts-ignore
      const transaction_id = lastInvoiceId;
      if(!creditToPay || !transaction_id){
          // @ts-ignore
          return true;
      }
      applyCreditOnTransaction({
          transaction_id,
          credit_amount:creditToPay,
      })
  }

  useEffect(() => {
    if (Completed === true) {
      setopenedPaypal(false);
      const register = async () => {
        setRequestLoading(true);
        const result = await formation.registerGuest(Number(id));
        if (!result.ok) {
          dataUser.credit = dataUser.credit + totalPrice;
          requestEditUser({ credit: dataUser.credit }, dataUser.id).then(() => {
            creditHistory.save({
              user: dataUser.id,
              amount: totalPrice,
              type: "crédit",
              description: "Echec d'une inscription à une formation",
              action: "error"
            }).then((response: any) => {
                const credit_id = response.data?.id;
                const transaction_id = transactionData?.id
            })
          });
          notifications.showNotification({
            title: "Une erreur est survenue.",
            message: `L'inscription n'a pas pu aboutir ! 🤥`,
            color: "red",
          });
        } else {
          postPayment();
          if (dataUser.credit > 0) {
            dataUser.credit =
                dataUser.credit - (dataFormation.price - totalPrice);
            requestEditUser({ credit: dataUser.credit }, dataUser.id).then(() => {
              creditHistory.save({
                user: dataUser.id,
                amount: - (dataFormation.price - totalPrice),
                type: "débit",
                description: "Inscription à une formation",
                action: "register"
              }).then((response: any) => {
                const credit_id = response.data?.id;
                const transaction_id = transactionData?.id
            })
            });
          }
          notifications.showNotification({
            title: "Nice !",
            message: "Vous êtes maintenant inscrit ! 😀",
            color: "green",
          });
        }
      };
      register().then(() => {
        request(id).then((r:any) => {
          setDataFormation(r.data);
        });
        requestGetUser();
      });
      setCompleted(false);
      setRequestLoading(false);
    }
  }, [Completed]);

  const handleRemove = async () => {
    setRequestLoading(true);
    const result = await formation.remove(Number(id));
    if (!result.ok) {
      notifications.showNotification({
        title: "Une erreur est survenue.",
        message: "La formation n'a pas pu être supprimée ! 🤥",
        color: "red",
      });
      setRequestLoading(false);
    } else {
      notifications.showNotification({
        title: "Nice !",
        message: "Formation supprimée ! 😀",
        color: "green",
      });
      setRequestLoading(false);
      navigation("/auth/formation", { replace: true });
    }
  };

  const handleGuestWaitingZone = async () => {
    setRequestLoading(true);
    if (isInWaitingZone) {
      const result = await formation.unRegisterInWaitingZone(Number(id));
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'annulation n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message:
            "Vous n'êtes maintenant plus inscrit dans la file d'attente ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    if (!isInWaitingZone) {
      const result = await formation.registerInWaitingZone(Number(id));
      console.log(result);
      if (!result.ok) {
        notifications.showNotification({
          title: "Une erreur est survenue.",
          message: " L'inscription n'a pas pu aboutir ! 🤥",
          color: "red",
        });
        setRequestLoading(false);
      } else {
        notifications.showNotification({
          title: "Nice !",
          message: "Vous êtes maintenant inscrit dans la file d'attente ! 😀",
          color: "green",
        });
        setRequestLoading(false);
      }
    }
    request(id).then((r:any) => {
      setDataFormation(r.data);
    });
  };

  useEffect(() => {
    // @ts-ignore
    request(id).then((r:any) => {
      if (!r.data.destined.find((item: any) => item == user.role)) {
        navigation("/");
      } else {
        setDataFormation(r.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    window.scrollTo(0, 0);
    requestGetUser();
  }, []);

  useEffect(() => {
    if (dataFormation?.guests?.length > 0) {
      const godFather: any[] = [];
      dataFormation.guests.forEach((guest: any) => {
        if (guest.godFather) {
          godFather.push(
            dataFormation.guests.filter(
              (g: any) => g.id === guest.godFather.id,
            ),
          );
        }
      });
      setGodFatherEmails(godFather);
    }
  }, [dataFormation?.guests]);

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Formations", href: "/auth/formation" },
    {
      title:
        dataFormation?.title +
        " le " +
        format_d_MM_YYYY(dataFormation?.startDate) +
        " à " +
        dataFormation?.site?.name,
      href: `/auth/formation/detail/${id}`,
      ref: dataFormation?.site?.name,
    },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  const confirm = () => {
    const id = modals.openModal({
      title: "Copilote",
      transition: "fade",
      transitionDuration: 600,
      transitionTimingFunction: "ease",
      children: (
        <>
          <Text size="sm" className="my-5">
            Vous êtes sur le point de proposer votre profil comme copilote pour
            la formation {dataFormation?.title} du{" "}
            {format_dddd_d_MM_YYYY(dataFormation?.startDate)}{" "}
            à {dataFormation?.site?.name} organisé par{" "}
            {dataFormation?.former.lastName} {dataFormation?.former.firstName}.
          </Text>
          <SimpleGrid cols={2}>
            <Button
              color="dark"
              fullWidth
              onClick={() => modals.closeModal(id)}
            >
              Annuler
            </Button>
            <Button
              variant="default"
              fullWidth
              onClick={() => {
                handleCopilot();
                modals.closeModal(id);
              }}
            >
              Confirmer
            </Button>
          </SimpleGrid>
        </>
      ),
    });
  };

  const confirmCancel = () => {
    const id = modals.openModal({
      title: "Copilote",
      transition: "fade",
      transitionDuration: 600,
      transitionTimingFunction: "ease",
      children: (
        <>
          <Text size="sm" className="my-5">
            Vous êtes sur le point d'annuler votre profil comme copilote pour la
            formation {dataFormation?.title} du{" "}
            {format_dddd_d_MM_YYYY(dataFormation?.startDate)}{" "}
            à {dataFormation?.site?.name} organisé par{" "}
            {dataFormation?.former.lastName} {dataFormation?.former.firstName}.
          </Text>
          <SimpleGrid cols={2}>
            <Button
              color="dark"
              fullWidth
              onClick={() => modals.closeModal(id)}
            >
              Annuler
            </Button>
            <Button
              variant="default"
              fullWidth
              onClick={() => {
                handleCopilot();
                modals.closeModal(id);
              }}
            >
              Confirmer
            </Button>
          </SimpleGrid>
        </>
      ),
    });
  };

  // @ts-ignore
  return (
    <>
      <LoadingOverlay visible={loading} />
      <LoadingOverlay visible={requestLoading} />
      <div className="absolute left-60 top-5">
        <Link to="/auth/formation">
          <Button
              className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
              color="lime"
              size="xs"
              variant="filled"
          >
            Retour à la liste
          </Button>
        </Link>
      </div>
      {/* Breadcrumbs & buttons */}

      <div
        className="w-screen md:w-auto px-8 py-2 md:pb-4"
        style={{ fontFamily: "aktiv-grotesk,sans-serif" }}
      >
        <Breadcrumbs
          className="my-5 text-gray-800"
          separator="/"
          styles={{ separator: { color: "#333" } }}
        >
          {items}
        </Breadcrumbs>
      </div>
      {/* end */}

      {/* banderole */}
      <div className="bg-white shadow-md md:flex">
        <div
            className={`py-5 px-6 ${
                !isCopilot && !isFormer ? "md:w-8/12" : "w-full mr-10"
            }`}
        >
          <h2 className="font-bold mb-1 text-thirteen text-center md:text-left text-gray-600">
            {dataFormation?.thematic}
          </h2>
          <h2 className="text-4xl mb-6 font-extralight text-center md:text-left text-gray-900">
            {dataFormation?.title}
          </h2>
          {window.innerWidth < 768 ? (
              <>
                <p className="text-thirteen text-center text-gray-600 uppercase">
                  {format_d_MMMM_YYYY(dataFormation?.startDate)}
                </p>
                <p className="text-thirteen text-center text-gray-600">
                  {dataFormation?.site?.address} {dataFormation?.site?.postalCode}{" "}
                  {dataFormation?.site?.city}
                </p>{" "}
              </>
          ) : null}

          <div className="md:flex mt-6 items-center md:mt-0 md:justify-between">
            <div className="flex">
              <img
                  className="w-12 h-12 rounded-md"
                  src={profilePicture}
                  alt="profile"
              />
              <div className="ml-4">
                <span className="text-ten leading-3 text-gray-600 font-bold block">
                  PILOTE
                </span>
                <span className="text-thirteen text-gray-600">
                  {dataFormation?.former.firstName}{" "}
                  <span className="uppercase text-thirteen">
                    {dataFormation?.former.lastName}
                  </span>
                </span>
              </div>
            </div>
            {window.innerWidth > 768 ? (
                <span className="text-thirteen float-right text-gray-600 text-right uppercase">
                {format_d_MMMM_YYYY(dataFormation?.startDate)}
              </span>
            ) : null}
          </div>

          <div className="mt-3 md:flex md:justify-between">
            <HasRoles role={ROLE_BEMAN_MORE} checkUser>
              {
                  dataFormation?.wantCopilot && (
                      <Anchor
                          className="text-gray-600"
                          onClick={!isCopilot ? confirm : confirmCancel}
                      >
                        {!isCopilot ? (
                            <h2 className="inline-block text-thirteen hover:underline">
                              <FaUser className="w-6 h-6 inline-block mb-1 ml-3 mr-6" />{" "}
                              Se proposer comme copilote{" "}
                            </h2>
                        ) : (
                            <span className="inline-block text-thirteen hover:underline">
                    {" "}
                              <MdOutlineCancel
                                  color="red"
                                  className="inline-block mb-1 mr-3"
                              />
                    Annuler ma demande de copilote{" "}
                  </span>
                        )}
                      </Anchor>
                  )
              }
            </HasRoles>
            {window.innerWidth > 768 ? (
                <span className="text-thirteen text-gray-600 float-right">
                {dataFormation?.site?.address} {dataFormation?.site?.postalCode}{" "}
                  {dataFormation?.site?.city}
              </span>
            ) : null}
          </div>
        </div>

        <div
            className={`m-auto text-center ${
                !isCopilot && !isFormer ? "md:w-2/12" : "hidden"
            }`}
        >
          <div className="text-center w-screen md:w-auto">
            {!isGuest &&
                dataFormation?.totalGuests >= dataFormation?.maxGuests &&
                !isInWaitingZone &&
                !isCopilot &&
                !isFormer && (
                    <>
                      <h2 className="text-yellow-500 font-bold text-thirteen text-center">
                        Complet
                      </h2>
                      <button
                          onClick={() => console.log()}
                          className="w-full text-white bg-yellow-500 text-thirteen p-2 mt-3 font-bold rounded"
                      >
                        Voir les prochaines dates
                      </button>
                      <button
                          onClick={() => handleGuestWaitingZone()}
                          className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                      >
                        {!isInWaitingZone
                            ? "S'inscrire sur la liste d'attente"
                            : "Se retirer de la file d'attente"}
                      </button>
                      <div className="text-thirteen text-gray-600 text-left">
                        Déjà{" "}
                        <span className="font-bold  text-thirteen text-gray-600">
                      {dataFormation?.waitingZone?.length} personne(s)
                    </span>
                      </div>
                    </>
                )}
            {isInWaitingZone && (
                <>
                  <button
                      onClick={() => handleGuestWaitingZone()}
                      className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                  >
                    {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                  </button>
                </>
            )}
            {!dataFormation?.isUniqueWaiting && !isGuest &&
                dataFormation?.totalGuests < dataFormation?.maxGuests &&
                !isCopilot &&
                !isFormer && (
                    <button
                        onClick={() => handleGuest()}
                        className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                    >
                      Je m'inscris {dataFormation?.price} €
                    </button>
                )}
                {dataFormation?.isUniqueWaiting && isInWaitingZone && !isGuest &&
                dataFormation?.totalGuests < dataFormation?.maxGuests &&
                !isCopilot &&
                !isFormer && (
                    <button
                        onClick={() => handleGuest()}
                        className="bg-pink-600 w-11/12 font-bold text-thirteen py-5 text-white rounded-md"
                    >
                      Je m'inscris {dataFormation?.price} €
                    </button>
                )}
            {isGuest && (
                <>
                  <FiUserCheck size="30" className="text-green-600 mx-auto" />
                  <h2 className="text-green-600 font-bold text-thirteen text-center mb-3">
                    Déjà inscrit
                  </h2>
                </>
            )}
          </div>
          {!isGuest && dataFormation?.totalGuests < dataFormation?.maxGuests && (
              <>
                <div
                    className={`flex ${
                        window.innerWidth < 768
                            ? "justify-around md:justify-between mx-4 pb-2"
                            : "flex-col"
                    } mt-1`}
                >
                  <div className="text-thirteen text-gray-600 inline-block">
                    INSCRITS:{" "}
                    <span className="font-bold text-gray-600 text-thirteen">
                    {dataFormation?.totalGuests} / {dataFormation?.maxGuests}
                  </span>
                  </div>
                  <div className="text-thirteen text-gray-600 inline-block mr-1">
                    Vos crédits :{" "}
                    <span className="font-bold text-thirteen">{dataUser?.credit} €</span>
                  </div>
                </div>
              </>
          )}
        </div>
      </div>

      <div className="w-screen md:w-auto px-6 py-2 md:p-12 md:pt-5">
        <div className="md:flex md:justify-between mt-8">
          <div className="md:w-8/12">
            <div className="my-10">
              <h2 className="text-xl mb-4">Pré-requis / Objectif</h2>
              <div className="bg-white shadow p-3 rounded">
                <p
                  className="break-all"
                  dangerouslySetInnerHTML={{
                    __html: dataFormation?.prerequisite,
                  }}
                />
              </div>
            </div>

            <div className="my-10">
              <h2 className="text-xl mb-4">Description / Mot du formateur</h2>
              <div className="bg-white shadow p-3 rounded">
                <p
                  className="break-all"
                  dangerouslySetInnerHTML={{
                    __html: dataFormation?.formerWords,
                  }}
                />
              </div>
            </div>

            {user?.role && user?.role[0] != "ROLE_GUEST" && (
            <div className="md:flex md:justify-between">
              <div className="md:w-5/12">
                <h2 className="text-xl">Public visé</h2>
                <div className="bg-white shadow p-3 mt-2 rounded">
                  {!dataFormation?.destined ||
                    (dataFormation?.destined.length == 0 && (
                      <p className="text-sm">Aucun public visé</p>
                    ))}
                  {dataFormation?.destined &&
                    dataFormation?.destined.map(
                      (name: string, index: Number) => (
                        <Badge
                          className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                          variant="filled"
                        >
                          {name == "ROLE_GUEST" ? "INVITÉ" : name.slice(5)}
                        </Badge>
                      ),
                    )}
                </div>
              </div>

              <div className="md:w-5/12 mt-8 md:mt-0">
                <h2 className="text-xl">Habilitations nécessaires</h2>
                <div className="bg-white shadow p-3 mt-2 rounded">
                  {!dataFormation?.habilitation ||
                    (dataFormation?.habilitation.length == 0 && (
                      <p className="text-sm">Aucune habilitation nécessaire</p>
                    ))}
                  {dataFormation?.habilitation &&
                    dataFormation?.habilitation.map(
                      (name: string, index: Number) => (
                        <Badge
                          className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                          variant="filled"
                        >
                          {name}
                        </Badge>
                      ),
                    )}
                </div>
              </div>
            </div>)}

            <div className="flex justify-evenly mx-auto mt-10 items-center space-x-2">
              <HasRoles role={ROLE_DXMAN_MORE} checkIsAuthor checkUser datas={dataFormation}>
                <Link to={"/auth/formation/edit/" + id}>
                  <Button
                      className="text-sm hover:text-white hover:bg-yellow-600 w-auto bg-gray-100 text-gray-400 shadow"
                      color="lime"
                      size="xs"
                      variant="filled"
                  >
                    Modifier
                  </Button>
                </Link>
              </HasRoles>
              <HasRoles role={ROLE_DXMAN_MORE} checkIsAuthor checkUser datas={dataFormation}>
                <Button
                    onClick={() => handleRemove()}
                    className="text-sm hover:text-white hover:bg-red-600 w-auto bg-gray-100 text-gray-400 shadow"
                    color="lime"
                    size="xs"
                    variant="filled"
                >
                  Supprimer
                </Button>
              </HasRoles>
            </div>

            {window.innerWidth > 768 ? <div className="my-10"></div> : null}
          </div>

          <div className="md:w-3/12">
            <div>
              <h2 className="text-xl md:text-right mt-8 md:mt-0 mb-2">
                Date et Horaires
              </h2>
              <div className="bg-white shadow p-3 mt-5 rounded">
                <h2 className="text-sm text-gray-600 md:text-right">
                  Date et Horaires
                </h2>
                <h4 className="text-base text-gray-600 font-bold md:text-right">
                  {dataFormation?.startDate == dataFormation?.endDate && format_dddd_d_MMMM_YYYY(dataFormation?.startDate)}
                  {dataFormation?.startDate != dataFormation?.endDate && format_dddd_d_MMMM_YYYY(dataFormation?.startDate) + " - " + format_dddd_d_MMMM_YYYY(dataFormation?.endDate)}
                </h4>

                {dataFormation?.formationDates?.map(
                  (date: any, index: Number) => {
                    const startDate = new Date(date.dateAt);
                    startDate.setHours(startDate.getHours() - 1);
                    const endAt = new Date(date.endAt);
                    endAt.setHours(endAt.getHours() - 1);
                    return (
                      <span className="text-sm block text-gray-600 md:text-right">
                        {startDate.toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}{" "}
                        -{" "}
                        {endAt.toLocaleTimeString("fr-FR", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    );
                  },
                )}
              </div>
            </div>

            {!dataFormation?.isVisio && (
              <div>
                <h2 className="text-xl md:text-right mt-8 mb-2">Lieu</h2>
                <div className="bg-white shadow p-3 mt-5 rounded-b-none rounded-t text-right">
                  <h4 className="text-md">{dataFormation?.site?.address}</h4>
                  <h4 className="text-md">
                    {dataFormation?.site?.postalCode}{" "}
                    {dataFormation?.site?.city}
                  </h4>
                </div>
                {dataFormation?.site?.gmap && (
                  <a
                    href={dataFormation?.site?.gmap}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button
                      radius="xs"
                      className="bg-gray-600 px-10 rounded-none rounded-b"
                      size="md"
                      variant="filled"
                      fullWidth
                    >
                      <span className="text-sm">Voir la carte interactive</span>
                    </Button>
                  </a>
                )}
              </div>
            )}

            {canSeeGuest && (
            <div className="bg-white shadow p-3 mt-8 rounded text-center">
              {dataFormation?.totalGuests >= dataFormation?.maxGuests ? (
                <h2 className="text-yellow-500 font-bold text-xl text-center">
                  Complet
                </h2>
              ) : (
                <>
                  <h2 className="text-pink-600 text-3xl font-bold ">
                    {dataFormation?.totalGuests} inscrits
                  </h2>
                  <h2 className="text-pink-600 text-xl ">
                    sur {dataFormation?.maxGuests} places
                  </h2>
                </>
              )}

              <Spoiler
                maxHeight={60}
                showLabel="Voir plus d'inscrits"
                hideLabel="Moins"
                transitionDuration={100}
              >
                <div className="pb-5">
                  {dataFormation?.guests.map((guest: User, index: Number) => (
                    <div key={"" + index} className="mt-2 mb-1 flex items-center justify-center">
                      <p className="text-sm">
                        {guest.lastName} {guest.firstName}
                      </p>
                      {user.id ==3 && (
                          <FaTrash onClick={() => handleRemoveGuest(guest.id)} className={"w-6 pl-3 cursor-pointer hover:text-red-600"} />
                      )}

                    </div>
                  ))}
                </div>
              </Spoiler>

              {dataFormation?.totalGuests >= dataFormation?.maxGuests && (
                  <div className="bg-white shadow p-3 mt-8 rounded text-center">
                    <h2 className="text-pink-600 text-thirteen font-bold ">
                      {dataFormation?.waitingZone?.length} inscrits dans la file d'attente
                    </h2>
                    <Spoiler
                        maxHeight={60}
                        showLabel="Voir plus d'inscrits"
                        hideLabel="Moins"
                        transitionDuration={100}
                    >
                      <div className="pb-5">
                        {dataFormation?.waitingZone.map(
                            (guest: User, index: Number) => (
                                <div key={"" + index} className="mt-2 mb-1">
                                  <p className="text-thirteen">
                                    {guest.lastName} {guest.firstName}
                                  </p>
                                </div>
                            )
                        )}
                      </div>
                    </Spoiler>
                  </div>
              )}
            </div>
            )}
            {!isGuest &&
              dataFormation?.totalGuests >= dataFormation?.maxGuests && !isInWaitingZone && (
                <>
                  <Button
                    onClick={() => console.log()}
                    className="px-10 mt-3"
                    size="md"
                    color="orange"
                    variant="filled"
                    fullWidth
                  >
                    <span className="text-sm">Voir les prochaines dates</span>
                  </Button>
                  <Button
                    onClick={() => handleGuestWaitingZone()}
                    className="px-10 mt-5"
                    size="md"
                    color="gray"
                    variant="filled"
                    fullWidth
                  >
                    <span className="text-sm">
                      {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                    </span>
                  </Button>
                </>
              )}
            {isInWaitingZone && (
                <>
                  <button
                      onClick={() => handleGuestWaitingZone()}
                      className="w-full text-white bg-gray-500 text-thirteen p-2 mt-3 font-bold rounded"
                  >
                    {!isInWaitingZone
                        ? "S'inscrire sur la liste d'attente"
                        : "Se retirer de la file d'attente"}
                  </button>
                </>
            )}

            {window.innerWidth < 768 ? (
              <div className="text-center mt-8">
                {!dataFormation?.isUniqueWaiting && !isGuest &&
                  dataFormation?.totalGuests < dataFormation?.maxGuests && (
                    <button
                      onClick={() => handleGuest()}
                      className="bg-pink-600 w-full py-5 text-white rounded-md"
                    >
                       Je m'inscris {dataFormation?.price} €
                    </button>
                  )}
                  {dataFormation?.isUniqueWaiting && isInWaitingZone  && !isGuest &&
                  dataFormation?.totalGuests < dataFormation?.maxGuests && (
                    <button
                      onClick={() => handleGuest()}
                      className="bg-pink-600 w-full py-5 text-white rounded-md"
                    >
                       Je m'inscris {dataFormation?.price} €
                    </button>
                  )}
                {isGuest && (
                  <>
                    <FiUserCheck size="30" className="text-green-600 mx-auto" />
                    <h2 className="text-green-600 font-bold text-xl text-center mb-3">
                      Déjà inscrit
                    </h2>
                  </>
                )}
                <div className="flex justify-between mt-1">
                  <div className="text-sm text-gray-600 inline-block">
                    inscrits:{" "}
                    <span className="font-bold text-gray-600">
                      {dataFormation?.totalGuests} / {dataFormation?.maxGuests}
                    </span>
                  </div>
                  <div className="text-sm text-gray-600 inline-block font-bold">
                    {dataFormation?.price} €
                  </div>
                </div>
              </div>
            ) : null}

            <button
              onClick={() => {
                clipboard2.copy(
                  dataFormation?.guests
                    .map((guest: any) => guest.email)
                    .join(", "),
                );
                setOpened((o) => !o);
              }}
              className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
            >
              <p
                className={`flex text-gray-400 font-medium float-right ${
                  clipboard2.copied ? "text-green-400" : "text-gray-400"
                }`}
              >
                {clipboard2.copied ? "Copié" : "Copier les emails INSCRITS"}{" "}
                <FaCopy className="ml-1 mt-1" />
              </p>
            </button>

            <button
                onClick={() => {
                  clipboard.copy(
                      dataFormation?.guests
                          .map((guest: any) => guest.godFather ? guest.godFather.email ?? "aucun" : "aucun")
                          .join("; "),
                  );
                  setOpened((o) => !o);
                }}
                className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full"
            >
              <p
                  className={`flex text-gray-400 font-medium bg-white p-2 text-thirteen float-right ${
                      clipboard.copied ? "text-green-400 " : "text-gray-400"
                  }`}
              >
                {clipboard.copied ? "Copié" : "Copier les emails PARRAINS"}{" "}
                <FaCopy className="ml-1 mt-1" />
              </p>
            </button>

            <button className="hidden md:block relative mt-4 bg-white rounded-md p-2 w-full">
              {!loading && csv && csv.length > 0 && (
                <p className="flex text-gray-400 font-medium float-right">
                  { //@ts-ignore
                   <CSVLink
                       separator=";"
                    filename={"liste de " + dataFormation?.thematic + ".csv"}
                    data={csv}
                  >
                    Extraire la liste{" "}
                  </CSVLink>
                   }
                  <RiFolderSharedLine className="ml-1 mt-1" />
                </p>
              )}
            </button>

            {dataFormation?.isVisio && (
              <div className="col-span-1 lg:col-span-2 mr-10 mt-5">
                <h2 className="text-xl text-right my-2">Visioconférence</h2>
                <div className="bg-white shadow p-3 mt-3 rounded-b-none rounded-t">
                  <h4 className="text-xs">Participer à la réunion Zoom</h4>
                  <Divider className="my-2" />
                  <Tooltip
                    className="float-right justify-center pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Copier le lien"
                  >
                    <Tooltip
                      delay={500}
                      label="Copié !"
                      position="right"
                      opened={opened}
                    >
                      <ActionIcon
                        className="float-right justify-center pb-2"
                        component="button"
                        onClick={() => {
                          clipboard.copy(dataFormation?.videoConferenceLink);
                          setOpened((o) => !o);
                        }}
                      >
                        <IoIosCopy size={15} />
                      </ActionIcon>
                    </Tooltip>
                  </Tooltip>
                  <Tooltip
                    className="float-right justify-center pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Ouvrir le lien"
                  >
                    <ActionIcon
                      className="float-right justify-center pb-2"
                      component="button"
                      onClick={() =>
                        window.open(
                          dataFormation?.videoConferenceLink,
                          "_blank",
                        )
                      }
                    >
                      <IoMdOpen size={15} />
                    </ActionIcon>
                  </Tooltip>
                  <h4 className="text-xs ">Lien</h4>
                  <Divider className="my-2" />
                  <Tooltip
                    className="float-right justify-center pb-2"
                    wrapLines
                    withArrow
                    transition="fade"
                    transitionDuration={200}
                    label="Copier le mot de passe"
                  >
                    <Tooltip
                      delay={500}
                      position="right"
                      label="Copié !"
                      opened={opened2}
                    >
                      <ActionIcon
                        component="button"
                        onClick={() => {
                          clipboard.copy(dataFormation?.visioPassword);
                          setOpened2((o) => !o);
                        }}
                      >
                        <IoIosCopy size={15} />
                      </ActionIcon>
                    </Tooltip>
                  </Tooltip>
                  <h4 className="text-xs">Mots de passe :</h4>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
      {/* Bouton je m'inscris qui suis quand on scroll */}
      {window.innerWidth > 768
        ? null
        : scroll.y > 450 && (
            <div className="fixed top-0 bg-white shadow-xl pb-3">
              <div className="pb-2 pt-3 px-6 md:w-8/12">
                <h2 className="text-xs mb-1 font-extralight text-gray-900 uppercase">
                  {dataFormation?.title}
                </h2>
                <div className="flex justify-between">
                  <p className="text-xs text-gray-600 uppercase">
                    {format_d_MMMM_YYYY(dataFormation?.startDate)}
                  </p>
                  <p className="text-xs text-center text-gray-600 uppercase">
                    {dataFormation?.place}
                  </p>
                </div>
              </div>
              <div className="m-auto text-center md:w-2/12">
                <div className="text-center w-screen md:w-auto px-3">
                  {!isGuest &&
                    dataFormation?.totalGuests >= dataFormation?.maxGuests && (
                      <>
                        <h2 className="text-yellow-500 font-bold text-xm text-center">
                          Complet
                        </h2>
                        <button
                          onClick={() => console.log()}
                          className="w-full text-white bg-yellow-500 text-xs p-2 mt-1 font-bold rounded"
                        >
                          Voir les prochaines dates
                        </button>
                        <button
                          onClick={() => handleGuestWaitingZone()}
                          className="mb-1 w-full text-white bg-gray-500 text-xs p-2 mt-2 font-bold rounded"
                        >
                          {!isInWaitingZone
                            ? "S'inscrire sur la liste d'attente"
                            : "Se retirer de la file d'attente"}
                        </button>
                        <span className="text-xs text-gray-600 float-right font-bold text-right">
                          {dataFormation?.price} €
                        </span>
                        <div className="text-xs text-gray-600 text-left">
                          Déjà{" "}
                          <span className="font-bold text-gray-600">
                            {dataFormation?.waitingZone?.length} personne(s)
                          </span>
                        </div>
                      </>
                    )}

                  {!dataFormation?.isUniqueWaiting && !isGuest &&
                    dataFormation?.totalGuests < dataFormation?.maxGuests && (
                      <button
                        onClick={() => handleGuest()}
                        className="bg-pink-600 object-contain text-white rounded-md flex justify-center float-right"
                      >
                        <p className="py-2 px-3 text-xs">Je m'inscris</p> {dataFormation?.price} €
                      </button>
                    )}
                    {dataFormation?.isUniqueWaiting && isInWaitingZone && !isGuest &&
                    dataFormation?.totalGuests < dataFormation?.maxGuests && (
                      <button
                        onClick={() => handleGuest()}
                        className="bg-pink-600 object-contain text-white rounded-md flex justify-center float-right"
                      >
                        <p className="py-2 px-3 text-xs">Je m'inscris</p> {dataFormation?.price} €
                      </button>
                    )}
                  {isGuest && (
                    <>
                      <FiUserCheck
                        size="25"
                        className="text-green-600 mx-auto"
                      />
                      <h2 className="text-green-600 font-bold text-xs text-center mb-3">
                        Déjà inscrit
                      </h2>
                      {new Date(dataFormation?.startDate) > new Date() && (
                      <Button
                        onClick={() => handleGuest()}
                        className="px-10"
                        size="md"
                        color="gray"
                        variant="filled"
                      >
                        <span className="inline-block mx-auto">
                          <FiUserX
                            size="16"
                            className="inline-block mx-auto mr-3 text-xs"
                          />{" "}
                          J'annule
                        </span>
                      </Button>)}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
    </>
  );
};

export default ItemFormationWrapped;
