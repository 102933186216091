import request from "./request";

const endpoint = "/user_role_informations";

const post = (idUser: Number) => {
    return request.post(endpoint,{
        user: `/api/users/${idUser}`
    });
};

const get = (id: Number) => {
    return request.get(endpoint + `/${id}`);
};

const exportedApiUserInfos = {post, get}

export default exportedApiUserInfos;