import React from 'react';
import { ActionIcon, Button, Table, Pagination, Select } from '@mantine/core';
import { FiEdit, FiDelete } from 'react-icons/fi';

type Props = {
  rows: React.ReactElement[];
  title: string;
  thead: React.ReactElement;
  page: number;
  maxPage: number;
  selectedYear: number;
  exportLink?: string;
  exportGlobalLink?: string;
  onYearChange: (year: string | null) => void;
  onPageChange: (page: number) => void;
};

const ReadOnlyListingComponent = (props: Props) => {
  const currentYear = new Date().getFullYear();
  // TODO change to 2023
  const startYear = 2022;
  const yearsArray = Array.from(
    { length: currentYear - startYear + 1 },
    (_, i) => currentYear - i
  );

  const handlePageChange = (newPage: number) => {
    props.onPageChange(newPage);
  };

  return (
    <div className="mx-auto bg-white shadow-xl p-5 overflow-auto w-full">
      <>
        <div className="mx-10 text-xl text-center relative">
          <span className="text-2xl text-center">{props.title}</span>{" "}
          <label style={{ display: 'inline-block' }}>
            <Select
              value={props.selectedYear.toString()}
              data={yearsArray.map((year) => year.toString())}
              onChange={props.onYearChange}
              style={{ textAlign: 'center' }} // Center-align text in Select
            />
          </label>
          {(props.exportLink && props.exportGlobalLink) ? (
            <div style={{top:0,right:"-2.5rem"}} className="xl:absolute mt-3 xl:mt-0">
              <div>
                <Button className="bg-blue" onClick={() => window.open(props.exportLink)}>
                  Export
                </Button>
                <Button className="bg-blue ml-2" onClick={() => window.open(props.exportGlobalLink)}>
                  Export Global
                </Button>
              </div>
            </div>
          ) : props.exportLink ? (
            <div style={{top:0,right:"-2.5rem"}} className="lg:absolute mt-3 lg:mt-0">
              <div>
                <Button className="bg-blue" onClick={() => window.open(props.exportLink)}>
                  Export
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className="mt-3 overflow-auto md:mt-3 text-xl">
          <Table className="overflow-auto" highlightOnHover>
            {props.thead}
            <tbody className="overflow-auto">
              {(props.rows && props.rows.length) ? (
                props.rows
              ) : (
                <tr>
                  <td>Aucune donnée</td>
                </tr>
              )}
            </tbody>
          </Table>
          <div style={
            {
              "textAlign": "center",
              "margin": "auto",
              "display": "flex",
              "justifyContent": "center",
              "padding": "10px"
            }
          }>
            <Pagination page={props.page} total={props.maxPage} onChange={handlePageChange} />
          </div>
        </div>
      </>
    </div>
  );
};

export default ReadOnlyListingComponent;