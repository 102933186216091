const isAdhesionPayment = (itemName: string) => {
    if (!itemName) {
        return false;
    }

    const names = [
        "adhésion",
        "renouvellement cotisation",
    ];

    var isAdhesion = false;
    var itemNameLower = itemName.toLocaleLowerCase();

    names.forEach((name) => {
        isAdhesion = isAdhesion || (itemNameLower.indexOf(name) >= 0)
    })

    return isAdhesion;
}

export default isAdhesionPayment;
