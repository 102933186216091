import React, { useEffect, useState } from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Textarea,
  TextInput,
  useMantineTheme,
  Select,
  RadioGroup,
  Checkbox,
  Radio,
} from "@mantine/core";
import { DatePicker, TimeInput } from "@mantine/dates";
import {
  ROLE_WITHOUT_TOUS,
  ROLE_WITHOUT_TOUS_NEWS,
  ROLES,
  ROLES_WOUT_GUEST,
  ROLES_WOUT_GUEST_TOUS
} from "../../../constants/roles";
import {
  HABILITATION,
  HABILITATION_WITHOUT_TOUS,
} from "../../../constants/habilitation";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/hooks";
import dayjs from "dayjs";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import news from "../../../api/news";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { MdUploadFile } from "react-icons/all";
import { ImageIcon, UploadIcon, CrossCircledIcon } from "@modulz/radix-icons";
import { RichTextEditor } from "@mantine/rte";
import storage from "../../../auth/storage";
import Footer from "../../Footer";
import users from "../../../api/users";
import { handleImageUpload } from "../../../utility/imageUpload";

const CreateNews = () => {
  const { request: requestMe, data: user } = useApi(users.me);
  const [loading, setLoading] = useState(false);
  const [displayButton, setDisplayButton] = useState(true);
  const { request } = useApi(news.post);
  const { request: requestUser } = useApi(users.getUserByLastName);
  const [search, setSearch] = useState<string>("");
  const [searchStart, setSearchStart] = useState<string>("");

  const [guest, setGuest] = useState([]);
  const [SubGuestData, setSubGuestData] = useState([]);

  const navigation = useNavigate();

  useEffect(() => {
    requestMe();
  }, []);

  const searchGuest = () => {
    requestUser(search).then((res) => {
      const tab: any = [];

      for (let i = 0; i < res.data.length; i++) {
        tab.push({
          value: "/api/users/" + res.data[i].id,
          label:
            res.data[i].firstName +
            " " +
            res.data[i].lastName +
            " (" +
            res.data[i].email +
            ")",
        });
      }

      setGuest(tab);
      setSubGuestData(tab);
    });
  };

  useEffect(() => {
    if (search?.length > 2) {
      if (search?.length == 3) {
        setSearchStart(search);
        searchGuest();
      } else {
        if (search.slice(0, 3) == searchStart) {
          console.log("SubGuestData", SubGuestData);
          setGuest(
            SubGuestData.filter((item: any) => {
              if (
                item.email?.toLowerCase().includes(search.toLowerCase()) &&
                item != user?.email
              ) {
                return item;
              }
            }),
          );
        } else {
          searchGuest();
        }
      }
    }
  }, [search]);

  const handleSubmit = async (values: any) => {
    if (form.validate()) {
      setLoading(true);
      // @ts-ignore

      await request({
        ...values,
        id: user.id,
        isValidate: user.roles[0] == "ROLE_XMAN" ? true : false,
      })
        .then(async (data) => {
          const { id } = data.data;
          console.log(data);

          await news.image(values.picture[0], id).then((data) => {
            navigation("/auth/news/detail/" + id, { replace: true });
          });
          navigation("/auth/news/detail/" + id, { replace: true });
        })
        .catch((data) => {
          console.log(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const form = useForm({
    initialValues: {
      title: "",
      category: "VIE DE L'ASSOCIATION",
      picture: [],
      createdAt: new Date(),
      description: "",
      destined: [],
      date: new Date(),
      taggedUsers: [],
    },

    validationRules: {
      title: (value) => value.length >= 3,
      category: (value) => value.length >= 3,
      createdAt: (value) => value?.getDate() >= 1,
      description: (value) => value.length > 0,
      date: (value) => value != null,
      destined: (value) => value.length > 0,
    },
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Actualités", href: "/auth/news" },
    { title: "Ajouter une actualité", href: "/auth/news/create" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  console.log("formvluetaggedusers", form.values.taggedUsers);

  useEffect(() => {
    // @ts-ignore
    if (form.values.destined.includes("All")) {
      // @ts-ignore
      form.setFieldValue("destined", ROLE_WITHOUT_TOUS_NEWS);
    }
  }, [form.values.destined]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function UpdateImage(value: []) {
    form.setFieldValue("picture", value);
    // @ts-ignore
    const UrlImg = URL.createObjectURL(value[0]);
    // console.log("url NvImage : ",UrlTest)
    // @ts-ignore
    document.getElementById("imgPreview").src = UrlImg;
    // @ts-ignore
    document.getElementById("imgPreview").className = "w-40 mr-5";
    // @ts-ignore
    document.getElementById("ImageError").innerHTML = "";
  }

  function RejectImage(value: []) {
    // @ts-ignore
    console.log("Rejected file : ", value[0]);
    // @ts-ignore
    document.getElementById("ImageError").innerHTML = "UNE ERREUR EST SURVENUE";
  }

  window.onscroll = () => {
    //@ts-ignore
    if (window.scrollY + window.innerHeight < document.getElementById("SubmitFix")?.offsetTop + document.getElementById("SubmitFix")?.offsetHeight
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  return (
    <div className="pb-12 pt-6 md:pt-12 pl-4 pr-6">
      <div className="mx-auto md:px-4 sm:px-6 lg:px-8">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <h2 className="text-3xl mt-6 md:mt-10">Ajouter une actualité</h2>
        <p className="max-w-3xl my-5">
          Vous êtes sur le point de créer une nouvelle actualité : merci !<br /> Il
          vous suffit de remplir les champs suivants.
        </p>

        <div className="mt-10">
          <LoadingOverlay visible={loading} />
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <div className="grid grid-cols-1 lg:grid-cols-7 gap-5 lg:gap-10 2xl:gap-52">
              <div className="mb-3 col-span-1 md:col-span-5">
                <div>
                  <TextInput
                    required
                    placeholder="Titre de l'actualité"
                    error={form.errors.title && "Please specify valid Titre"}
                    value={form.values.title}
                    onChange={(event) =>
                      form.setFieldValue("title", event.currentTarget.value)
                    }
                  />
                </div>

                <Dropzone
                  className="mt-10"
                  onDrop={(value: []) => UpdateImage(value)}
                  multiple={false}
                  maxSize={5 * 1024 ** 2}
                  accept={IMAGE_MIME_TYPE || "image/jpeg"}
                  onReject={(value: []) => RejectImage(value)}
                >
                  {() => (
                    <Group
                      position="center"
                      spacing="xl"
                      style={{ minHeight: 50, pointerEvents: "none" }}
                    >
                      <div className="flex items-center">
                        <img id="imgPreview" className="" src="" alt="" />
                        <div className="text-sm">
                          Faites glisser ou cliquez pour sélectionner une image
                          à mettre en avant, l'image ne doit pas dépasser 5 Mo
                          <p id="ImageError"></p>
                        </div>
                      </div>
                    </Group>
                  )}
                </Dropzone>

                <div className="my-3">
                  <Select
                    data={[
                      { value: "PARTENAIRES", label: "PARTENAIRES" },
                      {
                        value: "VIE DE L'ASSOCIATION",
                        label: "VIE DE L'ASSOCIATION",
                      },
                      { value: "PRODUITS", label: "PRODUITS" },
                      { value: "FISCALITE", label: "FISCALITE" },
                      { value: "LOI", label: "LOI" },
                      { value: "FORMATION", label: "FORMATION" },
                      { value: "EVENEMENT", label: "EVENEMENT" },
                    ]}
                    required
                    placeholder="Catégorie"
                    error={
                      form.errors.category &&
                      "Entré un catégorie valide s'il vous plaît"
                    }
                    value={form.values.category}
                    onChange={(event: string) =>
                      form.setFieldValue("category", event)
                    }
                  />
                </div>

                <div className="mt-5">
                  <RichTextEditor
                    value={form.values.description}
                    onChange={(event) =>
                      form.setFieldValue("description", event)
                    }
                    onImageUpload={handleImageUpload}
                  />
                </div>
                <div className="my-3 flex justify-between">
                  <div className="w-1/2 m-2">
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      error={
                        form.errors.date && "Date de publication obligatoire"
                      }
                      labelFormat="DD/MM/YYYY"
                      placeholder="Date de publication"
                      value={form.values.date}
                      onChange={(event: Date) =>
                        form.setFieldValue("date", event)
                      }
                      required
                      size="md"
                    />
                  </div>
                  <div className="w-1/2 m-2">
                    <MultiSelect
                      clearable
                      data={[...guest]}
                      placeholder="Taguez un ou des adhérents"
                      searchable
                      size="md"
                      onSearchChange={(value) => setSearch(value)}
                      onChange={(value: []) =>
                        form.setFieldValue("taggedUsers", value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-span-1 lg:col-span-2">
                <div className="my-10">
                  <h2 className="text-xl mb-2">Public visé</h2>
                  <MultiSelect
                      data={ROLES_WOUT_GUEST}
                      onChange={(event: []) =>
                          form.setFieldValue("destined", event)
                      }
                      value={form.values.destined}
                      placeholder="Public visé"
                  />
                </div>
              </div>
            </div>
            <div className="flex mb-2 justify-center md:block">
              <div id="SubmitFix" className="flex mb-2 justify-center md:block">
                <Button
                  onClick={() => handleSubmit(form.values)}
                  mt={20}
                  mr={5}
                  className="px-20"
                  size="md"
                  color="pink"
                  type="button"
                >
                  Je valide
                </Button>
              </div>
              <div
                className={`hidden md:${
                  displayButton ? "block" : "hidden"
                } fixed bottom-0 right-0 mr-16 bg-white px-3 pb-3 shadow-xl border`}
              >
                <Button
                  onClick={() => handleSubmit(form.values)}
                  mt={20}
                  mr={5}
                  className="mr-auto px-20"
                  size="md"
                  color="pink"
                >
                  Je valide
                </Button>
              </div>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default CreateNews;
