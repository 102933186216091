import React, {useEffect, useRef, useState} from 'react';
import {FaBell} from "react-icons/all";
import {Link, Navigate} from "react-router-dom";
import NotificationItemLink from "./NotificationItemLink";
import {create} from "apisauce";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import notifications from "../../api/notifications";
import storage from "../../auth/storage";
import { ReactComponent as YourSvg } from '../../assets/images/ic_notif.svg';
import workshop from "../../api/workshop";
import isExpertAccountant from '../../utility/isExpertAccountant';

const NotificationCard = () => {
    const {data,request} = useApi(notifications.actualize);
    const [intervalTime, setIntervalTime] = useState(120000);
    const {id} : any = storage.getUser();
    const {data:dataUser, request:requestMe} = useApi(users.me)
    const [workshopAbsent, setWorkshopAbsent] = useState([]);
    const {
        data: dataFormer,
        loading: loadingFormer,
        request: requestFormer,
    } = useApi(workshop.getWorkshopAfterDateByFormerAndNotEndedAbsent);
    const {
        data: dataCopilots,
        loading: loadingCopilots,
        request: requestCopilots,
    } = useApi(workshop.getWorkshopAfterDateByCopilotAndNotEndedAbsent);

    const useInterval = (callback : any, delay : any) => {
        const savedCallback = useRef();

        // Remember the latest callback.
        useEffect(() => {
            savedCallback.current = callback;
        }, [callback]);

        // Set up the interval.
        useEffect(() => {
            function tick() {
                // @ts-ignore
                savedCallback.current();
            }
            if (delay !== null) {
                let id = setInterval(tick, delay);
                return () => clearInterval(id);
            }
        }, [delay]);
    }

    useInterval(() => {
        // Do some API call here
        setTimeout(() => {
            request(id);
            requestFormer(id);
            requestCopilots(id);
        }, 10000);
    }, intervalTime);

    useEffect(() => {
        request(id)
        requestMe()
        requestFormer(id);
        requestCopilots(id);
    }, []);

    useEffect(() => {
        if(!loadingFormer && !loadingCopilots){
            const array = dataFormer.concat(dataCopilots);
            setWorkshopAbsent(array);
        }
    },[dataFormer,dataCopilots])

    const workshopAbsentFilter = workshopAbsent.filter((item: any) => {
        //filter workshop with startDate is today or before
        const date = new Date();
        const currentDate = new Date(item.startDate);
        return (
            currentDate.getTime() <= date.getTime()
        );
    });


    if(isExpertAccountant(storage.getUser())){
        return null;
    }

    return (

        <div className="sm:px-5 pt-5 md:pb-3 pb-16 mx-10">
            <div className="bg-fushiaTonique rounded shadow float-right p-1.5 px-4 sm:px-8 flex gap-x-5 text-white">
                <YourSvg className="bg-fushiaTonique my-auto flex-none h-[25]" />
                <div className='flex flex-wrap gap-x-5 items-center'>
                    <NotificationItemLink display={data?.goals} path='/auth/notifications/objectifs' title="Objectifs" />
                    <NotificationItemLink display={data?.evolutions} path='/auth/evolution' title="Evolution" />
                    <NotificationItemLink display={data?.CopilotWorkshops || data?.workshopsValid || data?.workshopWaitingAbsentValidation || workshopAbsentFilter.length > 0} path='/auth/notifications/workshop' title="Ateliers" />
                    <NotificationItemLink display={data?.newsValid} path='/auth/notifications/news' title="Actualités" />
                    <NotificationItemLink display={false} path='/auth/home' title="Membres" />
                    <NotificationItemLink display={dataUser?.roles == "ROLE_GUEST" ? dataUser?.isJdEnded == true && dataUser?.isDemarrageEnded == true && dataUser?.is3JREnded == true : data?.adhesion} path='/auth/notifications/adhesion' title="Adhésion" />
                    <NotificationItemLink display={data?.JD} path='/auth/JDs' title="Journée découverte" />
                </div>
                {/*data && console.log("notifTest",data)*/}
            </div>
        </div>
    );
};

export default NotificationCard;
