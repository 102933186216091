export const CIVILITIES = [
    {value: 'M.', label: 'Monsieur'},
    {value: 'Mme', label: 'Madame'},
    {value: 'Mlle', label: 'Mademoiselle'},
];

export const getCivility = (code: string) => {
    switch (code) {
        case 'M':
        case 'M.':
        case 'Monsieur':
            return {value: 'M.', label: 'Monsieur'};
        case 'Mme':
        case 'Madame':
            return {value: 'Mme', label: 'Madame'};
        case 'Mlle':
        case 'Mademoiselle':
            return {value: 'Mlle', label: 'Mademoiselle'};
        default:
            return {value: 'M.', label: 'Monsieur'};
    }
}
