import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import workshop from "../../api/workshop";
import {useNotifications} from "@mantine/notifications";
import useApi from "../../hooks/useApi";
import storage from "../../auth/storage";
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

const CardCollection = ({data}: any) => {
    const {id:UserId}: any = storage.getUser();
    const {request: requestWorkshop} = useApi(
        workshop.item
    );
    const idWorkshop = data?.id
    const [isGuest,setisGuest] = useState(Boolean)
    const [listGuest,setlistGuest] = useState<any>()

    useEffect(() => {
        requestWorkshop(idWorkshop).then(r => {
            setlistGuest(r?.data?.guests)
        })
    },[]);
    
    useEffect(() => {
        setisGuest(!!listGuest?.find((guest: { id: any; }) => guest.id === UserId))
    }, [listGuest]);

    const notifications = useNotifications();
    const handleCopilot = async (id: Number) => {
        const result = await workshop.registerCopilot(Number(id));
        if (!result.ok) {
            notifications.showNotification({
                title: 'Une erreur est survenue.',
                message: " L'inscription n'a pas pu aboutir ! 🤥",
                color:"red"
            })
        }
        notifications.showNotification({
            title: 'Nice !',
            message: 'Vous êtes maintenant inscrit ! 😀',
            color:"green"
        })
    }
    
    return (
        <Link to={"/auth/workshop/detail/"+data?.id}>
            <div className="md:flex p-3 mb-5 bg-white rounded shadow-md">
                <div className="mr-auto grid gap-1 grid-cols-1">
                    <div className="font-bold text-sm text-gray-500">{data?.thematic}</div>
                    <div>
                        <span className="text-2xl uppercase">{data?.title}</span>
                        {data?.totalGuests >= data?.maxGuests && (
                            <span className="ml-5 bg-yellow-500 p-1.5 text-sm rounded text-white">Complet</span>
                        )}
                        <span className={`invisible md:visible ml-5 uppercase ${isGuest ? "bg-green-500" : "bg-gray-500"} p-1.5 text-sm rounded text-white`}>{data?.totalGuests}/{data?.maxGuests}</span>
                    </div>
                </div>
                <div className="flex justify-between mt-4 md:mt-0 ml-auto md:grid grid-cols-1">
                    <span className="uppercase md:text-right md:text-base">{format_d_MMMM_YYYY(data?.startDate)}</span>
                    {data?.isVisio ? <p className="text-right text-sm">Visio</p> : 
                        <div className='flex-col float-right'>
                            <p className="text-right text-sm">{data?.site?.address}</p>
                            <p className="text-right text-sm">{data?.site?.postalCode} {data?.site?.city}</p>
                        </div>}
                </div>
            </div>
        </Link>
    );
};

export default CardCollection;
