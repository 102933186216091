import request from "./request";

const endpoint = "/event_dates";

const create = (dateAt : any, event : any, endAt : any) => {
    return request.post(endpoint, {
        dateAt : dateAt,
        events : "/api/events/" + event,
        endAt : endAt,
    });
};


export default {
    create,
};