import React, { useEffect, useState } from "react";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Group,
  LoadingOverlay,
  MultiSelect,
  Select,
  TextInput,
  useMantineTheme,
} from "@mantine/core";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { RichTextEditor } from "@mantine/rte";
import {ROLE_WITHOUT_TOUS, ROLES, ROLES_WOUT_GUEST, ROLES_WOUT_GUEST_TOUS} from "../../../constants/roles";
import storage from "../../../auth/storage";
import useApi from "../../../hooks/useApi";
import news from "../../../api/news";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/hooks";
import { CrossCircledIcon, ImageIcon, UploadIcon } from "@modulz/radix-icons";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";
import { URL2 } from "../../../constants/URL";
import Footer from "../../Footer";
import users from "../../../api/users";
import { handleImageUpload } from "../../../utility/imageUpload";

const EditNews = () => {
  const { id } = useParams();
  const { request: requestMe, data: user } = useApi(users.me);
  const [loading, setLoading] = useState(false);
  const [displayButton, setDisplayButton] = useState(true);
  const { request } = useApi(news.edit);
  const { request: requestNews } = useApi(news.get);

  const navigation = useNavigate();

  useEffect(() => {
    requestMe();

    requestNews(id).then((r) => {
      form.setValues({
        title: r?.data?.title,
        category: r?.data?.category,
        picture: r?.data?.fileUrl,
        createdAt: new Date(),

        description: r?.data?.description,
        destined: r?.data?.destined,
        date: dayjs(r?.data?.publishAt ?? new Date()).toDate(),
      });
    });
  }, []);

  const handleSubmit = async (values: any) => {
    if (form.validate()) {
      setLoading(true);
      // @ts-ignore

      await request(
        { ...values, isValidate: user.roles[0] == "ROLE_XMAN" ? true : false },
        id,
      )
        .then(async (data) => {
          const { id } = data.data;
          if (values?.picture?.length > 0) {
            await news.image(values.picture[0], id).then((data) => {
              navigation("/auth/news/detail/" + id, { replace: true });
            });
          }
          navigation("/auth/news/detail/" + id, { replace: true });
        })
        .catch((data) => {
          console.log(data);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const form = useForm({
    initialValues: {
      title: "",
      category: "",
      picture: [],
      createdAt: new Date(),
      description: "",
      destined: [],
      date: new Date(),
    },

    validationRules: {
      title: (value) => value.length >= 3,
      category: (value) => value.length >= 3,
      description: (value) => value.length > 0,
      createdAt: (value) => value?.getDate() >= 1,
      date: (value) => value != null,
    },
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Actualités", href: "/auth/news" },
    { title: "Modification d'une actualité", href: "/auth/news/edit/" + id },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  useEffect(() => {
    // @ts-ignore
    if (form.values.destined.includes("All")) {
      // @ts-ignore
      form.setFieldValue("destined", ROLES_WOUT_GUEST_TOUS);
    }
  }, [form.values.destined]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function UpdateImage(value: []) {
    form.setFieldValue("picture", value);
    // @ts-ignore
    const UrlTest = URL.createObjectURL(value[0]);
    // console.log("url NvImage : ",UrlTest)
    // @ts-ignore
    document.getElementById("imgOut").src = UrlTest;
    // @ts-ignore
    document.getElementById("ImageError").innerHTML = "";
  }

  function RejectImage(value: []) {
    // @ts-ignore
    console.log("Rejected file : ", value[0]);
    // @ts-ignore
    document.getElementById("ImageError").innerHTML = "UNE ERREUR EST SURVENUE";
  }

  window.onscroll = () => {
    //@ts-ignore
    if (window.scrollY + window.innerHeight < document.getElementById("SubmitFix")?.offsetTop + document.getElementById("SubmitFix")?.offsetHeight
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  return (
    <div>
      <div className="pb-12 pt-6 md:pt-12 pl-4 pr-6">
        <div className=" mx-auto md:px-4 sm:px-6 lg:px-8">
          <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
            {items}
          </Breadcrumbs>
          <h2 className="text-3xl mt-6 md:mt-10">Modifier une actualité</h2>
          <p className="max-w-3xl my-5">
            Vous êtes sur le point de modifier une actualité : merci !
            Il vous suffit de modifier un ou plusieurs champs suivants.
          </p>

          <div className="mt-10">
            <LoadingOverlay visible={loading} />
            <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
              <div className="grid grid-cols-1 lg:grid-cols-7 gap-5 lg:gap-10 2xl:gap-52">
                <div className="col-span-1 md:col-span-5">
                  <div>
                    <TextInput
                      required
                      label="Titre"
                      error={
                        form.errors.title &&
                        "Rentrez un titre valide s'il vous plaît"
                      }
                      value={form.values.title}
                      onChange={(event) =>
                        form.setFieldValue("title", event.currentTarget.value)
                      }
                    />
                  </div>
                  <Dropzone
                    className="mt-10"
                    onDrop={(value: []) => UpdateImage(value)}
                    multiple={false}
                    maxSize={5 * 1024 ** 2}
                    accept={IMAGE_MIME_TYPE || "image/jpeg"}
                    onReject={(value: []) => RejectImage(value)}
                  >
                    {() => (
                      <Group
                        position="center"
                        spacing="xl"
                        style={{ minHeight: 50, pointerEvents: "none" }}
                      >
                        <div className="flex items-center">
                          <img
                            id="imgOut"
                            className="w-40 mr-5"
                            src={URL2 + form.values.picture}
                            alt=""
                          />
                          <div className="text-sm">
                            Faites glisser ou cliquez pour sélectionner une
                            image à mettre en avant, l'image ne doit pas
                            dépasser 5 Mo
                            <p id="ImageError"></p>
                          </div>
                        </div>
                      </Group>
                    )}
                  </Dropzone>

                  <div className="my-3">
                    <Select
                      data={[
                        { value: "PARTENAIRES", label: "PARTENAIRES" },
                        {
                          value: "VIE DE L'ASSOCIATION",
                          label: "VIE DE L'ASSOCIATION",
                        },
                        { value: "PRODUITS", label: "PRODUITS" },
                        { value: "FISCALITE", label: "FISCALITE" },
                        { value: "LOI", label: "LOI" },
                        { value: "FORMATION", label: "FORMATION" },
                        { value: "EVENEMENT", label: "EVENEMENT" },
                      ]}
                      required
                      placeholder="Catégorie"
                      error={
                        form.errors.category &&
                        "Entré un catégorie valide s'il vous plaît"
                      }
                      value={form.values.category}
                      onChange={(event: string) =>
                        form.setFieldValue("category", event)
                      }
                    />
                  </div>

                  <div className="mt-5">
                    <RichTextEditor
                      value={form.values.description}
                      onChange={(event) =>
                        form.setFieldValue("description", event)
                      }
                      onImageUpload={handleImageUpload}
                    />
                  </div>
                  <div className="my-3 ">
                    <DatePicker
                      inputFormat="DD/MM/YYYY"
                      labelFormat="DD/MM/YYYY"
                      placeholder="Date de publication"
                      label="Date de publication"
                      value={form.values.date}
                      onChange={(event: Date) =>
                        form.setFieldValue("date", event)
                      }
                      required
                    />
                  </div>
                </div>
                <div className="col-span-1 lg:col-span-2">
                  <div className="my-10">
                    <h2 className="text-xl mb-2">Public visé</h2>
                    <MultiSelect
                      data={ROLES_WOUT_GUEST}
                      onChange={(event: []) =>
                        form.setFieldValue("destined", event)
                      }
                      value={form.values.destined}
                      placeholder="Public visé"
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-center md:block">
                <Button
                  onClick={() => handleSubmit(form.values)}
                  mt={20}
                  mr={5}
                  className="px-20"
                  size="md"
                  color="pink"
                  type="button"
                >
                  Je valide
                </Button>
              </div>
              <div className="hidden md:block fixed bottom-0 right-0 mr-16 bg-white px-3 pb-3 shadow-xl border">
                <div
                  id="SubmitFix"
                  className="flex mb-2 justify-center md:block"
                >
                  <Button
                    onClick={() => handleSubmit(form.values)}
                    mt={20}
                    mr={5}
                    className="px-20"
                    size="md"
                    color="pink"
                    type="button"
                  >
                    Je valide
                  </Button>
                </div>
                <div
                  className={`hidden md:${
                    displayButton ? "block" : "hidden"
                  } fixed bottom-0 right-0 mr-16 bg-white px-3 pb-3 shadow-xl border`}
                >
                  <Button
                    onClick={() => handleSubmit(form.values)}
                    mt={20}
                    mr={5}
                    className="mr-auto px-20"
                    size="md"
                    color="pink"
                  >
                    Je valide
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default EditNews;
