import React, { useEffect, useState } from 'react';
import { Badge, Group, Anchor, Button } from "@mantine/core";
import {Link} from "react-router-dom";
import workshop from "../../api/workshop";
import {useNotifications} from "@mantine/notifications";
import useApi from "../../hooks/useApi";
import storage from "../../auth/storage";
import CapitalizeLetter from "../../hooks/CapitalizeLetter"
import { format_dddd_d_MMMM_YYYY, format_d_MMMM_YYYY } from "../../utility/dateUtility";

const HomeCardWorkshop = ({data, isNotHome = false}: any) => {

    const notifications = useNotifications();
    const [requestLoading, setRequestLoading] = useState(false);
    const [isInWaitingZone, setIsInWaitingZone] = useState(false);
    const { request, loading } = useApi(workshop.item);
    const [dataWorkshop, setDataWorkshop] = useState<any>();
    const [isGuest, setIsGuest] = useState();
    const user: any = storage.getUser();
    
    useEffect(() => {
        // @ts-ignore
        setIsGuest(!!dataWorkshop?.guests.find(guest => guest.id === user?.id))
        // @ts-ignore
        setIsInWaitingZone(!!dataWorkshop?.waitingZone.find(guest => guest.id === user?.id))
        // @ts-ignore
        // setIsCopilot(!!dataWorkshop?.coPilotWanted.find(coPilota => coPilota.id === user?.id))
    }, [dataWorkshop]);

    const handleGuestWaitingZone = async () => {
        setRequestLoading(true)
        if (isInWaitingZone) {
            const result = await workshop.unRegisterInWaitingZone(Number(data.id));
            if (!result.ok) {
                notifications.showNotification({
                    title: 'Une erreur est survenue.',
                    message: " L'annulation n'a pas pu aboutir ! 🤥",
                    color: "red"
                })
                setRequestLoading(false)
            }
            notifications.showNotification({
                title: 'Nice !',
                message: "Vous êtes maintenant plus inscrit dans la file d'attente ! 😀",
                color: "green"
            })
            setRequestLoading(false)
        }
        if (!isInWaitingZone) {
            const result = await workshop.registerInWaitingZone(Number(data.id));
            if (!result.ok) {
                notifications.showNotification({
                    title: 'Une erreur est survenue.',
                    message: " L'inscription n'a pas pu aboutir ! 🤥",
                    color: "red"
                })
                setRequestLoading(false)
            }
            notifications.showNotification({
                title: 'Nice !',
                message: "Vous êtes maintenant inscrit dans la file d'attente ! 😀",
                color: "green"
            })
            setRequestLoading(false)
        }
        request(data.id).then(r => {
            setDataWorkshop(r.data);
        });
    }

    useEffect(() => {
        // @ts-ignore
        request(data.id).then(r => {
            setDataWorkshop(r.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if(isNotHome){
        return (
            <div className={"w-full mb-5 bg-white duration-150 rounded shadow-md"} style={{padding: "12px 14px"}}>
                <Link to={"/auth/workshop/detail/"+data?.id}>
                    <div className="flex flex-row justify-between" style={{color: "#687687"}}>
                        <div className="flex flex-col text-left">
                            {isNotHome && (<div className="font-bold text-thirteen text-gray-500">{data?.thematic}</div>)}
                            <div className="flex items-center">
                                <h4 style={{fontWeight: isNotHome ? "initial" :"bold",color: isNotHome ? "#333" : "#687687", fontSize: isNotHome ? "21px" : "15px"}}>{data?.title && CapitalizeLetter(data?.title)}</h4>

                                <span className="ml-5"
                                     style={{color: (isGuest ? "white" : isNotHome ? "white" : "#687687"), opacity:(isGuest ?  0.6 : 1)}}>
                                    <span className={" rounded-md py-1 px-2 uppercase"} style={{background: (isGuest ? "#84cc16" : isNotHome ? "#6B7280" :"#FAFAFA")}} >{data?.totalGuests}/{data?.maxGuests}</span>
                                </span>
                            </div>
                        </div>
                        <div className="flex text-right" style={{fontSize:isNotHome ? "13px" : "11px"}}>
                            <div>
                                <p>{CapitalizeLetter(format_dddd_d_MMMM_YYYY(data?.startDate))}</p>
                                <div className="ml-auto items-center justify-between text-sm font-medium flex-wrap">
                                    {data?.isVisio ? <p className="text-right text-sm" style={{fontSize: isNotHome ? "13px" : "11px", color: "#687687"}}>Visio</p> :
                                        <div className='text-right'>
                                            <p className="text-right text-sm" style={{fontSize:isNotHome ? "13px" : "11px", color: "#687687"}}>{data?.site.address && CapitalizeLetter(data?.site.address)}</p>
                                            <p className="text-right text-sm" style={{fontSize: isNotHome ? "13px" :"11px", color: "#687687"}}>{data?.site?.postalCode && CapitalizeLetter(data?.site.postalCode)} {data?.site?.city && CapitalizeLetter(data?.site.city)}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </Link>
                {data?.totalGuests >= data?.maxGuests && (
                    <div className="flex items-start items-center flex-wrap text-thirteen font-medium">
                        <div className="text-white bg-red-500 rounded-md py-1 px-3 mr-2">
                            complet
                        </div>
                        {isGuest ? null :
                            <>
                                <div className='mr-2 my-1'>
                                    <Button onClick={() => alert("fonctionalité futur")} size="xs"
                                            styles={(theme) => ({
                                                root: {
                                                    backgroundColor: "#F0F1F3",
                                                    '&:hover': {
                                                        backgroundColor: "#E3E4E6",
                                                    }
                                                }
                                            })}
                                            fullWidth>
                                        <span className="text-thirteen text-gray-500">Prochaines dates</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button onClick={() => handleGuestWaitingZone()} size="xs"
                                            styles={(theme) => ({
                                                root: {
                                                    backgroundColor: "#F0F1F3",
                                                    '&:hover': {
                                                        backgroundColor: "#E3E4E6",
                                                    }
                                                }
                                            })}
                                            fullWidth
                                            disabled={requestLoading}>
                                        <span className="text-thirteen text-gray-500">{!isInWaitingZone ? "Inscription liste d'attente" : "Désinscription file d'attente"}</span>
                                    </Button>
                                </div>
                            </>
                        }
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className="w-full ">
        <div className={"p-3 bg-white  bg-opacity-70 hover:bg-opacity-100 cursor-pointer border border-b-1 duration-150"}>
            <Link to={"/auth/workshop/detail/"+data?.id}>
                <div className="flex flex-row justify-between" style={{color: "#687687"}}>
                    <div className="flex flex-col text-left">
                        {<div className=" text-eleven text-gray-500">{data?.thematic}</div>}
                        <h4 style={{fontWeight: isNotHome ? "initial" :"bold",color: isNotHome ? "#333" : "#687687",  maxWidth: "250px", fontSize: isNotHome ? "24px" : "15px"}}>{data?.title && CapitalizeLetter(data?.title)}</h4>
                    </div>
                    <div className="flex flex-col text-right uppercase" style={{fontSize:isNotHome ? "18px" : "11px"}}>
                        <p>{format_d_MMMM_YYYY(data?.startDate)}</p>
                        {data?.isVisio ? <p className="text-right uppercase text-gray-500 text-eleven" style={{fontSize: isNotHome ? "18px" : "11px"}}>Visio</p> :
                            <div className='flex-col'>
                                <p className="text-right text-eleven text-gray-500 uppercase" style={{fontSize: isNotHome ? "18px" :"11px"}}>{data?.site?.city} ({data?.site?.postalCode.slice(0,2)})</p>
                            </div>
                        }
                    </div>
                </div>
            </Link>
            {data?.totalGuests >= data?.maxGuests && (
            <div className="flex items-start items-center flex-wrap text-sm font-medium">
                <div className="text-white bg-red-500 rounded-md py-1 px-3 mr-2">
                    complet
                </div>
                {isGuest ? null : 
                    <>
                        <div className='mr-2 my-1'>
                            <Button onClick={() => alert("fonctionalité futur")} size="xs"
                                styles={(theme) => ({
                                    root: {
                                        backgroundColor: "#F0F1F3",
                                        '&:hover': {
                                            backgroundColor: "#E3E4E6",
                                        }
                                    }
                                })}
                                fullWidth>
                                <span className="text-xs text-gray-500">Prochaines dates</span>
                            </Button>
                        </div>
                        <div>
                            <Button onClick={() => handleGuestWaitingZone()} size="xs"
                                styles={(theme) => ({
                                    root: {
                                        backgroundColor: "#F0F1F3",
                                        '&:hover': {
                                            backgroundColor: "#E3E4E6",
                                        }
                                    }
                                })}
                                fullWidth
                                disabled={requestLoading}>
                                <span className="text-xs text-gray-500">{!isInWaitingZone ? "Inscription liste d'attente" : "Désinscription file d'attente"}</span>
                            </Button>
                        </div>
                    </>
                }
            </div>
            )}
        </div>
        </div>
    );
};
export default HomeCardWorkshop;
