import request from "./request";

const endpoint = "/workshop_dates";

const create = (dateAt : any, workshop : any, endAt : any) => {
    return request.post(endpoint, {
        dateAt : dateAt,
        workshop : "/api/workshops/" + workshop,
        endAt : endAt,
    });
};


export default {
    create,
};