import request from "./request";

const login = (email: string, password: string) => request.post('/login_check',{email: email, password: password});

//const refresh = (refresh_token) => request.post('/token/refresh', refresh_token)


const exportedAuthApi = {
    login,
    //refresh
};

export default exportedAuthApi;