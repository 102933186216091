import { create } from "apisauce";
import { REACT_APP_API_BASE_URL } from "../env";

const request = create({
    baseURL: `${REACT_APP_API_BASE_URL}/`,
});

const payment = (data: any) => {
    return request.post("payment/stripe", {
        amount: data.amount,
        workshop_id: data.workshop_id,
        user_id: data.user_id,
        payment_method: data.payment_method,
        name: data.name,
    });
};

export default payment;
