import AdhesionScreen from '../../../../templates/Connected/Notifications/AdhesionScreen';

const AdhesionPaymentSimulation = () => {

    return (
        <div>
            <AdhesionScreen />
        </div>
    )

}

export default AdhesionPaymentSimulation;
