import React, { useState } from "react";
import { Button, Modal } from "@mantine/core";
import { FaWalking } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

interface RoleCardAccountProps {
    role: string;
    date: Date | null;

    isActive: boolean;
    Goals: any;
    isRole: boolean;

    goalAtFinish: number;
    goalFinish: number;
}

function RoleCardAccount({
    role,
    date,
    isActive,
    Goals,
    isRole,
    goalAtFinish,
    goalFinish,
}: RoleCardAccountProps) {
    const [opened, setOpened] = useState(false);
    const { id } = useParams();

    return (
        <>
            {!isActive ? (
                <div
                    style={{ backgroundColor: isRole ? "#e9ecef" : "#FFFF" }}
                    className="flex-1 mx-auto my-auto rounded p-3 justify-center shadow-lg text-center"
                >
                    <Button
                        disabled={isRole}
                        onClick={() => setOpened(true)}
                        size="sm"
                        className="border-none bg-white hover:bg-white"
                        variant="filled"
                    >
                        <span>
                            <span className="mpt-2 text-s16 text-gray-600 font-bold">
                                {role}
                            </span>
                            {date && (
                                <span className="text-ten block pt-2 text-gray-400">
                                    <span className="text-ten float-right text-gray-400  uppercase">
                                        {format_d_MMMM_YYYY(date)}
                                    </span>
                                </span>
                            )}
                        </span>
                    </Button>
                    <Modal
                        opened={opened}
                        onClose={() => setOpened(false)}
                        title={"Objectifs " + role}
                        overflow="inside"
                    >
                        <div className="flex flex-col gap-3">
                            {Goals && Goals.length > 0
                                ? Goals.map((item: any) => (
                                      <>
                                          <div>
                                              <p className="text-sm">
                                                  {item.description}
                                              </p>
                                              <div className="flex">
                                                  <p className="text-gray-400 font-bold text-xs px-2 py-1 m-1 rounded-md">
                                                      {item.type}
                                                  </p>
                                              </div>
                                          </div>
                                      </>
                                  ))
                                : "Aucun objéctifs trouvé"}
                        </div>
                    </Modal>
                </div>
            ) : (
                <div className="flex-1 mx-auto my-auto justify-center text-center">
                    <Button
                        compact
                        size="lg"
                        className="border-none bg-buttonAccount shadow text-white h-32"
                        variant="filled"
                        component={Link}
                        to={"/auth/adherents/profil/goals/" + id}
                    >
                        <span>
                            <FaWalking className="mx-auto" size={25} />
                            {role}
                            {date && (
                                <span className="text-xs block pt-2 text-gray-200">
                                    <span className="text-xs float-right mt-2 text-white uppercase">
                                        {format_d_MMMM_YYYY(date)}
                                    </span>
                                </span>
                            )}
                            <span className="text-xs block mt-7">
                                Objectifs {goalAtFinish} / {goalFinish}{" "}
                            </span>
                        </span>
                    </Button>
                </div>
            )}
        </>
    );
}

export default RoleCardAccount;
