import { REACT_APP_UPLOAD_IMAGE_URL } from "../env";

export const handleImageUpload = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        fetch(REACT_APP_UPLOAD_IMAGE_URL, {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => resolve(result.data.url))
            .catch(() => reject(new Error("Upload failed")));
    });
