import ItemFactureCard from "../../../components/Factures/ItemFactureCard"

const ItemFacture= () => {

    return (
        <ItemFactureCard/>
    )
    
}

export default ItemFacture; 
