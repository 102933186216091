import request from "./request";

const endpoint = "/team_meating_dates";

const create = (dateAt : any, teamMeeting : any, endAt : any) => {
    return request.post(endpoint, {
        dateAt : dateAt,
        teamMeating : "/api/team_meatings/" + teamMeeting,
        endAt : endAt,
    });
};


export default {
    create,
};