import { format } from 'util';
import request from './request';

let date = new Date();
const x = 2; // go back 5 days!
date.setDate(date.getDate() - x);

const endpoint = '/formations';

const create = (formation: any) => {
  return request.post(endpoint, {
    thematic: formation.thematic,
    title: formation.title,
    price: parseFloat(formation.price),
    place: formation.place,
    former: '/api/users/' + formation.former,
    maxGuests: formation.maxGuests,
    minGuests: formation.minGuests,
    description: formation.description,
    videoConferenceLink: formation.videoConferenceLink,
    prerequisite: formation.prerequisite,
    formerWords: formation.formerWords,
    startDate: formation.startDate,
    endDate: formation.endDate,
    visioPassword: formation.visioPassword,
    isVisio: formation.isVisio === 'true',
    wantCopilot: formation.wantCopilot,
    priceBedOne: parseFloat(formation.priceBedOne),
    priceBedTwo: parseFloat(formation.priceBedTwo),
    priceBedThree: parseFloat(formation.priceBedThree),
    destined: formation.destined,
    habilitation: formation.habilitation,
    site: formation.isVisio === 'true' ? null : '/api/sites/' + formation.site,
  });
};

const edit = (formation: any, id: number) => {
  return request.put(endpoint + '/' + id, {
    thematic: formation.thematic,
    title: formation.title,
    price: parseFloat(formation.price),
    place: formation.place,
    maxGuests: formation.maxGuests,
    minGuests: formation.minGuests,
    description: formation.description,
    videoConferenceLink: formation.videoConferenceLink,
    prerequisite: formation.prerequisite,
    formerWords: formation.formerWords,
    startDate: formation.startDate,
    endDate: formation.endDate,
    visioPassword: formation.visioPassword,
    isVisio: formation.isVisio === 'true',
    wantCopilot: formation.wantCopilot,
    priceBedOne: parseFloat(formation.priceBedOne),
    priceBedTwo: parseFloat(formation.priceBedTwo),
    priceBedThree: parseFloat(formation.priceBedThree),
    destined: formation.destined,
    habilitation: formation.habilitation,
    site: formation.isVisio === 'true' ? null : '/api/sites/' + formation.site,
  });
};

const collectionAll = () => {
    return request.get(endpoint);
}

const collection = (role: string, habilitations: Array<any>, startDate : string|null) => {
  if(startDate != null){
    date = new Date(startDate);
  }
  return request.get(
    endpoint +
      '?destined=' +
      role +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      '' +
      "&habilitation[]=[]"
      +
      habilitations
        .map((habilitation) => '&habilitation[]=' + habilitation)
        .join('')
  );
};

const collectionByFilter = (filter: string, value: any) => {
  return request.get(
    endpoint +
      '?' +
      filter +
      '=' +
      value +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ''
  );
};

const collectionByFilterEdu = (filter: string, value: any) => {
  return request.get(endpoint + '?' + filter + '=' + value);
};

const remove = (id: Number) => {
  return request.delete(endpoint + '/' + id);
};

const item = (id: Number) => {
  return request.get(endpoint + '/' + id);
};

const publish = (id: Number) => {
  return request.post(endpoint + '/' + id + '/publish');
};

const registerGuest = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join');
};

const unRegisterGuest = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel');
};

const unRegisterGuestByAdmin = (id: Number,userId: Number) => {
    return request.post(endpoint + '/' + id + '/cancel/admin',{
        userId: userId
    });
}

const registerInWaitingZone = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join/waiting_zone');
};

const registerApprouvedCopilot = (emails: any, id: number) => {
  return request.post(endpoint + '/' + id + '/join/copilot/approuved', {
    emails: [...emails],
  });
};

const unRegisterApprouvedCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel/copilot/approuved');
};

const unRegisterInWaitingZone = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel/waiting_zone');
};

const registerCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join-copilot');
};

const unRegisterCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel-copilot');
};

const deleteDateByFormationId = (id: number) => {
  return request.put(endpoint + '/' + id + '/dates/delete');
};

const exist = (id: number, date: number) => {
  return request.get(endpoint + '/exist/' + id + '/'+date);
};

const editFormer = (userId: any, id: number) => {
  return request.put(endpoint + '/' + id, {
    former: "/api/users/"+userId,
  });
};

const exportedApiFormations = {
  create,
  exist,
  editFormer,  collection,
  collectionAll,
  item,
  publish,
  collectionByFilter,
  registerGuest,
  registerCopilot,
  unRegisterGuest,
  unRegisterCopilot,
  edit,
  remove,
  registerInWaitingZone,
  unRegisterInWaitingZone,
  registerApprouvedCopilot,
  unRegisterApprouvedCopilot,
  deleteDateByFormationId,
  collectionByFilterEdu,
  unRegisterGuestByAdmin
};

export default exportedApiFormations;
