import FactureCard from "../../../components/Factures/ListingFactureCard";

const Facture = () => {

    return (
        <>
        <FactureCard />
        </>
    )
}

export default Facture

