import React, {useEffect, useState} from "react";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import CardCollection from "../../../components/workshop/CardCollection";
import {
    Anchor,
    Breadcrumbs,
    Button,
    Checkbox,
    LoadingOverlay,
    Select,
    Transition,
    Pagination as PaginationCore
} from "@mantine/core";
import {Link, useParams, useSearchParams} from "react-router-dom";
import {DateRangePicker} from "@mantine/dates";
import "dayjs/locale/fr";
import HasRoles from "../../../components/Security/hasRoles";
import {ROLE_DXMAN_MORE, ROLE_XMAN} from "../../../constants/roles";
import {ImCross, BsPlusLg} from "react-icons/all";
import Pagination from "@mui/material/Pagination";
import Footer from "../../Footer";
import thematic from "../../../api/thematic";
import storage from "../../../auth/storage";
import site from "../../../api/site";
import users from "../../../api/users";
import HomeCardWorkshop from "../../../components/workshop/HomeCardWorkshop";
import exportedStorageFilters from "../../../filters/storage";

const ListingWorkshop = () => {
    const {role}: any = storage.getUser();
    const {loading, request} = useApi(workshop.collection);
    const {request: requestMe, data: dataMe} = useApi(users.me);
    const {
        data: dataUser,
        loading: loadingUser,
        request: requestUser,
    } = useApi(users.infos);
    const [search, setSearch] = useState<string>("");
    const [searchPlace, setSearchPlace] = useState<string>("");
    const [finalDate, setFinalDate] = useState<any>([0, 9999999999999]);
    const [copilot, setCopilot] = useState(false);
    const [page, setPage] = useState<number>(1);
    const perPage = 10;
    const [date, setDate] = useState<any>(null);
    const [filters, setFilters] = useState(false);
    const [isRemove, setIsRemove] = useState(false);
    const [workShopData, setWorkShopData] = useState([]);
    const [wantendAdvices, setWantendAdvices] = useState(false);
    const [canSeeAdvices, setCanSeeAdvices] = useState(false);
    const [adviseThematics, setAdviseThematics] = useState([]);
    const [searchParams] = useSearchParams();

    const ResetSearch = () => {
        setSearch("");
        setSearchPlace("");
        setDate(null);
    };

    useEffect(() => {
        if(searchParams.get('title')){
            setSearch(searchParams.get('title')+"");
        }
    }, [searchParams]);


    const [thematics, setThematics] = useState([]);
    const [sites, setSites] = useState([]);

    const {request: requestThematics, data: dataThematics} = useApi(
        thematic.collection,
    );

    useEffect(() => {

        if(search && search.length > 0){
            exportedStorageFilters.storeTitle(search);
        }else{
            if (isRemove){
                exportedStorageFilters.removeTitle()
            }
        }
        if(searchPlace && searchPlace.length > 0){
            exportedStorageFilters.storePlace(searchPlace);
        }else{
            if (isRemove){
                exportedStorageFilters.removePlace()
            }
        }
        if (date !== null && date[0] !== null && date[1] !== null) {
            exportedStorageFilters.storeDate({date1 : date[0], date2: date[1]});
        }else{
            if (isRemove){
                exportedStorageFilters.removeDate()
            }
        }
    },[search,searchPlace, date])

    const {request: requestSites, data: dataSites} = useApi(site.collection);

    const {request: requestUserMe} = useApi(users.me);

    // ts-ignore
    useEffect(() => {
        requestMe().then((responseUserMe) => {
            requestUser(responseUserMe.data?.id).then((responseUser) => {
                setCanSeeAdvices(responseUser.data?.adviseThematic.length > 0);
                setAdviseThematics(responseUser.data?.adviseThematic);
                const habilitation = responseUser.data?.userInformations
                    ? responseUser.data?.userInformations.habilitation
                    : [];
                // if (role == "ROLE_GUEST") {
                //     requestUserMe().then((r) => {
                //         request(role, habilitation, r?.data?.endCursus).then((d) => {
                //             setWorkShopData(
                //                 d?.data?.filter(function (w: any) {
                //                     return responseUser?.data?.isJdEnded
                //                         ? w.title == '3 Jours de la Réussite' ||  w.title == 'Atelier Démarrage' || w.title == 'Découverte métier visio' ||
                //                         w.title == 'Découverte métier matinée' ||
                //                         w.title == 'Découverte métier après-midi'
                //                         : w.title == 'Découverte métier visio' ||
                //                         w.title == 'Découverte métier matinée' ||
                //                         w.title == 'Découverte métier après-midi';
                //                 })
                //             );
                //         });
                //     });
                // } else {
                //     request(role, habilitation, responseUserMe?.data?.endCursus).then((r) => {
                //         setWorkShopData(r?.data);
                //     });
                // }


                requestThematics("WORKSHOP").then((value) => {
                    setThematics(
                        value.data.map((thematic: any) => {
                            return {
                                label: thematic.wording,
                                value: thematic.wording,
                            };
                        }),
                    );
                });
                requestSites().then((value) => {
                    setSites(
                        value.data.sort((a: any, b: any) => a.name.localeCompare(b.name)).map((site: any) => {
                            return {
                                label: site.name.toUpperCase(),
                                value: "" + site.id,
                            };
                        }),
                    );
                });
            });
        });
        let storage = exportedStorageFilters.get()
        console.log(storage)
        if(storage){
            if(storage.title){
                setSearch(storage.title);
            }
            if(storage.place){
                setSearchPlace(storage.place);
            }
            if(storage.date1 && storage.date2){
                setDate([new Date(storage.date1), new Date(storage.date2)]);
            }
        }
        else {
            ResetSearch();
        }
        setIsRemove(true);
    }, []);

    const filteredThematics = thematics.filter((thematic: any) => {
        if(role == "ROLE_GUEST") {
            switch (thematic.label) {
                case "Découverte métier après-midi":
                    return true;
                case "Découverte métier matinée":
                    return true;
                case "Découverte métier visio":
                    return true;
                case "Atelier Démarrage":
                    return true;
                case "3 Jours de la Réussite":
                    return true;
                default:
                    return false;
            }
        }else{
            return true;
        }
    });

    useEffect(() => {
        if (date !== null && date[0] !== null && date[1] !== null) {
            setFinalDate([date[0].getTime(), date[1].getTime()]);
        }
        if (date && date[0] == null && date[1] == null) {
            setFinalDate([0, 9999999999999]);
        }
    }, [date]);

    //filter workshops by thematic
    const filteredWorkshopByThematic = workShopData.filter(function (w: any) {

        if (searchPlace == "") {
            if (w.site == null) {
                if (copilot) {
                    return (
                        w &&
                        w.title.toLowerCase().includes(search.toLowerCase()) &&
                        w &&
                        new Date(w.startDate).getTime() >= finalDate[0] &&
                        new Date(w.endDate).getTime() <= finalDate[1] &&
                        w.wantCopilot == true
                    );
                } else {
                    return (
                        w &&
                        w.title.toLowerCase().includes(search.toLowerCase()) &&
                        new Date(w.startDate).getTime() >= finalDate[0] &&
                        new Date(w.endDate).getTime() <= finalDate[1]
                    );
                }
            }
        }

        if (w.site != null) {
            if (copilot) {
                return (
                    w &&
                    w.title.toLowerCase().includes(search.toLowerCase()) &&
                    w &&
                    w.site?.id?.toString().includes(searchPlace) &&
                    w &&
                    new Date(w.startDate).getTime() >= finalDate[0] &&
                    new Date(w.endDate).getTime() <= finalDate[1] &&
                    w.wantCopilot == true
                );
            } else {
                return (
                    w &&
                    w.title.toLowerCase().includes(search.toLowerCase()) &&
                    w &&
                    w.site?.id?.toString().includes(searchPlace) &&
                    w &&
                    new Date(w.startDate).getTime() >= finalDate[0] &&
                    new Date(w.endDate).getTime() <= finalDate[1]
                );
            }
        }

    });

    const items = [
        {title: "Accueil", href: "/auth/home"},
        {title: "Ateliers", href: "/auth/workshop"},
    ].map((item, index) => (
        <Anchor
            component={Link}
            to={item.href}
            key={index}
            style={{color: "#333", fontSize: 14}}
        >
            {item.title}
        </Anchor>
    ));


    const filteredWorkshopByThematicAndRecommandation = filteredWorkshopByThematic.filter(function (w: any) {
        if (canSeeAdvices && wantendAdvices) {
            if (adviseThematics.find((wording: any) => w.title == wording.wording)) {
                return w;
            }
        } else {
            return w
        }
    });

    const {
        data: dataSearch,
        loading: loadingSearch,
        request: requestSearch,
    } = useApi(workshop.collectionSearch);

    useEffect(() => {
        requestSearch(
            page ?? 1,
            '',
            search,
            searchPlace,
            copilot,
            canSeeAdvices && wantendAdvices,
            finalDate
        )
    }, [page]);

    useEffect(() => {
        setPage(1)
        requestSearch(
            1,
            '',
            search,
            searchPlace,
            copilot,
            canSeeAdvices && wantendAdvices,
            finalDate
        )
    }, [search, searchPlace, finalDate, copilot, wantendAdvices]);

    return (
        <>
            <LoadingOverlay visible={loading || loadingSearch}/>
            <div className="w-screen md:w-auto px-6 py-2 md:p-12">
                <Breadcrumbs separator="/" styles={{separator: {color: "#333"}}}>
                    {items}
                </Breadcrumbs>
                <div className="mt-6 md:mt-10 text-3xl items-center flex">
                    {filteredWorkshopByThematicAndRecommandation.length < 2 ? "ATELIER" : "ATELIERS"}
                    <Link to="/auth/workshop/create">
                        <HasRoles role={ROLE_DXMAN_MORE}>
                            <Button className="ml-5 bg-gray-500 rounded" size="sm" compact>
                                <span> Créer</span>
                            </Button>
                        </HasRoles>
                    </Link>
                </div>
                <div className="md:max-w-3xl mt-3 md:my-6 relative">
                    <p className="text-sm md:text-md mr-6">
                        Bienvenue sur la page Ateliers. L'association vous permet de poursuivre votre
                        professionnalisation : choisissez les modules qui vous intéressent et inscrivez-vous ! Nos
                        formateurs bénévoles seront ravis de partager leur savoir.
                    </p>
                </div>
                {true && (
                    <>
                        {/* MOBILE FILTER */}
                        <div className="flex justify-end mt-8 md:hidden">
                            <button
                                onClick={() => setFilters(!filters)}
                                className="flex bg-gray-500 px-2 py-1 rounded text-white text-sm"
                            >
                                <svg
                                    className="w-4 h-4 mr-2"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
                                    />
                                </svg>
                                Filtres
                                <div className="ml-2 rounded-full bg-gray-400">
                                    <span className="text-xs px-2">1</span>
                                </div>
                            </button>
                        </div>
                        {filters ? (
                            <div className="bg-gray-50 p-2 shadow-md mt-2 mb-4 rounded md:hidden">
                                <div className="flex-1 m-2 relative">
                                    <Select
                                        searchable
                                        nothingFound="No options"
                                        clearable
                                        placeholder="Sélectionner un titre"
                                        data={[...filteredThematics]}
                                        value={search}
                                        onChange={(value) => setSearch(value === null ? "" : value)}
                                    />
                                </div>
                                <div className="flex-1 m-2">
                                    <Select
                                        searchable
                                        clearable
                                        placeholder="Sélectionner un lieu"
                                        data={[...sites]}
                                        value={searchPlace}
                                        onChange={(value) =>
                                            setSearchPlace(value === null ? "" : value)
                                        }
                                    />
                                </div>
                                <div className="flex-1 m-2">
                                    <DateRangePicker
                                        inputFormat="DD/MM/YYYY"
                                        labelFormat="MM/YYYY"
                                        locale="fr"
                                        clearable
                                        placeholder="Sélectionner une période"
                                        value={date}
                                        onChange={(value) => setDate(value)}
                                    />
                                </div>
                                <div className="flex justify-end p-2">
                                    <Checkbox
                                        size="xs"
                                        className="text-xs"
                                        checked={copilot}
                                        onChange={() => setCopilot(!copilot)}
                                        label="Ateliers qui recherchent un copilote"
                                    />
                                </div>
                                <div className="flex justify-end px-2">
                                    <button onClick={ResetSearch} className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2">
                                        {/* Tout afficher */}
                                        Annuler filtres
                                    </button>
                                </div>
                            </div>
                        ) : null}
                        {/* END MOBILE FILTER */}

                        {/* DESKTOP FILTER */}
                        <div className="md:flex justify-between hidden">
                            <div className="flex flex-wrap p-3 pr-0">
                                <div className="flex">
                                    <div className="m-2">
                                        <Select
                                            searchable
                                            nothingFound="No options"
                                            style={{minWidth: 185}}
                                            clearable
                                            placeholder="Sélectionner un titre"
                                            data={[...filteredThematics]}
                                            value={search}
                                            onChange={(value) => setSearch(value === null ? "" : value)}
                                        />
                                    </div>
                                    <div className="m-2">
                                        <Select
                                            searchable
                                            nothingFound="No options"
                                            clearable
                                            placeholder="Sélectionner un lieu"
                                            data={[...sites]}
                                            value={searchPlace}
                                            onChange={(value) =>
                                                setSearchPlace(value === null ? "" : value)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="m-2">
                                    <DateRangePicker
                                        inputFormat="DD/MM/YYYY"
                                        labelFormat="MM/YYYY"
                                        locale="fr"
                                        clearable
                                        placeholder="Sélectionner une période"
                                        value={date}
                                        onChange={(value) => setDate(value)}
                                    />
                                </div>
                                <div className="m-2 my-auto">
                                    {canSeeAdvices && (
                                        <Checkbox
                                            label="Afficher les ateliers recommandés"
                                            checked={wantendAdvices}
                                            onChange={(event) =>
                                                setWantendAdvices(event.currentTarget.checked)
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                            <div className="m-2">
                                <button
                                    onClick={ResetSearch}
                                    className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2"
                                    style={{width: 150}}
                                >
                                    {/* Tout afficher
                                    ({filteredWorkshopByThematicAndRecommandation.length > 1 && filteredWorkshopByThematicAndRecommandation.length}) */}
                                    Annuler filtres
                                    ({dataSearch.pagination?.totalItems})
                                </button>
                            </div>
                        </div>


                        <div className="hidden md:flex ml-8 text-sm">
                            <Checkbox
                                checked={copilot}
                                onChange={() => setCopilot(!copilot)}
                                className="mr-2"
                            />{" "}
                            Ateliers qui recherchent un copilote
                        </div>

                    </>
                )}
                {/* END OF DESKTOP FILTER */}
                <div className="md:flex-1 hidden mx-2 mb-6">
                    <Checkbox
                        className="text-xs"
                        checked={copilot}
                        onChange={() => setCopilot(!copilot)}
                        label="Ateliers qui recherchent un copilote"
                    />
                </div>
                {/* <div className="mt-2 md:mt-8 md:m-2">
                    {filteredWorkshopByThematicAndRecommandation.length ? (
                        filteredWorkshopByThematicAndRecommandation
                            .slice(page * perPage - perPage, perPage * page)
                            .map((workshop: any) => <HomeCardWorkshop isNotHome={true} data={workshop}/>)
                    ) : (
                        <span>Aucun Atelier n'a été trouvé</span>
                    )}
                </div>
                {filteredWorkshopByThematicAndRecommandation.length > perPage ? (
                    <div className="flex justify-center md:justify-end p-4">
                        <Pagination
                            count={Math.ceil(filteredWorkshopByThematicAndRecommandation.length / perPage)}
                            page={page}
                            onChange={(event, value) => setPage(value)}
                            shape="rounded"
                            color="primary"
                            hidePrevButton
                            hideNextButton
                            showFirstButton
                            showLastButton
                        />
                    </div>
                ) : null} */}

                <div className="mt-2 md:mt-8 md:m-2">
                    {(dataSearch.items?.length == 0) && (
                        <span>Aucun Atelier n'a été trouvé</span>
                    )}

                    {(dataSearch.items?.length > 0) && dataSearch.items.map((workshop: any) => (
                        <HomeCardWorkshop isNotHome={true} data={workshop}/>
                    ))}
                </div>
                {(dataSearch.pagination?.totalPages > 1) && (
                    <div className="flex justify-center md:justify-end p-4">
                        <PaginationCore
                            page={dataSearch.pagination?.page}
                            total={dataSearch.pagination?.totalPages}
                            onChange={setPage} />
                    </div>
                )}
                <Footer/>
            </div>
        </>
    );
};

export default ListingWorkshop;
