import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadingOverlay } from "@mantine/core";
import useApi from "../../hooks/useApi";
import users from "../../api/users";
import creditHistory from "../../api/creditHistory";
import ReadOnlyListingComponent from "./ReadOnlyListingComponent";
import { REACT_APP_API_BASE_URL } from "../../env";

const CreditHistoryPreview = () => {
  var { user_id, year } = useParams();

  if (!year) {
    year = new Date().getFullYear().toString();
  }

  const [page, setPage] = useState<number>(1);
  const [selectedYear, setSelectedYear] = useState<string>(year);
  
  const { 
    data,
    loading,
    request,
  } = useApi(users.getUserById);

  const { 
    data: dataCredits,
    loading: loadingCredits,
    request: requestCredits,
  } = useApi(creditHistory.detail);

  useEffect(() => {
    requestCredits(
      user_id,
      selectedYear,
      page,
    )
  }, [page]);

  useEffect(() => {
    setPage(1);
    requestCredits(
      user_id,
      selectedYear,
      page,
    )
  }, [selectedYear]);
  
  useEffect(() => {
    request(user_id).then((r) => {
      requestCredits(
        user_id,
        selectedYear,
        page,
      )
    });
  }, []);

  const getActionLabel = (action: string, description: string) => {
    switch (action) {
      case "error":
        return "Échec";
      case "cancel":
        return "Annulation";
      case "success":
        return "Succès";
      case "register":
        return "Inscription";
      case "register_adhesion":
        if (/Renouvellement adhésion.*Forman/i.test(description)) {
          return "Renouvellement adhésion";
        }
        else if (/Adhésion.*Forman/i.test(description)) {
          return "Adhésion";
        } 
        else if (/Renouvellement adhésion/i.test(description)) {
          return "Renouvellement adhésion";
        }
        else if (/Adhésion/i.test(description)) {
          return "Adhésion";
        }
        return "";
      default:
        return "";
    }
  };

  const rows = (dataCredits.credits || []).map((element: any) => (
    <tr key={element.id} className="hover:bg-white cursor-pointer">
      <td>
        {new Date(element.date).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        })}
      </td>
      <td>
        {new Date(element.date).toLocaleTimeString('fr-FR')}
      </td>
      <td>
        {element.type}
      </td>
      <td>
        {element.amount}
      </td>
      <td>
        {element.description}
      </td>
      <td>
        {getActionLabel(element.action, element.description)}
      </td>
    </tr>
  ));

  return (
    <>
      <div className="pb-12 pt-6 md:pt12 px-10 text-gray-600">
        <div className="flex flex-row  gap-2">
          <h1 className="uppercase text-3xl"> {data?.lastName} {data?.firstName} </h1>
        </div>
        <div className="flex flex-row justify-between mt-3 ">
          
          {(loading || loadingCredits) ? (
              <LoadingOverlay visible={true}/>
            ) : (
              <ReadOnlyListingComponent
                title={"Historique des avoirs"}
                selectedYear={parseInt(selectedYear)}
                onPageChange={setPage} 
                rows={rows}
                page={page} 
                exportLink={`${REACT_APP_API_BASE_URL}/export/credit-detail/${user_id}/${selectedYear}`}
                onYearChange={(year: string|null) => {
                  setSelectedYear(year ?? "0")
                }} 
                maxPage={(dataCredits.maxPage > 0) ? 
                    dataCredits.maxPage : 1
                } 
                thead={
                    <thead className="flex-grow">
                    <tr>
                        <th style={{width: 100}}>Date</th>
                        <th style={{width: 100}}>Heure</th>
                        <th style={{width: 100}}>Type</th>
                        <th style={{width: 100}}>Montant</th>
                        <th style={{width: 100}}>Description</th>
                        <th style={{width: 100}}>Action</th>
                    </tr>
                    </thead>
                }
              />
            )
          }
        </div>
      </div>
    </>
  );
};

export default CreditHistoryPreview;
