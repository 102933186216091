import React from 'react';


interface InfosCountCardAccountProps {
  title: string;
    number: number;
}

function InfosCountCardAccount({number, title}: InfosCountCardAccountProps) {
    return (
        <div
            className="flex flex-row-reverse w-full my-2 mx-auto overflow-hidden bg-white rounded-lg shadow-md dark:bg-gray-800">
            <div className="flex items-center justify-center w-24 bg-buttonAccount">
                <span className="text-white text-s21 font-bold">{number}</span>
            </div>

            <div className="px-4 -mx-3 py-4 text-left w-full ml-2  my-auto">
                <div className="mx-3 text-left ml-auto">
                    <p className="text-thirteen text-left ml-auto text-gray-600 dark:text-gray-200 ">{title}</p>
                </div>
            </div>
        </div>
    );
}

export default InfosCountCardAccount;