import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/hooks";
import { useNotifications } from "@mantine/notifications";
import useApi from "../../../hooks/useApi";
import teamMeeting from "../../../api/teamMeeting";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Chips,
  Col,
  LoadingOverlay,
  MultiSelect,
  NumberInput,
  Select,
  SimpleGrid,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePicker, DateRangePicker, TimeInput } from "@mantine/dates";
import { Link, useNavigate, useParams } from "react-router-dom";
import "dayjs/locale/fr";
import dayjs from "dayjs";
import { ROLE_WITHOUT_TOUS, ROLES } from "../../../constants/roles";
import {
  HABILITATION,
  HABILITATION_WITHOUT_TOUS,
} from "../../../constants/habilitation";
import Footer from "../../Footer";
import users from "../../../api/users";
import teamMeetingDate from "../../../api/teamMeetingDate";
import { RichTextEditor } from "@mantine/rte";
import site from "../../../api/site";
import { handleImageUpload } from "../../../utility/imageUpload";

const EditTeamMeeting = () => {
  const [value, setValue] = useState<[Date, Date]>();
  const { id } = useParams();
  const [first, setFirst] = useState(true);
  const navigation = useNavigate();
  const [CopilotData, setCopilotData] = useState([]);
  const [SubCopilotData, setSubCopilotData] = useState([]);
  const [GuestData, setGuestData] = useState([]);
  const [SubGuestData, setSubGuestData] = useState([]);
  const [search, setSearch] = useState<string>("");
  const [searchGuest, setSearchGuest] = useState<string>("");
  const [searchStart, setSearchStart] = useState<string>("");
  const notifications = useNotifications();
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [displayButton, setDisplayButton] = useState(true);
  const { request } = useApi(teamMeeting.item);
  const { request: requestMe, data: user } = useApi(users.me);

  const { request: requestDate } = useApi(teamMeetingDate.create);

  const { request: requestDeleteDate } = useApi(
    teamMeeting.deleteDateByteamMeetingId,
  );

  const { request: requestSite, data: dataSite } = useApi(site.collection);

  const { request: requestCopilot } = useApi(
    teamMeeting.registerApprouvedCopilot,
  );

  const { request: requestGuests } = useApi(teamMeeting.AddGuests);

  const { request: requestEditteamMeeting } = useApi(teamMeeting.edit);
  const [formValues, setFormValues] = useState<any>([]);

  useEffect(() => {
    // @ts-ignore
    request(id).then((r) => {
      requestMe().then((r2) => {
        if (!(r2.data.isPilot || r2.data.roles == "ROLE_XMAN")) {
          navigation("/");
        } else {
          const users = r.data?.copilotApprouved ?? [];
          const dates = r.data?.teamMeetingDates ?? [];
          const guests = r.data?.guests ?? [];

          const tabDates: any = [];
          for (let i = 0; i < dates.length; i++) {
            tabDates.push({
              startAt: dayjs(dates[i].dateAt).subtract(1, "hour").toDate(),
              endAt: dayjs(dates[i].endAt).subtract(1, "hour").toDate(),
            });
          }
          setFormValues([...tabDates]);

          setValue([
            dayjs(r.data?.startDate.slice(0, 19)).add(1, "day").toDate(),
            dayjs(r.data?.endDate.slice(0, 19)).add(1, "day").toDate(),
          ]);

          setFirst(false);

          const tab: [] = [];
          users.forEach((u: any) => {
            // @ts-ignore
            tab.push(u.email);
          });

          const gueststab: [] = [];
          guests.forEach((g: any) => {
            // @ts-ignore
            gueststab.push(g.email);
          });

          form.setFieldValue("copilots", tab);

          form.setFieldValue("guests", gueststab);

          form.setValues({
            thematic: "Réunion d'équipe",
            title: r.data?.title,
            place: r.data?.place,
            description: r.data?.description,
            prerequisite: r.data?.prerequisite,
            formerWords: r.data?.formerWords,
            videoConferenceLink: r.data?.videoConferenceLink,
            startDate: dayjs(r.data?.startDate ?? new Date()).toDate(),
            endDate: dayjs(r.data?.endDate ?? new Date()).toDate(),
            visioPassword: r.data?.visioPassword,
            isVisio: r.data?.isVisio + "",
            wantCopilot: r.data?.wantCopilot,
            destined: r.data?.destined,
            habilitation: r.data?.habilitation,
            copilots: tab,
            site: r.data?.site?.id.toString(),
            guests: gueststab,
            maxGuests: r.data?.maxGuests,
          });
          //console.log("forValue", form.values)
          //console.log("detined : ",form.values.destined)
          requestSite().then((value) => {
            setSites(
              value.data.map((site: any) => {
                return {
                  label: site.name,
                  value: "" + site.id,
                };
              }),
            );
          });
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { request: requestUser } = useApi(users.getFromBemanByLastName);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const searchCopilotEmail = () => {
    requestUser(search).then((res) => {
      const tab: any = [];
      for (let i = 0; i < res.data.length; i++) {
        tab.push({label : res.data[i].lastName + " " + res.data[i].firstName, value : res.data[i].email});
      }
      setSubCopilotData(tab);
      setCopilotData(tab);
    });
  };

  const searchGuestEmail = () => {
    requestUser(searchGuest).then((res) => {
      const tab: any = [];
      for (let i = 0; i < res.data.length; i++) {
        tab.push(res.data[i].email);
      }
      setSubGuestData(tab);
      setGuestData(tab);
    });
  };

  useEffect(() => {
    if (search?.length > 2) {
      if (search?.length == 3) {
        setSearchStart(search);
        searchCopilotEmail();
      } else {
        if (search.slice(0, 3) == searchStart) {
          setCopilotData(
            SubCopilotData.filter((item: any) => {
              if (
                item?.toLowerCase().includes(search.toLowerCase()) &&
                item != user?.email
              ) {
                return item;
              }
            }),
          );
        } else {
          searchCopilotEmail();
        }
      }
    }
  }, [search]);

  useEffect(() => {
    if (searchGuest?.length > 2) {
      if (searchGuest?.length > 3) {
        setSearchStart(searchGuest);
        searchGuestEmail();
      }
    }
  }, [searchGuest]);

  const handleChange = (i: any, e: any, name: any) => {
    let formValues1 = formValues;
    // @ts-ignore
    formValues1[i][name] = e;
    setFormValues(formValues1);
    console.log(formValues);
  };

  useEffect(() => {
    if (value && value[0] && value[1]) {
      console.log(value[0], value[1]);
      form.setFieldValue("endDate", value[1]);
      form.setFieldValue("startDate", value[0]);
      console.log(form?.values);

      const startAt = value[0];
      const endAt = value[1];
      // @ts-ignore
      const days = dayjs(endAt).diff(dayjs(startAt), "day");

      if (!first) {
        const tab: any = [];
        for (let i = 0; i < days + 1; i++) {
          tab.push({
            startAt: new Date("2022-01-01T09:00:00"),
            endAt: new Date("2022-01-01T18:00:00"),
          });
        }
        setFormValues([...tab]);
      }
    }
  }, [value]);

  const handleSubmit = async (values: any) => {
    console.log(form.errors,form.validate())
    if (form.validate()) {
      setLoading(true);
      console.log(values.habilitation);

      // @ts-ignore
      await requestEditteamMeeting(values, id)
        .then(async (values: any) => {
          const { id } = values.data;

          await requestCopilot(form.values.copilots, id);
          await requestGuests(form.values.guests, id);
          await requestDeleteDate(id);

          for (let i = 0; i < formValues.length; i++) {
            await requestDate(
              dayjs(formValues[i].startAt).add(1, "hour").toDate(),
              id,
              dayjs(formValues[i].endAt).add(1, "hour").toDate(),
            );
          }

          notifications.showNotification({
            title: "Nice !",
            message: "évenement modifiée ! 😀",
            color: "green",
          });
          navigation("/auth/teamMeeting/detail/" + id, { replace: true });
        })
        .catch((values: any) => {
          notifications.showNotification({
            title: "Une erreur est survenue.",
            message: "Impossible de modifier un évenement ! 🤥",
            color: "red",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const form = useForm({
    initialValues: {
      thematic: "Réunion d'équipe",
      title: "",
      place: "",
      description: "",
      prerequisite: "",
      formerWords: "",
      videoConferenceLink: "",
      startDate: new Date(),
      endDate: new Date(),
      visioPassword: "",
      isVisio: "false",
      wantCopilot: false,
      destined: [],
      habilitation: [],
      copilots: [],
      site: "",
      guests: [],
      maxGuests: 0,
    },

    validationRules: {
      title: (value) => value.length >= 3,
      site: (value, values) =>
          values?.isVisio == "false" ? value != "" && value != null : true,
      videoConferenceLink: (value, values) =>
          values?.isVisio == "true" ? value != "" : true,
      maxGuests: (value) => value >= 0,
      startDate: () =>
          !value || value[0] == null || value[1] == null ? false : true,
    },
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Réunion d'équipes", href: "/auth/teamMeeting" },
    {
      title: "Modification d'une réunion d'équipe",
      href: "/auth/teamMeeting/edit/" + id,
    },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  useEffect(() => {
    // @ts-ignore
    if (form.values.destined.includes("All")) {
      // @ts-ignore
      form.setFieldValue("destined", ROLE_WITHOUT_TOUS);
    }
  }, [form.values.destined]);

  useEffect(() => {
    // @ts-ignore
    if (form.values.habilitation.includes("All")) {
      // @ts-ignore
      form.setFieldValue("habilitation", HABILITATION_WITHOUT_TOUS);
    }
  }, [form.values.habilitation]);

  useEffect(() => {
    if (form.values.isVisio == "true") {
      form.setFieldValue("place", "");
      form.setFieldValue("site", "");
    } else {
      form.setFieldValue("videoConferenceLink", "");
      form.setFieldValue("visioPassword", "");
    }
  }, [form.values.isVisio]);

  window.onscroll = () => {
    //@ts-ignore
    if (window.scrollY + window.innerHeight < document.getElementById("SubmitFix")?.offsetTop + document.getElementById("SubmitFix")?.offsetHeight
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  return (
    <div className="pb-12 pt-6 md:pt-12 pl-4 pr-6">
      <div className="mx-auto md:px-4 sm:px-6 lg:px-8">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <h2 className="text-3xl mt-6 md:mt-10">
          Modification d'une réunion d'équipe
        </h2>
        <p className="max-w-3xl my-5">
          Vous êtes sur le point de modifier une réunion d'équipe : merci !
          Il vous suffit de modifier un ou plusieurs champs suivants.
        </p>

        <div className="mt-10">
          <LoadingOverlay visible={loading} />
          <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <div className="md:flex md:justify-between">
              <div className="md:w-7/12">
                <div>
                  <h3 className="text-2xl">Information générales</h3>
                  <div className="flex flex-row  mt-4 gap-20">
                    {/* <Select
                                             className='md:w-4/5'
                                                placeholder={window.innerWidth > 450 ? "Choix de thématique" : "La thématique"}
                                                searchable
                                                clearable
                                                data={thematics}
                                                onChange={(value: string) => setThematicSelected(parseInt(value))}
                                            /> */}
                    <TextInput
                      className="w-full"
                      required
                      placeholder="Titre"
                      error={
                        form.errors.title &&
                        "Veuillez spécifier un titre valide"
                      }
                      value={form.values.title}
                      onChange={(event) =>
                        form.setFieldValue("title", event.currentTarget.value)
                      }
                    />
                    {/* modifié la taille du select pour l'ajuster au multiSlect*/}
                  </div>
                  <div className="flex flex-col mt-4 gap-3">
                    <MultiSelect
                      className="w-full"
                      data={[...CopilotData, ...form.values.copilots]}
                      placeholder={
                        window.innerWidth > 450
                          ? "Sélectionnez les copilotes"
                          : "Les copilotes"
                      }
                      searchable
                      nothingFound="Aucun résulat"
                      maxSelectedValues={3}
                      value={form.values.copilots}
                      onSearchChange={(value) => setSearch(value)}
                      onChange={(value: []) =>
                        form.setFieldValue("copilots", value)
                      }
                    />
                    <Checkbox
                      label="Accepter les candidatures pour être copilote ?"
                      checked={form.values.wantCopilot}
                      onChange={(event) =>
                        form.setFieldValue(
                          "wantCopilot",
                          event.currentTarget.checked,
                        )
                      }
                    />
                  </div>
                  <div className="flex flex-col  mt-4 gap-3">
                    <MultiSelect
                      className="w-full"
                      data={[...GuestData, ...form.values.guests]}
                      placeholder={
                        window.innerWidth > 450
                          ? "Sélectionnez les invités"
                          : "Les invités"
                      }
                      searchable
                      nothingFound="Aucun résulat"
                      value={form.values.guests}
                      onSearchChange={(value) => setSearchGuest(value)}
                      onChange={(value: []) =>
                        form.setFieldValue("guests", value)
                      }
                    />
                  </div>
                </div>

                <div className="my-10">
                  <h3 className="text-2xl mb-3">Dates & Lieu</h3>
                  <DateRangePicker
                    className="mb-4"
                    locale="fr"
                    allowSingleDateInRange
                    inputFormat="DD/MM/YYYY"
                    labelFormat="MM/YYYY"
                    placeholder="Date"
                    minDate={new Date()}
                    maxDate={new Date(2030, 11, 31)}
                    error={
                      form.errors.startDate &&
                      "Veuillez spécifier une date valide"
                    }
                    value={value}
                    onChange={(teamMeeting: [Date, Date]) =>
                      setValue(teamMeeting)
                    }
                    required
                  />
                  <div className="flex gap-3">
                    <div className="grid grid-cols-2 gap-4 w-full">
                      {formValues.map((element: any, index: any) => (
                        <>
                          <div className="col-span-1">
                            <TimeInput
                              name="startAt"
                              required
                              placeholder="Horaires de début"
                              value={element.startAt}
                              onChange={(e) =>
                                handleChange(index, e, "startAt")
                              }
                            />
                          </div>
                          <div className="col-span-1">
                            <TimeInput
                              name="endAt"
                              required
                              placeholder="Horaires de fin / Jour 1"
                              value={element.endAt}
                              onChange={(e) => handleChange(index, e, "endAt")}
                            />
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div>
                    <Chips
                      value={form.values.isVisio}
                      onChange={(teamMeeting: string) =>
                        form.setFieldValue("isVisio", teamMeeting)
                      }
                      className="flex justify-center my-5 w-full"
                      color="indigo"
                      variant="filled"
                      size="xl"
                      radius="sm"
                    >
                      <Chip value="false">Évenement physique</Chip>
                      <Chip value="true">Évenement à distance</Chip>
                    </Chips>
                  </div>

                  {form.values.isVisio === "false" && (
                    <>
                      <Select
                        className="w-full"
                        placeholder="Choix du lieu"
                        searchable
                        clearable
                        value={form.values.site}
                        data={sites}
                        onChange={(value: string) =>
                          form.setFieldValue("site", value)
                        }
                      />
                    </>
                  )}
                  {form.values.isVisio === "true" && (
                    <>
                      <TextInput
                        className="mb-4"
                        placeholder="Lien de la visioconférence"
                        error={
                          form.errors.videoConferenceLink &&
                          "Veuillez specifier un lien valide"
                        }
                        value={form.values.videoConferenceLink}
                        onChange={(teamMeeting) =>
                          form.setFieldValue(
                            "videoConferenceLink",
                            teamMeeting.currentTarget.value,
                          )
                        }
                      />
                      <TextInput
                        className="mb-4"
                        placeholder="Mot de passe de la visioconférence (si nécessaire)"
                        error={
                          form.errors.visioPassword &&
                          "Veuillez spécifier un mot de passe valide"
                        }
                        value={form.values.visioPassword}
                        onChange={(teamMeeting) =>
                          form.setFieldValue(
                            "visioPassword",
                            teamMeeting.currentTarget.value,
                          )
                        }
                      />
                      <TextInput
                        className="mb-1"
                        required
                        placeholder="Infos de la visioconférence (si nécessaire)"
                      />
                      <div className="text-justify mx-2">
                        <small className="text-xs">
                          Merci d'indiquer au moins la plateforme utilisée
                          (zoom, teams, meet, skype...)
                        </small>
                      </div>
                      <Select
                        className="mt-3"
                        data={["30 min", "1h00", "1h30"]}
                        placeholder="Temps de pause"
                        required
                      ></Select>
                    </>
                  )}
                </div>
                <div>
                  <h3 className="text-2xl mb-4">Détails</h3>

                  <div className="mb-4">
                    <label>Description / Mots du Formateur</label>
                    <RichTextEditor
                      value={form.values.formerWords}
                      onChange={(teamMeeting) =>
                        form.setFieldValue("formerWords", teamMeeting)
                      }
                      onImageUpload={handleImageUpload}
                    />
                  </div>

                  <div className="mb-4">
                    <label>Pré-requis</label>
                    <RichTextEditor
                      value={form.values.prerequisite}
                      onChange={(teamMeeting) =>
                        form.setFieldValue("prerequisite", teamMeeting)
                      }
                      onImageUpload={handleImageUpload}
                    />
                  </div>
                </div>
              </div>
              <div className="md:w-3/12">
                <h2 className="text-xl">Places</h2>
                <NumberInput
                    className="mt-3"
                    placeholder="Nombre de participants maximum"
                    error={
                        form.errors.maxGuests &&
                        "Veuillez spécifier un maximum d'invités valide"
                    }
                    value={form.values.maxGuests}
                    onChange={(event: number) =>
                        form.setFieldValue("maxGuests", event)
                    }
                    required
                    min={0}
                />
                <div className="my-10">
                  <h2 className="text-xl mb-2">Public visé</h2>
                  <MultiSelect
                    data={ROLES}
                    onChange={(event: []) =>
                      form.setFieldValue("destined", event)
                    }
                    value={form.values.destined}
                    placeholder="Public visé"
                  />
                </div>
                <div className="my-10">
                  <h2 className="text-xl mb-2">Habilitation requise</h2>
                  <MultiSelect
                    data={HABILITATION}
                    onChange={(event: []) =>
                      form.setFieldValue("habilitation", event)
                    }
                    value={form.values.habilitation}
                    placeholder="Habilitation"
                  />
                </div>
              </div>
            </div>
            <div id="SubmitFix" className="flex mb-2 justify-center md:block">
              <Button
                onClick={() => handleSubmit(form.values)}
                mt={20}
                mr={5}
                className="px-20"
                size="md"
                color="pink"
                type="button"
              >
                Je valide
              </Button>
            </div>
            <div
              className={`hidden md:${
                displayButton ? "block" : "hidden"
              } fixed bottom-0 right-0 mr-16 bg-white px-3 pb-3 shadow-xl border`}
            >
              <Button
                onClick={() => handleSubmit(form.values)}
                mt={20}
                mr={5}
                className="mr-auto px-20"
                size="md"
                color="pink"
              >
                Je valide
              </Button>
            </div>
          </form>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default EditTeamMeeting;
