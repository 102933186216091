import React, {useEffect, useState} from "react";
import {Link, useNavigate, useParams} from "react-router-dom";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import users from "../../../api/users";
import {
    Anchor,
    Badge,
    LoadingOverlay,
    SimpleGrid,
    Button,
    Grid,
    Col,
    Spoiler,
    Breadcrumbs,
    Text,
    Divider,
    ActionIcon,
    Tooltip,
} from "@mantine/core";
import {User} from "../../../entity/User";
import {useNotifications} from "@mantine/notifications";
import storage from "../../../auth/storage";
import {useModals} from "@mantine/modals";
import {CheckIcon} from "@heroicons/react/solid";
import {FaUser} from "react-icons/fa";
import {
    FiUserCheck,
    FiUserX, GiTicket,
    IoIosCopy,
    IoMdOpen,
    MdOutlineCancel,
} from "react-icons/all";
import {useClipboard, useWindowScroll} from "@mantine/hooks";
import {RiFolderSharedLine} from "react-icons/ri";
import formation from "../../../api/formation";
import profilePicture from "../../../assets/images/profile-picture-test.png";
import graphTest from "../../../assets/images/test-graph.png";
import CardCollection from "../../../components/workshop/CardCollection";
import HasRoles from "../../../components/Security/hasRoles";
import {
    ROLE_XMAN,
    ROLE_BEMAN_MORE,
    ROLE_DXMAN_MORE,
} from "../../../constants/roles";
import {FaCopy} from "react-icons/fa";
import Footer from "../../Footer";
import {CSVLink} from "react-csv";
import ButtonWrapper from "../../../components/paypal/ButtonWrapper";
import {Modal} from "@mantine/core";
import transactions from "../../../api/transactions";
import creditHistory from "../../../api/creditHistory";
import { 
    format_d_MMMM_YYYY,
    format_dddd_d_MM_YYYY,
    format_dddd_d_MMMM_YYYY
} from "../../../utility/dateUtility";

import ItemWorkshopWrapped from "./ItemWorkshopWrapped";

const ItemWorkshop = () => {
    const { id } = useParams();
    const [openedPaypal, setopenedPaypal] = useState(false);
    const {request, loading} = useApi(workshop.item);
    const [requestLoading, setRequestLoading] = useState(false);
    const [Completed, setCompleted] = useState(false);
    const [totalPrice, setTotalPrice] = useState(Number);
    const [totalBasePrice, setTotalBasePrice] = useState(Number);
    const [dataWorkshop, setDataWorkshop] = useState<any>();

    // @ts-ignore
    return (

        <>
            <LoadingOverlay visible={loading} />
            <LoadingOverlay visible={requestLoading} />
            <Modal
                opened={openedPaypal}
                onClose={() => setopenedPaypal(false)}
                title="Paiement par Paypal"
            >
                <ButtonWrapper
                    Completed={() => setCompleted(true)}
                    currency="EUR"
                    showSpinner={false}
                    amount={totalPrice}
                    totalBasePrice={totalBasePrice}
                    itemName={dataWorkshop && dataWorkshop.title}
                    //@ts-ignore
                    WorkshopId={parseInt(id)}
                    //@ts-ignore
                    workshop={parseInt(id)}
                />
            </Modal>
            <ItemWorkshopWrapped
                id={id}

                openedPaypal={openedPaypal}
                setopenedPaypal={setopenedPaypal}

                dataWorkshop={dataWorkshop}
                setDataWorkshop={setDataWorkshop}

                totalPrice={totalPrice}
                setTotalPrice={setTotalPrice}
                setTotalBasePrice={setTotalBasePrice}

                Completed={Completed}
                setCompleted={setCompleted}

                setRequestLoading={setRequestLoading}
                requestLoading={requestLoading}

                request={request}
                loading={loading}
            />
        </>
    );
};

export default ItemWorkshop;
