import request from './request';

const endpoint = '/workshops';

const create = (workshop: any) => {
  return request.post(endpoint, {
    thematic: workshop.thematic,
    title: workshop.title,
    price: parseFloat(workshop.price),
    date: workshop.date,
    place: 'place',
    former: '/api/users/' + workshop.former,
    maxGuests: workshop.maxGuests,
    minGuests: workshop.minGuests,
    description: workshop.description,
    videoConferenceLink: workshop.videoConferenceLink,
    prerequisite: workshop.prerequisite,
    formerWords: workshop.formerWords,
    startDate: workshop.startDate,
    endDate: workshop.endDate,
    visioPassword: workshop.visioPassword,
    isVisio: workshop.isVisio === 'true',
    wantCopilot: workshop.wantCopilot,
    destined: workshop.destined,
    habilitation: workshop.habilitation,
    site: workshop.isVisio === 'true' ? null : '/api/sites/' + workshop.site,
  });
};

const edit = (workshop: any, id: number) => {
  return request.put(endpoint + '/' + id, {
    thematic: workshop.thematic,
    title: workshop.title,
    price: parseFloat(workshop.price),
    date: workshop.date,
    place: 'place',
    maxGuests: workshop.maxGuests,
    minGuests: workshop.minGuests,
    description: workshop.description,
    videoConferenceLink: workshop.videoConferenceLink,
    prerequisite: workshop.prerequisite,
    formerWords: workshop.formerWords,
    startDate: workshop.startDate,
    endDate: workshop.endDate,
    visioPassword: workshop.visioPassword,
    isVisio: workshop.isVisio === 'true',
    wantCopilot: workshop.wantCopilot,
    destined: workshop.destined,
    habilitation: workshop.habilitation,
    site:
      workshop.isVisio === 'true'
        ? null
        : '/api/sites/' + parseInt(workshop.site),
  });
};

const editFormer = (userId: any, id: number) => {
  return request.put(endpoint + '/' + id, {
    former: "/api/users/"+userId,
  });
};

let date = new Date();
const x = 2; // go back 5 days!
date.setDate(date.getDate() - x);

const collectionAll = () => {
    return request.get(endpoint + '?isPublish=1');
}

const collectionPaginate = (page: Number) => {
  return request.get(endpoint + '/collection?isPublish=1&page='+page);
}

const collection = (role: string, habilitations: Array<any>, startDate : string|null) => {
  if(startDate != null){
    date = new Date(startDate);
    date.setDate(date.getDate()+1)
  }

  return request.get(
    endpoint +
      '?isPublish=1&destined=' +
      role +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      (date.getDate())  +
      '' +
      "&habilitation[]=[]"
      +
      habilitations
        .map((habilitation) => '&habilitation[]=' + habilitation)
        .join('')
  );


};

const collectionSearch = (page: any, userId: any, search: any, siteId: any, wantCopilot: any, wantAdvices: any, dateInteval: any) => {
  var dateStart = new Date();
  dateStart.setTime(0);

  var dateEnd = new Date();
  dateEnd.setTime(9999999999999);

  if(dateInteval && dateInteval[0]){
    dateStart.setTime(dateInteval[0]);
  }

  if(dateInteval && dateInteval[1]){
    dateEnd.setTime(dateInteval[1]);
  }

  const format = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return request.get(
    endpoint +
      '/search?isPublish=1' +
      '&page=' + page +
      '&user=' + userId +
      '&search=' + search +
      '&site=' + siteId +
      '&wantCopilot=' + wantCopilot +
      '&wantAdvices=' + wantAdvices +
      '&dateStart=' + format(dateStart) +
      '&dateEnd=' + format(dateEnd)
  )
};

const collectionByFilter = (filter: string, value: any) => {
  return request.get(
    endpoint +
      '?isPublish=1&' +
      filter +
      '=' +
      value +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ''
  );
};

const collectionByFilterEdu = (filter: string, value: any) => {
  return request.get(endpoint + '?' + filter + '=' + value);
};

const remove = (id: Number) => {
  return request.delete(endpoint + '/' + id);
};

const item = (id: Number) => {
  return request.get(endpoint + '/' + id);
};

const publish = (id: Number) => {
  return request.put(endpoint + '/' + id, {
    isPublish: true,
  });
};

const Unpublish = (id: Number) => {
  return request.put(endpoint + '/' + id, {
    isPublish: 0,
  });
};

const registerGuest = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join');
};

const unRegisterGuest = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel');
};

const registerInWaitingZone = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join/waiting_zone');
};

const unRegisterInWaitingZone = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel/waiting_zone');
};

const registerApprouvedCopilot = (emails: any, id: number) => {
  return request.post(endpoint + '/' + id + '/join/copilot/approuved', {
    emails: [...emails],
  });
};

const unRegisterApprouvedCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel/copilot/approuved');
};

const registerCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/join-copilot');
};

const unRegisterCopilot = (id: Number) => {
  return request.post(endpoint + '/' + id + '/cancel-copilot');
};

const deleteDateByWorkshopId = (id: number) => {
  return request.put(endpoint + '/' + id + '/dates/delete');
};

const getWorkshopByThematic = (thematic: string) => {
  return request.get(
    endpoint +
      '?isPublish=1&thematic=' +
      thematic +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ''
  );
};

const getWorkshopByTitle = (title: string) => {
  return request.get(
    endpoint +
      '?isPublish=1&title=' +
      title +
      '&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ''
  );
};

const getWorkshopJourneeDecouverte = (userId: number) => {
  date.setDate(date.getDate() - 14);
  return request.get(
    endpoint +
      '?isPublish=1&guests.id=' +
      userId +
      '&title=Decouverte&startDate[after]=' +
      '' +
      date.getFullYear() +
      '-' +
      (date.getMonth() + 1) +
      '-' +
      date.getDate() +
      ''
  );
};

const getWorkshopNonePublish = () => {
  return request.get(endpoint + '?isPublish=0');
};

const getWorkshopsWithCopilotWanted = () => {
  return request.get('/workshops/copilotWanted'+ '?isPublish=1');
};

const unRegisterCopilotBy = (id: number, userId: number) => {
  return request.post(endpoint + '/' + id + '/cancel-copilot-by', {
    id: userId,
  });
};

const registerApprouvedCopilotBy = (id: number, userId: number) => {
  return request.post(endpoint + '/' + id + '/join/copilot/approuved/by', {
    id: userId,
  });
};

const exist = (id: number, date: any) => {
  return request.get(endpoint + '/exist/' + id + '/'+date);
};

const getWorkshopAfterDateByFormerAndNotEndedAbsent = (formerId: number) => {
  return request.get(endpoint + "?isPublish=1&former.id="+formerId+"&isAbsentEnded=false");
};

const getWorkshopAfterDateByCopilotAndNotEndedAbsent = (formerId: number) => {
  const now = new Date()
  return request.get(endpoint + "?isPublish=1&startDate[before]="+now.getFullYear()+"-"+(now.getMonth()+1)+"-"+now.getDay()+"&copilotApprouved.id="+formerId+"&isAbsentEnded=false");
};

const updateWorkshopAbsents = (users: Array<any>, id: number) => {
  return request.put(endpoint + '/' + id, {
    absentGuest: [...users],
    isAbsentEnded: true,
  });
};

const updateWorkshopWaiting = (isWaiting: boolean, id: number) => {
  return request.put(endpoint + '/' + id, {
    isUniqueWaiting: isWaiting
  });
};


const exportedApiWorkshops = {
  create,
  exist,
  collection,
  collectionAll,
  collectionSearch,
  collectionPaginate,
  collectionByFilter,
  item,
  publish,
  registerGuest,
  registerCopilot,
  unRegisterGuest,
  unRegisterCopilot,
  edit,
  getWorkshopByThematic,
  remove,
  registerInWaitingZone,
  unRegisterInWaitingZone,
  registerApprouvedCopilot,
  unRegisterApprouvedCopilot,
  deleteDateByWorkshopId,
  getWorkshopJourneeDecouverte,
  getWorkshopNonePublish,
  Unpublish,
  unRegisterCopilotBy,
  registerApprouvedCopilotBy,
  getWorkshopsWithCopilotWanted,
  getWorkshopAfterDateByFormerAndNotEndedAbsent,
  updateWorkshopAbsents,
  getWorkshopByTitle,
  collectionByFilterEdu,
  editFormer,
  getWorkshopAfterDateByCopilotAndNotEndedAbsent,
  updateWorkshopWaiting
};

export default exportedApiWorkshops;
