import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import event from "../../api/event";
import useApi from "../../hooks/useApi";
import storage from "../../auth/storage";
import { useNotifications } from "@mantine/notifications";
import { Button } from "@mantine/core";
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

const CardCollection = ({ data }: any) => {
    const notifications = useNotifications();
    const [requestLoading, setRequestLoading] = useState(false);
    const { id: UserId }: any = storage.getUser();
    const { request: requestevent } = useApi(event.item);
    const idevent = data?.id;
    const [isGuest, setisGuest] = useState(Boolean);
    const [isLink, setisLink] = useState(true);
    const [dataEvent, setdataEvent] = useState<any>();
    const [isInWaitingZone, setIsInWaitingZone] = useState(false);

    useEffect(() => {
        requestevent(idevent).then((r) => {
            setdataEvent(r?.data);
            if (r?.data?.externLink == "") {
                setisLink(false);
            }
        });
    }, []);

    useEffect(() => {
        setisGuest(
            !!dataEvent?.guests.find(
                (guest: { id: any }) => guest.id === UserId,
            ),
        );
        setIsInWaitingZone(
            !!dataEvent?.waitingZone.find(
                (guest: { id: any }) => guest.id === UserId,
            ),
        );
    }, [dataEvent]);

    const handleGuestWaitingZone = async () => {
        setRequestLoading(true);
        if (isInWaitingZone) {
            const result = await event.unRegisterInWaitingZone(Number(data.id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: " L'annulation n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            }
            notifications.showNotification({
                title: "Nice !",
                message:
                    "Vous êtes maintenant plus inscrit dans la file d'attente ! 😀",
                color: "green",
            });
            setRequestLoading(false);
        }
        if (!isInWaitingZone) {
            const result = await event.registerInWaitingZone(Number(data.id));
            if (!result.ok) {
                notifications.showNotification({
                    title: "Une erreur est survenue.",
                    message: " L'inscription n'a pas pu aboutir ! 🤥",
                    color: "red",
                });
                setRequestLoading(false);
            }
            notifications.showNotification({
                title: "Nice !",
                message:
                    "Vous êtes maintenant inscrit dans la file d'attente ! 😀",
                color: "green",
            });
            setRequestLoading(false);
        }
        requestevent(data.id).then((r) => {
            setdataEvent(r.data);
        });
    };

    return (
        <div className="w-full">
            <Link to={"/auth/event/detail/" + data?.id}>
                <div className="md:flex p-3 bg-white  bg-opacity-70 hover:bg-opacity-100 cursor-pointer border border-b-1 duration-150">
                    <div
                        className={`mr-auto grid gap-1 grid-cols-1 ${
                            isLink && "items-center"
                        }`}
                    >
                        <h4
                            style={{
                                fontWeight: "bold",
                                color: "#687687",
                                maxWidth: "240px",
                                fontSize: "15px",
                            }}
                        >
                            {data?.title.charAt(0).toUpperCase() +
                                data?.title.toLowerCase().slice(1)}
                        </h4>
                        <div>
                            {!isLink && (
                                <span
                                    className="rounded-md py-1 px-2 mr-auto text-sm"
                                    style={{
                                        background: isGuest
                                            ? "#84cc16"
                                            : "#FAFAFA",
                                        color: isGuest ? "white" : "#687687",
                                        opacity: isGuest ? 0.6 : 1,
                                    }}
                                >
                                    {data?.totalGuests}/{data?.maxGuests}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex justify-between mt-4 md:mt-0 ml-auto md:grid grid-cols-1">
                        <span
                            className="text-right"
                            style={{ fontSize: "11px" }}
                        >
                            {format_d_MMMM_YYYY(data?.startDate)}
                        </span>
                        {data?.isVisio ? (
                            <p
                                className="text-right text-sm"
                                style={{ fontSize: "11px", color: "#687687" }}
                            >
                                Visio
                            </p>
                        ) : (
                            <>
                                {data?.site && data?.site != "" ? (
                                    <div className="flex-col">
                                        <p
                                            className="text-right text-sm uppercase"
                                            style={{
                                                fontSize: "11px",
                                                color: "#687687",
                                            }}
                                        >
                                            {data?.site?.city} (
                                            {data?.site?.postalCode.slice(0, 2)}
                                            )
                                        </p>
                                    </div>
                                ) : (
                                    <p
                                        className="text-right text-sm"
                                        style={{
                                            fontSize: "11px",
                                            color: "#687687",
                                        }}
                                    >
                                        {data?.place}
                                    </p>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Link>
            {!isLink && data?.totalGuests >= data?.maxGuests && (
                <div className="flex items-start items-center flex-wrap text-sm font-medium px-3 pb-3">
                    <div className="text-white bg-red-500 rounded-md py-1 px-3 mr-2">
                        complet
                    </div>
                    {isGuest ? null : (
                        <>
                            <div className="mr-2 my-1">
                                <Button
                                    onClick={() => alert("fonctionalité futur")}
                                    size="xs"
                                    styles={(theme) => ({
                                        root: {
                                            backgroundColor: "#F0F1F3",
                                            "&:hover": {
                                                backgroundColor: "#E3E4E6",
                                            },
                                        },
                                    })}
                                    fullWidth
                                >
                                    <span className="text-xs text-gray-500">
                                        Prochaines dates
                                    </span>
                                </Button>
                            </div>
                            <div>
                                <Button
                                    onClick={() => handleGuestWaitingZone()}
                                    size="xs"
                                    styles={(theme) => ({
                                        root: {
                                            backgroundColor: "#F0F1F3",
                                            "&:hover": {
                                                backgroundColor: "#E3E4E6",
                                            },
                                        },
                                    })}
                                    fullWidth
                                    disabled={requestLoading}
                                >
                                    <span className="text-xs text-gray-500">
                                        {!isInWaitingZone
                                            ? "Inscription liste d'attente"
                                            : "Désinscription file d'attente"}
                                    </span>
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default CardCollection;
