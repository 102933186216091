import React from 'react';
import {ActionIcon, Button} from "@mantine/core";
import {AiOutlineEye, AiOutlineSearch, GiTicket} from "react-icons/all";
import {useNavigate} from "react-router-dom";
import storage from "../../auth/storage";

interface GoalNeedValidationItemProps {
    user: any;
}

const GoalNeedValidationItem = (props: GoalNeedValidationItemProps) => {
    const navigation = useNavigate();
    const {id}: any = storage.getUser();
    return (
        <div key={props.user.id} className="flex flex-wrap justify-between bg-white rounded mt-5 shadow gap-4 p-5">
            <div className="flex flex-col gap-2">
                <div className="flex-1 inline-flex items-center">
                    <span className="uppercase">OBJECTIFS</span> 
                    <span className="font-bold uppercase text-gray-600 text-sm ml-3 block">{props.user.role.slice(5)}</span>
                </div>
                <div className="flex-1">
                    <span className="font-bold uppercase text-gray-800 text-sm">{props.user.firstName} {props.user.name}</span>
                </div>
            </div>
            <div className="flex items-center">
                    <Button onClick={() => navigation('/auth/adherents/profil/goals/' + props.user.id, {replace: true})}
                            color="pink">
                        <AiOutlineEye size={18} className="text-white"/> <span
                        className="ml-3">Voir les objectifs</span>
                    </Button>
            </div>
        </div>
    );
};

export default GoalNeedValidationItem;
