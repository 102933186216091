import request from "./request";

const endpoint = "/goals";

const post = (goal: any) => {
    return request.post(endpoint, {
        level: goal.level,
        description: goal.description,
        type: goal.type,
        category: goal.category,
    });
};

const getByLevel = (level: string) => {
    return request.get(endpoint + "?level=" + level);
}

const getAll = () => {
    return request.get(endpoint);
}

const exportedApiUsers = {
    post, getByLevel, getAll
}

export default exportedApiUsers;