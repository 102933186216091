import request from "./request";

const endpoint = "/invoice";

const collection = () => {
    return request.get(endpoint);
};

const getYearlyInvoices = (selectedYear:any, page:number) => {
    return request.get(`${endpoint}/${selectedYear}/${page}`);
};

export default {
  collection,
  getYearlyInvoices,
}
