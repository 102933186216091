import {
  Button,
  TextInput,
  Modal,
  Checkbox,
  Select,
  PasswordInput,
  MultiSelect,
  NumberInput,
  Textarea,
} from '@mantine/core';
import React from 'react';
import {
  CIVILITIES
} from '../../constants/civility';
import {
  HABILITATION,
  HABILITATION_WITHOUT_TOUS,
} from '../../constants/habilitation';
import { 
  ROLES, 
  ROLE_WITHOUT_TOUS, 
  ROLES_WOUT_GUEST_TOUS,
  ROLES_WITH_EXPERT_TOUS,
} from '../../constants/roles';
import {DatePicker} from "@mantine/dates";

type Props = {
  opened: boolean;
  onClose: () => void;
  isSiteVisible: boolean;
  formSite?: any;
  handleSubmit?: (e: any) => void;
  type: string;
  subType?: string;
};

const ModalComponent = (props: Props) => {
  React.useEffect(() => {
    // @ts-ignore
    if (
      props.type == 'thematic' &&
      props.formSite.values.destined.includes('All')
    ) {
      // @ts-ignore
      props.formSite.setFieldValue('destined', ROLE_WITHOUT_TOUS);
    }
  }, [props.type == 'thematic' && props.formSite.values.destined]);

  React.useEffect(() => {
    // @ts-ignore
    if (
      props.type == 'thematic' &&
      props.formSite.values.habilitation.includes('All')
    ) {
      // @ts-ignore
      props.formSite.setFieldValue('habilitation', HABILITATION_WITHOUT_TOUS);
    }
  }, [props.type == 'thematic' && props.formSite.values.habilitation]);
  return (
    <Modal
      opened={props.opened}
      onClose={props.onClose}
      title={props.type + ' formulaire'}
    >
      {props.isSiteVisible && props.formSite ? (
        <form
          onSubmit={props.formSite.onSubmit(
            (values: any) => props.handleSubmit && props.handleSubmit(values)
          )}
        >
          {props.type == 'thematic' && (
            <>
              <TextInput
                required
                label="Nom"
                {...props.formSite.getInputProps('wording')}
              />
              <Textarea
                required
                label="Pré-requis"
                {...props.formSite.getInputProps('prerequisite')}
              />
              <Textarea
                required
                label="Mot du formateur"
                {...props.formSite.getInputProps('formerWords')}
              />
              <MultiSelect
                data={ROLES}
                onChange={(event: []) =>
                  props.formSite.setFieldValue('destined', event)
                }
                value={props.formSite.values.destined}
                placeholder="Public visé"
                className="mt-5"
              />

              <MultiSelect
                data={HABILITATION}
                value={props.formSite.values.habilitation}
                onChange={(event: []) =>
                  props.formSite.setFieldValue('habilitation', event)
                }
                placeholder="Habilitation"
                className="mt-5"
              />

              <NumberInput
                required
                label="Place"
                {...props.formSite.getInputProps('place')}
              />
              <NumberInput
                required
                label="Place Zoom"
                {...props.formSite.getInputProps('placeZoom')}
              />
              <TextInput
                required
                label="Thême"
                {...props.formSite.getInputProps('theme')}
              />
              <TextInput
                required
                label="Type"
                {...props.formSite.getInputProps('type')}
              />
            </>
          )}

          {props.type == 'user' && props.subType == 'create' && (
            <>
              <Select
                required
                label="Civilité"
                className="mt-1"
                data={CIVILITIES}
                value={props.formSite.values.civilite}
                onChange={(value: string) =>
                  props.formSite.setFieldValue('civilite', value)
                }
              />
              <TextInput
                required
                label="Nom"
                className="mt-1"
                {...props.formSite.getInputProps('lastName')}
              />
              <TextInput
                required
                label="Prénom"
                className="mt-1"
                {...props.formSite.getInputProps('firstName')}
              />
              <TextInput
                required
                label="Email"
                className="mt-1"
                {...props.formSite.getInputProps('email')}
              />
              <TextInput
                required
                label="Téléphone"
                className="mt-1"
                {...props.formSite.getInputProps('phoneNumber')}
              />
              <Select
                required
                label="Niveau"
                className="mt-1"
                data={ROLES_WITH_EXPERT_TOUS}
                value={props.formSite.values.role}
                onChange={(value: string) =>
                  props.formSite.setFieldValue('role', value)
                }
              />
              <PasswordInput
                required
                label="Mot de passe"
                className="mt-1"
                {...props.formSite.getInputProps('password')}
              />
              <PasswordInput
                required
                label="Confirmation"
                className="mt-1 mb-3"
                {...props.formSite.getInputProps('confirmPassword')}
              />
            </>
          )}

          {props.type == 'user' && props.subType == 'edit' && (
            <>
              <Select
                required
                label="Civilité"
                className="mt-1"
                data={CIVILITIES}
                value={props.formSite.values.civilite}
                onChange={(value: string) =>
                  props.formSite.setFieldValue('civilite', value)
                }
              />
              <TextInput
                required
                label="Nom"
                className="mt-1"
                {...props.formSite.getInputProps('lastName')}
              />
              <TextInput
                required
                label="Prénom"
                className="mt-1"
                {...props.formSite.getInputProps('firstName')}
              />
              <TextInput
                required
                label="Téléphone"
                className="mt-1 mb-3"
                {...props.formSite.getInputProps('phoneNumber')}
              />
            </>
          )}

          {props.type == 'user' && props.subType == 'delete' && (
            <>
              <div style={{textAlign: "center"}}>
                Etes-vous sûr de vouloir supprimer <br/>
                {props.formSite.values.civilite} {props.formSite.values.lastName} {props.formSite.values.firstName} ?
              </div>
            </>
          )}

          {props.type == 'tva' && (
            <>
            <NumberInput
              required
              label="TVA"
              {...props.formSite.getInputProps('valueTVA')}
            />
            <NumberInput
              required
              label="Chiffre d'affaire"
              {...props.formSite.getInputProps('ca')}
            />
            <NumberInput
              required
              label="CA status ( baissier = -1; stable = 0; haussier = 1 )"
              {...props.formSite.getInputProps('caugmente')}
            />
</>
          )}
          {props.type == 'site' && (
            <>
              <TextInput
                required
                label="Nom"
                {...props.formSite.getInputProps('name')}
              />
              <TextInput
                required
                label="Adresse"
                {...props.formSite.getInputProps('address')}
              />
              <TextInput
                required
                label="Code postal"
                {...props.formSite.getInputProps('postalCode')}
              />
              <TextInput
                required
                label="Ville"
                {...props.formSite.getInputProps('city')}
              />
              <TextInput
                required
                label="Google Map"
                {...props.formSite.getInputProps('gmap')}
              />
            </>
          )}

          {props.type == 'invoice' && (
              <>
                <TextInput
                    required
                    label="Utilisateur"
                    {...props.formSite.getInputProps('user')}
                />
                <TextInput
                    required
                    label="Description"
                    {...props.formSite.getInputProps('itemName')}
                />
                <TextInput
                    required
                    label="Montant sans € ( exemple : 4.5 pour 4.5 € )"
                    {...props.formSite.getInputProps('total')}
                />
                <TextInput
                    required
                    label="Id de formation"
                    {...props.formSite.getInputProps('formation')}
                />
                <TextInput
                    required
                    label="Id de l'atelier"
                    {...props.formSite.getInputProps('idWorkshop')}
                />
                <DatePicker
                    className="mt-5"
                    label="Date de création"
                    inputFormat="DD/MM/YYYY"
                    labelFormat="DD/MM/YYYY"
                    placeholder="Date de création"
                    {...props.formSite.getInputProps('createdAt')}
                    required
                    size="md"
                />
              </>
          )}
          <div className="flex ">
            <Button fullWidth className="mt-3 mx-auto" type="submit">
              Valider les modifications
            </Button>
          </div>
        </form>
      ) : (
        <div></div>
      )}
    </Modal>
  );
};

export default ModalComponent;
