export const formatItemName = (paymentMethod:string, itemName:string) => {
    if (!itemName) {
        return '';
    }

    switch(paymentMethod) {
        case 'PAYMENT_BY_CREDIT': return `Paiement par avoir de : ${itemName}`;
        case 'PAYMENT_BY_STRIPE': return `Paiement par Stripe de : ${itemName}`;
        case 'PAYMENT_BY_PAYPAL': return `Paiement par Paypal de : ${itemName}`;
        default: return `Paiement de : ${itemName}`;
    }
};
