import React from 'react';
import {Badge, Group, Anchor} from "@mantine/core";
import {Link} from "react-router-dom";
import {useNotifications} from "@mantine/notifications";
import news from "../../api/news";
import { URL2 } from "../../constants/URL";
import { format_d_MMMM_YYYY } from "../../utility/dateUtility";

const HomeNewsCard = ({data}: any) => {

    return (
        <Link to={"/auth/news/detail/" + data?.id}>
            <div className="w-full ">
                <div className="p-3 bg-white  bg-opacity-70 hover:bg-opacity-100 cursor-pointer border border-b-1 duration-150">
                    <div className="flex flex-row" style={{color: "#687687"}}>
                        <div className='flex flex-row justify-between flex-1'>
                            <div className="flex flex-col text-left">
                                <span className="uppercase" style={{fontSize: "11px"}}>{data?.category}</span>
                                <h4 style={{fontWeight: "bold", fontSize: "15px"}}>{data?.title.charAt(0).toUpperCase() + data?.title.toLowerCase().slice(1)}</h4>
                            </div>
                            <div className="flex text-right" style={{fontSize: "11px"}}>
                                <p>{format_d_MMMM_YYYY(data?.createdAt)}
                                    <br/>{data?.isVisio ? <p className="text-right text-sm">Visio</p> :
                                        <div className='flex-col'>
                                            <p className="text-right text-sm uppercase" style={{fontSize: "11px", color: "#687687"}}> {data?.site?.city} {data?.site && "("+data?.site?.postalCode.slice(0,2)+")"}</p>
                                        </div>
                                    }</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
};

export default HomeNewsCard;
