import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
    Table,
    Button,
    Anchor,
    Breadcrumbs,
    LoadingOverlay,
} from "@mantine/core";
import { AiOutlineDownload } from "react-icons/ai";
import transactions from "../../api/transactions";
import useApi from "../../hooks/useApi";
import Footer from "../../templates/Footer";
import users from "../../api/users";
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import settings from "../../api/settings";
import storage from "../../auth/storage";
import { format_ddMMYYYY } from "../../utility/dateUtility";

const FacturePDF = () => {
    const { id } = useParams();
    const user: any = storage.getUser();
    const { data: dataTransaction, request: requestTransaction } = useApi(
        transactions.getTransactionById,
    );
    const { data, request } = useApi(users.getUserById);
    const { data: dataTva, request: requestTva } = useApi(settings.getTva);

    useEffect(() => {
        requestTransaction(id);
        request(user.id);
    }, []);

    const styles = StyleSheet.create({
        page: { backgroundColor: "tomato" },
        title: { left: 0, fontWeight: "bold", color: "black" },
        section: { color: "black", margin: 30, left: 0 },
    });

    console.log(dataTransaction);

    return (
        <>
            <Document>
                <Page size="A4" style={styles.page}>
                    <View style={styles.section}>
                        <Text style={styles.title}>
                            {" "}
                            <h1 className="uppercase ">
                                FACTURE {dataTransaction.numFact}{" "}
                            </h1>
                        </Text>

                        <div className=" text-xl">
                            {format_ddMMYYYY(dataTransaction.createAt)}
                        </div>
                    </View>
                    <View>
                        <Text>
                            <div className="flex flex-row justify-between mt-3 ">
                                <div>
                                    <div>Association Forman</div>
                                    <div>2 bis rue Léon Blum</div>
                                    <div>78350 JOUY-EN-JOSAS1</div>
                                </div>
                                <div>
                                    <div>
                                        Adress Facturation client oui bonjour:
                                    </div>
                                    <div>
                                        {data?.userInformations?.societyName
                                            ? data?.userInformations
                                                  ?.societyName
                                            : data?.lastName +
                                              " " +
                                              data?.firstName}
                                    </div>
                                    <div>{data?.address}</div>
                                    <div>
                                        {data?.city} {data?.postalCode}
                                    </div>
                                </div>
                            </div>
                        </Text>
                    </View>
                    <View>
                        <Text>
                            <Table verticalSpacing="md" className="mt-3 mb-4">
                                <thead>
                                    <tr>
                                        <th>Quantité</th>
                                        <th>Description</th>
                                        <th>Prix Unitaire (euros HT)</th>
                                        <th>Montant (euros HT)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>5</td>
                                        <td>{"OUi"}</td>
                                        <td>{dataTransaction.total}</td>
                                        <td>{dataTransaction.total}</td>
                                    </tr>
                                </tbody>
                                <tfoot className="bg-gray-300">
                                    <tr>
                                        <th></th>
                                        <th></th>
                                        <th>TOTAL (euros HT) </th>
                                        <th>{dataTransaction.total}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <div className="flex justify-between">
                                <div className="text-xs flex flex-col">
                                    <div>
                                        TVA non applicable,article 261 du CGI
                                    </div>
                                    <div>
                                        indemnité forfaitaire pour frais de
                                        recouvrement de 40 euros
                                    </div>
                                </div>
                                <div className="text-xs font-bold">
                                    Facture Acquité le{" "}
                                    {format_ddMMYYYY(dataTransaction.createAt)}
                                </div>
                            </div>
                        </Text>
                    </View>
                </Page>
            </Document>
            {
                <PDFDownloadLink
                    document={<FacturePDF />}
                    fileName="somename.pdf"
                >
                    {({ blob, url, loading, error }) =>
                        loading ? (
                            "Loading document..."
                        ) : (
                            <button>Download</button>
                        )
                    }
                </PDFDownloadLink>
            }
        </>
    );
};

export default FacturePDF;
