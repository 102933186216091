import { useContext } from "react";
import AuthContext from "./context";
import authStorage from "../auth/storage";
import jwtDecode from "jwt-decode";
import {AuthToken} from "../entity/AuthToken";
import request from "../api/request";

// @ts-ignore
const useAuth = () => {
    // @ts-ignore
    /*
    const { user, setUser } = useContext(AuthContext) || {};
     */
    const logIn = async (authToken: AuthToken) => {
        authStorage.storeToken(authToken);
        // @ts-ignore
       /*  const userInfoResponse = await users.infos(dataToken['id']);
        // @ts-ignore
        if(userInfoResponse.ok){
            console.log(userInfoResponse.data);
            // @ts-ignore
            setUser(new User(
                // @ts-ignore
                userInfoResponse?.data?.id,
                // @ts-ignore
                userInfoResponse.data.lastName,
                // @ts-ignore
                userInfoResponse.data.firstName,
                // @ts-ignore
                userInfoResponse.data.civilite,
                // @ts-ignore
                userInfoResponse.data.email,
                // @ts-ignore
                userInfoResponse.data.phoneNumber,
                // @ts-ignore
                userInfoResponse.data.godFather
            ));
        }
        */

    };

    const logout = () => {
        authStorage.removeToken();
        authStorage.removeRefreshToken();
        delete request.headers.Authorization;
    };

    const setAxiosToken = (token: any) =>{
        request.headers["Authorization"] = "Bearer " + token;
    }

    const setup = () => {
        //Voir si on a un token
        const token = authStorage.getToken();
        //Si le token est encore valide
        if (token) {
            // @ts-ignore
            const { exp: expiration } = jwtDecode(token);
            if (expiration * 1000 > new Date().getTime()) {
                setAxiosToken(token);
            } else {
                logout();
            }
        } else {
            logout();
        }
    }

    const isAuthenticated = () => {
        //Voir si on a un token
        const token = authStorage.getToken()
        //Si le token est encore valide
        if (token) {
            // @ts-ignore
            const { exp: expiration } = jwtDecode(token);
            if (expiration * 1000 > new Date().getTime()) {
                return true;
            }
            return false;
        }
        return false;
    }

    return { /*user , */ logIn, logout, setup, isAuthenticated };
};

export default useAuth;