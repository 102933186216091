import request from "./request";

const endpoint = "/team_meatings";

const create = (teamMeeting: any) => {
    return request.post(endpoint, {
        thematic: 'teamMeeting',
        title: teamMeeting.title,
        place: teamMeeting.place,
        former: "/api/users/" + teamMeeting.former,
        description: teamMeeting.description,
        prerequisite: teamMeeting.prerequisite,
        videoConferenceLink : teamMeeting.videoConferenceLink,
        formerWords: teamMeeting.formerWords,
        startDate: teamMeeting.startDate,
        endDate: teamMeeting.endDate,
        visioPassword : teamMeeting.visioPassword,
        isVisio: teamMeeting.isVisio === "true",
        wantCopilot: teamMeeting.wantCopilot,
        breakTime: teamMeeting.breakTime,
        site: teamMeeting.isVisio === "true" ? null : "/api/sites/"+teamMeeting.site,
        destined: teamMeeting.destined,
        maxGuests: teamMeeting.maxGuests,
    });
};

const edit = (teamMeeting: any, id: number) => {
    return request.put(endpoint+"/"+id, {
        thematic: "Réunion d'équipe",
        title: teamMeeting.title,
        place: teamMeeting.place,
        description: teamMeeting.description,
        prerequisite: teamMeeting.prerequisite,
        formerWords: teamMeeting.formerWords,
        videoConferenceLink: teamMeeting.videoConferenceLink,
        startDate: teamMeeting.startDate,
        endDate: teamMeeting.endDate,
        visioPassword : teamMeeting.visioPassword,
        isVisio: teamMeeting.isVisio === "true",
        wantCopilot: teamMeeting.wantCopilot,
        breakTime: teamMeeting.breakTime,
        site: teamMeeting.isVisio === "true" ? null : "/api/sites/"+teamMeeting.site,
        destined: teamMeeting.destined,
        habilitation: teamMeeting.habilitation,
        maxGuests: teamMeeting.maxGuests,
    });
};
let date = new Date();
const x = 2; // go back 5 days!
date.setDate(date.getDate() - x);
const collection = ( startDate : string|null,habilitations: Array<any>, role: string) => {
    if(startDate != null){
        date = new Date(startDate);
    }
    return request.get(endpoint + "?destined="+role+"&startDate[after]="+""+date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+
        '' +
        "&habilitation[]=[]"
        +
        habilitations
            .map((habilitation) => '&habilitation[]=' + habilitation)
            .join(''));
};
const collectionAll = () => {
    return request.get(endpoint);
};

const item = (id: Number) => {
    return request.get(endpoint+"/"+id);
};

const publish = (id: Number) => {
    return request.post(endpoint+"/"+id+"/publish");
};

const registerGuest = (id: Number) => {
    return request.post(endpoint+"/"+id+"/join");
};

const unRegisterGuest = (id: Number) => {
    return request.post(endpoint+"/"+id+"/cancel");
}

const registerApprouvedCopilot = (emails: any, id: number) => {
    return request.post(endpoint + "/" + id + "/join/copilot/approuved", {
        emails: [...emails]
    });
};

const unRegisterApprouvedCopilot = (id: Number) => {
    return request.post(endpoint + "/" + id + "/cancel/copilot/approuved");
}

const registerCopilot = (id: Number) => {
    return request.post(endpoint + "/" + id + "/join-copilot");
};

const unRegisterCopilot = (id: Number) => {
    return request.post(endpoint + "/" + id + "/cancel-copilot");
};

const getteamMeetingsWithCopilotWanted = () => {
    return request.get("/teamMeetings/copilotWanted");
};

const unRegisterCopilotBy = (id: number, userId: number) => {
    return request.post(endpoint + "/" + id + "/cancel-copilot-by",{
        id: userId
    });
};

const registerApprouvedCopilotBy = (id: number, userId: number) => {
    return request.post(endpoint + "/" + id + "/join/copilot/approuved/by", {
        id: userId
    });
};

const deleteDateByteamMeetingId = (id: number) => {
    return request.put(endpoint + "/" + id + "/dates/delete");
};

const remove = (id: Number) => {
    return request.delete(endpoint + "/" + id);
};

const AddGuests = (emails: any, id: number) => {
    return request.post(endpoint+"/"+id+"/add/guests", {
        emails: [...emails]
    });
};


const collectionByFilter = (filter: string, value: any) => {
    return request.get(endpoint + "?" + filter + "=" + value+"&startDate[after]="+""+date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+"");
};


const collectionByFilterEdu = (filter: string, value: any) => {
    return request.get(endpoint + '?' + filter + '=' + value);
  };

const exportedApiteamMeetings = {
    create,collectionAll, collection, item, publish, registerGuest, unRegisterGuest, remove, edit, deleteDateByteamMeetingId,
    registerApprouvedCopilot, unRegisterApprouvedCopilot, registerCopilot, unRegisterCopilot, getteamMeetingsWithCopilotWanted,
    unRegisterCopilotBy, registerApprouvedCopilotBy, AddGuests, collectionByFilter, collectionByFilterEdu
}

export default exportedApiteamMeetings;