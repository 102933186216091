import request from "./request";

const endpoint = "/events";

const create = (event: any) => {
    return request.post(endpoint, {
        thematic: 'event',
        title: event.title,
        price: parseFloat(event.price),
        date: event.date,
        place: event.place,
        former: "/api/users/" + event.former,
        maxGuests: event.maxGuests,
        minGuests: event.minGuests,
        description: event.description,
        videoConferenceLink : event.videoConferenceLink,
        prerequisite: event.prerequisite,
        formerWords: event.formerWords,
        startDate: event.startDate,
        endDate: event.endDate,
        visioPassword : event.visioPassword,
        isVisio: event.isVisio === "true",
        breakTime: event.breakTime,
        externLink: event.externLink,
        site: (event.site == "" || event.place != "") ? null : "/api/sites/"+event.site,
        destined: event.destined,
        habilitation: event.habilitation,
    });
};

const edit = (event: any, id: number) => {
    return request.put(endpoint+"/"+id, {
        thematic: 'event',
        title: event.title,
        price: parseFloat(event.price),
        date: event.date,
        place: event.place,
        maxGuests: event.maxGuests,
        minGuests: event.minGuests,
        description: event.description,
        videoConferenceLink : event.videoConferenceLink,
        prerequisite: event.prerequisite,
        formerWords: event.formerWords,
        startDate: event.startDate,
        endDate: event.endDate,
        visioPassword : event.visioPassword,
        isVisio: event.isVisio === "true",
        breakTime: event.breakTime,
        externLink: event.externLink,
        site: (event.site == "" || event.place != "") ? null : "/api/sites/"+event.site,
        destined: event.destined,
        habilitation: event.habilitation,
    });
};
let date = new Date();
const x = 2; // go back 5 days!
date.setDate(date.getDate() - x);
const collection = (startDate : string|null) => {
    if(startDate != null){
        date = new Date(startDate);
      }
    return request.get(endpoint+ "?&startDate[after]="+""+date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+"");
};

const collectionAll = () => {
  return request.get(endpoint);
};


const item = (id: Number) => {
    return request.get(endpoint+"/"+id);
};

const publish = (id: Number) => {
    return request.post(endpoint+"/"+id+"/publish");
};

const registerGuest = (id: Number) => {
    return request.post(endpoint+"/"+id+"/join");
};

const unRegisterGuest = (id: Number) => {
    return request.post(endpoint+"/"+id+"/cancel");
}

const deleteDateByEventId = (id: number) => {
    return request.put(endpoint + "/" + id + "/dates/delete");
};

const registerInWaitingZone = (id: Number) => {
    return request.post(endpoint + "/" + id + "/join/waiting_zone");
};

const unRegisterInWaitingZone = (id: Number) => {
    return request.post(endpoint + "/" + id + "/cancel/waiting_zone");
}
const remove = (id: Number) => {
    return request.delete(endpoint + "/" + id + "/delete");
};
const collectionByFilter = (filter: string, value: any) => {
    return request.get(endpoint + "?" + filter + "=" + value+"&startDate[after]="+""+date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+"");
};

const collectionByFilterEdu = (filter: string, value: any) => {
    return request.get(endpoint + '?' + filter + '=' + value);
  };

const exportedApiEvents = {
    create,collectionAll
    ,collection, item, publish, registerGuest, unRegisterGuest, remove, collectionByFilter,
    edit, deleteDateByEventId, registerInWaitingZone ,unRegisterInWaitingZone,collectionByFilterEdu
}

export default exportedApiEvents;