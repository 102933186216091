import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {IoBarChartSharp} from "react-icons/all";
import {Button, Select} from "@mantine/core";
import useApi from "../../../hooks/useApi";
import users from "../../../api/users";
import storage from "../../../auth/storage";
import workshop from "../../../api/workshop";
import formation from "../../../api/formation";
import TeamMeating from "../../../api/teamMeeting";
import {CSVLink} from "react-csv";
import { format_d_MMMM_YYYY } from "../../../utility/dateUtility";

const Rapport = () => {
    const {id:UserId}: any = storage.getUser();
    const StorageId: any = storage.getUser();
    const [data, setData] = useState<any>([]);
    const { data: dataUser, loading, request } = useApi(users.infos);
    const {data:ligne, loading: loadingLigne, request: requestLigne} = useApi(users.getUserLigne);
    const {data:guests, request: requestGuests} = useApi(users.getGuests);
    const {data:guestsId, request: requestGuestsId} = useApi(users.getGuestsId);
    const [csvLigne, setCsvLigne] = useState<any[]>([]);
    const [csvLigne2, setCsvLigne2] = useState<any[]>([]);
    const [csvAteliers, setCsvAteliers] = useState<any[]>([]);
    const [csvFormations, setCsvFormations] = useState<any[]>([]);
    const [csvTM, setCsvTM] = useState<any[]>([]);
    const [csvAteliersGuest, setCsvAteliersGuest] = useState<any[]>([]);
    const [csvInfoGuest, setCsvInfoGuest] = useState<any[]>([]);
    const [csvFormationsGuest, setCsvFormationsGuest] = useState<any[]>([]);
    const [csvTMGuest, setCsvTMGuest] = useState<any[]>([]);
    const [usersData, setUsersData] = useState<any[]>([]);
    const [userSelected, setUserSelected] = useState<any>();
    const {
        data: dataWorkshop,
        loading: loadingWorkshop,
        request: requestWorkshop,
    } = useApi(workshop.collectionByFilterEdu);
    const {
        data: dataWorkshop2,
        loading: loadingWorkshop2,
        request: requestWorkshop2,
    } = useApi(workshop.collectionByFilterEdu);
    const {
        data: dataFormation,
        loading: loadingFormation,
        request: requestFormation,
    } = useApi(formation.collectionByFilterEdu);
    const {
        data: dataFormation2,
        loading: loadingFormation2,
        request: requestFormation2,
    } = useApi(formation.collectionByFilterEdu);
    const {
        data: dataTeamMeating,
        loading: loadingTeamMeating,
        request: requestTeamMeating,
    } = useApi(TeamMeating.collectionByFilter);
    const {
        data: dataTeamMeating3,
        loading: loadingTeamMeating3,
        request: requestTeamMeating3,
    } = useApi(TeamMeating.collectionByFilterEdu);

    const {
        data: dataWorkshopGuest,
        loading: loadingWorkshopGuest,
        request: requestWorkshopGuest,
    } = useApi(workshop.collectionByFilterEdu);
    const {
        data: dataWorkshopGuest2,
        loading: loadingWorkshopGuest2,
        request: requestWorkshopGuest2,
    } = useApi(workshop.collectionByFilterEdu);
    const {
        data: dataFormationGuest,
        loading: loadingFormationGuest,
        request: requestFormationGuest,
    } = useApi(formation.collectionByFilterEdu);
    const {
        data: dataFormationGuest2,
        loading: loadingFormationGuest2,
        request: requestFormationGuest2,
    } = useApi(formation.collectionByFilterEdu);
    const {
        data: dataTeamMeatingGuest,
        loading: loadingTeamMeatingGuest,
        request: requestTeamMeatingGuest,
    } = useApi(TeamMeating.collectionByFilter);
    const {
        data: dataTeamMeatingGuest3,
        loading: loadingTeamMeatingGuest3,
        request: requestTeamMeatingGuest3,
    } = useApi(TeamMeating.collectionByFilterEdu);

    useEffect(() => {
                requestLigne(StorageId.id);
        requestWorkshop("former", StorageId.id);
        requestWorkshop2("guests", StorageId.id);
        requestFormation("former", StorageId.id);
        requestFormation2("guests", StorageId.id);
        requestTeamMeating("former", StorageId.id);
        requestTeamMeating3("guests", StorageId.id);
        requestGuests().then(r => {
            const tab: any = [];
            for (let i = 0; i < r.data?.length; i++) {
                tab.push({label : r.data[i].lastName + " " + r.data[i].firstName, value : r.data[i].id});
            }
            setUsersData(tab)
        })
    }, []);




    useEffect(() => {
        if(userSelected){
            requestWorkshopGuest("former", userSelected);
            requestWorkshopGuest2("guests", userSelected);
            requestFormationGuest("former", userSelected);
            requestFormationGuest2("guests", userSelected);
            requestTeamMeatingGuest("former", userSelected);
            requestTeamMeatingGuest3("guests", userSelected);
            requestGuestsId(userSelected)
        }
    }, [userSelected]);


    useEffect(() => {
       setCsvInfoGuest(
           guestsId?.map((guest: any) => {
               return {
                   prenom: guest.firstName,
                   nom: guest.lastName,
                   email: guest.email,
                   telephone: "0"+guest.phoneNumber,
                   parrain_prenom: guest.godFather ? guest.godFather?.firstName  : "pas de parrain",
                   parrain_nom: guest.godFather ? guest.godFather?.lastName : "pas de parrain",
                   telephone_parrain: "0"+guest.godFather?.phoneNumber || "pas de parrain",
               };
           }) ?? [],
       )
    }, [guestsId]);





    useEffect(() => {
       setCsvAteliers(
           [...dataWorkshop2 , ...dataWorkshop].map((workshop: any) => {
               return {
                   thematique: workshop.thematic,
                   titre: workshop.title,
                   date: format_d_MMMM_YYYY(workshop?.startDate),
                   adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                   prix: workshop.price,
               };
           }),
       )
    }, [dataWorkshop,dataWorkshop2]);

    useEffect(() => {
        setCsvFormations(
            [...dataFormation2 , ...dataFormation].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataFormation,dataFormation2]);

    useEffect(() => {
        setCsvTM(
            [...dataTeamMeating , ...dataTeamMeating3].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataTeamMeating,dataTeamMeating3]);

    useEffect(() => {
        setCsvAteliersGuest(
            [...dataWorkshopGuest2 , ...dataWorkshopGuest].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataWorkshopGuest,dataWorkshopGuest2]);

    useEffect(() => {
        setCsvAteliersGuest(
            [...dataWorkshopGuest2 , ...dataWorkshopGuest].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataWorkshopGuest,dataWorkshopGuest2]);

    useEffect(() => {
        setCsvFormationsGuest(
            [...dataFormationGuest2 , ...dataFormationGuest].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataFormationGuest,dataFormationGuest2]);

    useEffect(() => {
        setCsvTMGuest(
            [...dataTeamMeatingGuest , ...dataTeamMeatingGuest3].map((workshop: any) => {
                return {
                    thematique: workshop.thematic,
                    titre: workshop.title,
                    date: format_d_MMMM_YYYY(workshop?.startDate),
                    adresse: workshop?.site?.address+ " "+ workshop?.site?.postalCode + " " + workshop?.site?.city,
                    prix: workshop.price,
                };
            }),
        )
    }, [dataTeamMeatingGuest,dataTeamMeatingGuest3]);

    useEffect(() => {
        if(ligne.ligneAmont && ligne.ligneAmont.length > 0){
            console.log("Amont",ligne.ligneAmont)


           let array = ligne.ligneAmont.map((guests: any) => {
                  return guests;
           })
           console.log("array",array)
            let users : any = [];
            array.map((guest: any) => {
                console.log("amont",guest)
                 guest.map((g: any)=> {
                    users.push({
                        prenom: g.firstName,
                        nom: g.lastName,
                        email: g.email,
                        tel: g.phoneNumber,
                    })
                })
            })
            setCsvLigne(users)
        }

        if(ligne.ligneAval && ligne.ligneAval.length > 0){
            console.log("Aval",ligne.ligneAval)

            setCsvLigne2(
                // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                ligne.ligneAval.map((guests: any) => {
                        return {
                            prenom: guests.firstName,
                            nom: guests.lastName,
                            email: guests.email,
                            tel: guests.phoneNumber,
                        }
                }) ?? []
            )

        }

            // @ts-ignore
            //setCsvLigne(array2)



    }, [ligne]);

    return (
        <>
            <h1 className="p-3 text-sm">Mes infos</h1>
        <div className="flex justify-between mt-5 bg-white p-3 shadow">
            <CSVLink
                separator=";"
                filename={"ligne.csv"}
                data={[...csvLigne , ...csvLigne2]}
            >
                Exporter la lignée
            </CSVLink>
            <CSVLink
                separator=";"
                filename={"formations.csv"}
                data={csvFormations}
            >
                Exporter formations
            </CSVLink>
            <CSVLink
                separator=";"
                filename={"ateliers.csv"}
                data={csvAteliers}
            >
                Exporter ateliers
            </CSVLink>
            <CSVLink
                separator=";"
                filename={"reunion.csv"}
                data={csvTM}
            >
                Exporter réunion d'équipe
            </CSVLink>
        </div>
            <h1 className="p-3 text-sm">Les informations filleuls</h1>
            <div className="flex justify-between mt-5 bg-white p-3 shadow">
                <Select
                    placeholder="Choix du filleul"
                    clearable
                    data={usersData}
                    onChange={(value: string) =>{
                        console.log(value)
                        setUserSelected(value)}
                    }
                />
                <CSVLink
                    separator=";"
                    filename={"formations.csv"}
                    data={csvInfoGuest}
                >
                    Exporter filleuls du filleul
                </CSVLink>
                <CSVLink
                    separator=";"
                    filename={"formations.csv"}
                    data={csvFormationsGuest}
                >
                    Exporter formations filleul
                </CSVLink>
                <CSVLink
                    separator=";"
                    filename={"ateliers.csv"}
                    data={csvAteliersGuest}
                >
                    Exporter ateliers filleul
                </CSVLink>
                <CSVLink
                    separator=";"
                    filename={"reunion.csv"}
                    data={csvTMGuest}
                >
                    Exporter réunion d'équipe filleul
                </CSVLink>
            </div>
        </>
);
};

export default Rapport;
