import React, { useEffect } from "react";
import { formatNumberWithSpaceSeparator } from '../../utility/numberFormatting';

interface Props {
    element: any;
    title: any;
}

type MonthMap = {
  [key: string]: string;
};

const monthMaps: MonthMap = {
    Jan: "Janvier",
    Feb: "Février",
    Mar: "Mars",
    Apr: "Avril",
    May: "Mai",
    Jun: "Juin",
    Jul: "Juillet",
    Aug: "Août",
    Sep: "Septembre",
    Oct: "Octobre",
    Nov: "Novembre",
    Dec: "Décembre",
};

const CreditHistoryRowTotal = (props: Props) => {
    return (
        <tr key={props.element.id}>
            <td key={`${props.element.id}-td`} style={{ width: 100, textAlign:'center', fontWeight:'bold' }}>
                {props.title}
            </td>
            {Object.keys(monthMaps).map((key, index) => {
                const amount = props.element[key];
                return (
                    <td key={`${props.element.id}-td-${index}`} style={{ width: 100, textAlign:'center', fontWeight:'bold' }}>
                        {amount ? formatNumberWithSpaceSeparator(amount) : "0"}
                    </td>
                );
            })}
            <td key={`${props.element.id}-td-action`} style={{ width: 100, textAlign:'center', fontWeight:'bold' }}>
                
            </td>
        </tr>
    );
};

export default CreditHistoryRowTotal;
