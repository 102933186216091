import request from "./request";

const endpoint = "/user_informations";

const post = (idUser: Number) => {
    return request.post(endpoint,{
        user: `/api/users/${idUser}`
    });
};

const get = (id: Number) => {
    return request.get(endpoint + `/${id}`);
};

const editUserInfos = (UI :any, id : Number) => {
    return request.put(endpoint+"/"+id,{
        habilitation: UI.habilitation,
        societyName: UI.societyName,
        societySiret: UI.societySiret,
        societyAddress: UI.societyAddress,
        country: UI.country,
        city: UI.city,
        postalCode: UI.postalCode

    });
};

const exportedApiUserInfos = {post, get, editUserInfos}

export default exportedApiUserInfos;