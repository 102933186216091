import React, {useEffect} from 'react';

const Cgu = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className="container mx-auto p-10">
                <h1 className="text-center font-bold">
                    CONDITIONS GENERALES D’UTILISATION
                    DES SERVICES ET DU SITE WWW.ASSO-FORMAN.FR
                </h1>
                <p>
                    L’Association FORMAN propose des services de formation et d’entraides aux gestionnaires de patrimoine. <br/>


                    A ce titre, elle édite et exploite le site Internet <a href="www.asso-forman.fr">www.asso-forman.fr</a>  permettant l’organisation d’ateliers ou de formations, la mise en relation de gestionnaires de patrimoine, le parrainage de gestionnaires de patrimoine et le développement de leurs réseaux professionnels. <br/>

                    Les présentes conditions générales définissent les termes et conditions d’utilisation des Services et du Site  <a href="www.asso-forman.fr">www.asso-forman.fr</a>.

                    L’Utilisateur reconnaît avoir pris connaissance et accepter les Conditions Générales sans réserve. Les Conditions Générales sont consultables gratuitement sur le Site <a
                    href="https://asso-forman.fr/cgu">https://asso-forman.fr/cgu</a> <br/>

                    <div className="my-5">
                    <h2 className="font-bold mb-3">
                        1. DEFINITIONS
                    </h2>


                   <p>
                       Les définitions figurant ci-dessous s’appliquent aux présentes Conditions Générales, y compris lorsqu’elles sont présentes dans son préambule :

                       • « Association FORMAN » désigne l’association FORMAN, association loi 1901 déclarée en préfecture sous le numéro W784001301, SIRET 84511823100016, dont le siège est situé 2 bis rue Léon Blum - 78350 JOUY-EN-JOSAS ;

                       • « Chemin de la réussite » désigne un module de formation défini sur le Site et permettant de débuter son parcours au sein de l’Association FORMAN et de valider son adhésion en qualité de membre ;

                       • « Conditions Générales » désigne le présent document ;

                       • « Contenu » désigne toute donnée, information ou document fourni par l’Association FORMAN ou mis en ligne sur le Site et/ou sur l’Espace de stockage par l’Association FORMAN ou par un Utilisateur ;

                       • « Données » désigne toutes données, informations ou éléments fournis par l’Utilisateur, le concernant ou non, à l’Association FORMAN par tout moyen, y compris le Site et l’Espace de stockage, dans le cadre de l’utilisation des Services ;

                       • « Filleul » désigne tout Utilisateur parrainé par à un Parrain (qui lui est directement rattaché) ;


                       • « Informations Confidentielles » désigne toutes les informations, données de quelque nature qu’elles soient (à savoir, de manière non limitative, les informations commerciales, financières, industrielles, techniques ou stratégiques), sous quelque forme qu’elles soient (à savoir, de manière non limitative, les concepts, les échantillons, les inventions, les graphiques, les descriptions, les spécifications, les méthodes, les modèles, les dessins, les idées, les plans d’affaires, les applications, les études, les analyses, les développements, les données marketing, les marques, les noms commerciaux), qu’elles soient ou non protégées par des droits de propriété intellectuelle ou industrielle, dont est titulaire ou qui sont détenues par l’Association FORMAN ou l’Utilisateur et qui sont révélées par l’Association FORMAN ou l’Utilisateur et/ou par un tiers dûment autorisé à cet effet, oralement ou par tout autre moyen (à savoir, de manière non limitative, par écrit, par voie magnétique ou électronique, par un moyen de télécommunications ou par un procédé informatique), au Partenaire.

                       Sont inclues dans cette définition les informations obtenues autrement, incidemment ou intentionnellement, par l’Association FORMAN ou l’Utilisateur par tout moyen (à savoir notamment, observations visuelles et visites de tout site ou bureau où l’une des parties exerce son activité) et en relation avec l’activité de l’autre partie, sans pour autant que l’information soit marquée « confidentielle », « propriétaire », « copyright » ou autres.

                       Il s’agit également de toutes les informations que l’Association FORMAN ou l’Utilisateur présente expressément à l’autre partie comme étant confidentielles et qui portent une mention explicite de son caractère confidentiel.

                       • « Lignée » désigne en lignée ascendante le Parrain de l’Utilisateur et l’ensemble des Parrains ascendant liés, et en ligné descendante l’ensemble des Filleuls de l’Utilisateur ainsi que l’ensemble des Filleuls descendants liés ;

                       • « Parrain initial » désigne l’Utilisateur qui, souhaitant développer son propre réseau, a invité un nouvel Utilisateur à intégrer l’Association FORMAN ;

                       • « Parrain » désigne l’Utilisateur actif de rang immédiatement supérieur à l’Utilisateur dans sa Lignée ascendante ;

                       • « Utilisateur » désigne toute personne physique utilisant les Services du Site ;

                       • « Services » désignent l’ensemble des services de formation et d’entraides aux gestionnaires de patrimoine proposés par l’Association FORMAN via le Site ainsi que les services de parrainages proposés par l’Association FORMAN ;

                       • « Site » désigne le site internet www.asso-forman.fr;

                       • « Espace de stockage » désigne l’espace de stockage accolé au Site, contenant différents supports appartenant à l’Association FORMAN et mis à la disposition des Utilisateurs dans les conditions visées aux présentes ;

                       • « Tiers » désigne toute personne autre que l’Association FORMAN ou l’Utilisateur.
                   </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            2. CONDITIONS D’ACCES AUX SERVICES
                        </h2>
                        <p>
                            L’accès aux Services est exclusivement ouvert aux personnes remplissant les conditions suivantes :

                            • être un membre de l’Association FORMAN à jour de ses cotisations,

                            • être une personne physique inscrite au « parcours découverte »,

                            • avoir conclu un mandat avec la société PRODEMIAL (RCS 810 852 129) en cours de validité ou s’engager à conclure un mandat avec ladite société dans un délai de 2 mois à compter de l’adhésion de l’Utilisateur à l’Association FORMAN.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            3. UTILISATION DES SERVICES

                        </h2>
                        <p>
                            3.1 Obligations générales

                            L’Association FORMAN concède à l’Utilisateur, le droit non-exclusif, temporaire et non cessible d’accès aux Services, au Site et à l’Espace de stockage dans les limites et conditions prévues aux Conditions Générales et notamment sous réserve des restrictions suivantes.

                            L’Utilisateur s’interdit :

                            • D’utiliser les Services, le Site et l’Espace de stockage dans un autre cadre que le cadre de son activité professionnelle et pour ses besoins propres, notamment à des fins commerciales ;

                            • D’utiliser les Services, le Site et l’Espace de stockage pour mettre à la disposition de Tiers, à quelque titre que ce soit, y compris à titre gratuit ou payant, tout ou partie du Contenu, les Services et/ou le Site ;

                            • Excepté dans le cas prévu à l’article L.122-6-1 du Code de la Propriété Intellectuelle, (i) de faire des copies de tout ou partie des Services et/ou du Site et/ou du Contenu, notamment des supports de formation, (ii) de vendre, concéder des sous-licence, distribuer ou plus généralement donner accès à quelque titre que ce soit, aux Services et/ou au Site à tout Tiers ;


                            • De modifier, arranger, procéder à de la rétro ingénierie, décompiler, désassembler, traduire, adapter, reproduire, distribuer, diffuser, transférer toute information, tout logiciel, tout produit, tout Service et/ou tout Contenu, ainsi que de créer des œuvres et/ou des services dérivés ou concurrents à partir des Services, du Site, de l’Espace de stockage et/ou du Contenu ;

                            • De permettre à un Tiers d'utiliser les Services, le Site et/ou l’Espace de stockage, à quelque titre que ce soit et par quelque moyen que ce soit,

                            • D’utiliser le Site, les Services, l’Espace de stockage, le Contenu et/ou tout document ou information contenu sur le Site ou l’Espace de stockage à des fins illicites ou immorales ou contraires à l’utilisation prévue du Site, des Services, du Contenu, de l’Espace de stockage et/ou à leur finalité ;

                            • De mener des activités visant à accéder à des éléments ou fonctionnalités du Site, de l’Espace de stockage ainsi qu’à des Services dont l’utilisation n’a pas été autorisée par l’Association FORMAN ;

                            • De modifier, fausser, bloquer, alourdir de manière anormale, perturber, ralentir, et/ou entraver le fonctionnement normal de tout ou partie du Site et/ou de l’Espace de stockage ou leur accessibilité aux autres Utilisateurs ou tenter de faire tout ce qui précède ;

                            • D’intégrer, de transmettre ou de propager tout virus, cheval de Troie, ver, bombe, fichier altéré, code malveillant et/ou dispositif de destruction similaire ou donnée corrompue ou tout élément de nature à perturber ou endommager le Site et/ou les Services et/ou le Contenu et/ou l’Espace de stockage, au sein du Site et/ou de l’Espace de stockage ou dans le cadre de l’utilisation du Site et/ou de l’Espace de stockage et/ou des Services, et/ou organiser, participer ou être impliqué de quelque façon que ce soit dans une attaque contre les serveurs de l’Association FORMAN et/ou des prestataires et partenaires de l’Association FORMAN ;

                            • D’utiliser le compte d’un autre Utilisateur du Site, de se faire passer pour une autre personne ou s’attribuer une fausse qualité dans les rapports avec toute personne physique ou morale dans le cadre du Site et/ou des Services ;

                            • De collecter, d’intercepter par tout moyen ou plus généralement d’utiliser des informations relatives aux autres Utilisateurs à quelques fins que ce soit, à l’exception de celles spécifiquement autorisées par le Site, et notamment de harceler les autres Utilisateurs du Site, de leur envoyer des messages non-sollicités à des fins de prospection commerciale, d’utiliser les Services à des fins d'enquête, de concours, de ventes pyramidales ou opérations similaires, ou d'envoi d’emailing, de spams ou de toutes publicités ou éléments promotionnels non sollicités, et ce à des fins commerciales ou non ;


                            • D’accéder, d’utiliser, de télécharger à partir des Services ou autrement reproduire ou fournir à quiconque (à titre gratuit ou onéreux) tout annuaire des Utilisateurs ou toute autre information relative aux Utilisateurs ou à l'utilisation des Services et/ou du Site et/ou à l’Espace de stockage.

                            D’une manière générale, l’Utilisateur s’engage à ne jamais rien faire ou tenter de faire qui puisse nuire, directement ou indirectement, à l’Association FORMAN.

                            En cas de non-respect des présentes obligations, l’Association FORMAN pourra suspendre ou clôturer le compte de l’Utilisateur, sans préavis, conformément à l’article 4.4 des Conditions Générales.
                            L’Association FORMAN pourra utiliser l’adresse IP de l’Utilisateur pour l’identifier dans le but de faire respecter les Conditions Générales.

                            3.2 Obligations spécifiques au Contenu généré par l’Utilisateur

                            L’Utilisateur s’engage et garantie de ne publier sur le Site et/ou l’Espace de stockage que du Contenu, notamment des éléments (documents, informations, données, etc.), de toute nature, pour lesquels il bénéficie des droits ou autorisations nécessaires en vue de leur publication et mise à disposition de l’Association FORMAN et des autres Utilisateurs, qui ne contreviennent en aucune manière aux droits qui seraient détenus par des Tiers (notamment droits de propriété intellectuelle, droit à la vie privée et/ou droit à l’image) et qui ne sont pas contraires à une ou plusieurs dispositions légales ou règlementaires.

                            En cas de réclamations et/ou d’actions qui seraient exercées par un Tiers ou un ayant-droit relatif à l’utilisation par l’Association FORMAN et/ou les Utilisateurs de tout Contenu généré par un Utilisateur, ledit Utilisateur s’engage à garantir et à relever indemne l’Association FORMAN, et notamment à dédommager intégralement l’Association FORMAN des dépens, des frais, des indemnités et plus généralement des sommes qui pourraient être mises à sa charge à ce titre.

                            L’Association FORMAN sera alors en droit de suspendre ou de supprimer le compte de l’Utilisateur et de supprimer le Contenu litigieux, outre tous dommages et intérêts qui pourraient être réclamés par l’Association FORMAN.

                            En outre, l’Utilisateur qui génère et publie un Contenu sur le Site et/ou l’Espace de stockage s’engage à céder les droits de propriété intellectuelle qu’il détient sur ledit Contenu dans les conditions de l’article 10.2 des Conditions Générales.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            4. COMPTE DE CONNECTION
                        </h2>
                        <p>
                            4.1 Création du compte

                            L’Utilisation du Site, de l’Espace de stockage et des Services est conditionnée par la création d’un compte sur le Site.

                            Pour créer ce compte, lors de sa première connexion au Site, l’Utilisateur doit entrer l’identifiant de connexion ainsi que le mot de passe provisoire qui lui ont été transmis par l’Association FORMAN.

                            Il doit alors créer un nouveau mot de passe secret, personnel et confidentiel.

                            L’Utilisateur est responsable de la confidentialité de son identifiant et de son mot de passe ainsi que des restrictions d’accès à son ordinateur et de toutes les activités effectuées depuis son compte ou avec son identifiant et mot de passe. Par conséquent, l’Utilisateur sera seul responsable en cas d'utilisation du Site et/ou de l’Espace de stockage et/ou des Services à partir de son nom d'utilisateur, y compris non conforme aux dispositions des Conditions Générales.

                            L’Utilisateur doit, dès qu'il en a connaissance, et sans délai, notifier à l’Association FORMAN par email à l’adresse communication@asso-forman.fr, toute utilisation non autorisée de son identifiant et changer tout mot de passe concerné.

                            L’Utilisateur s'engage à ne pas créer plusieurs comptes et à accéder au Site, à l’Espace de stockage et aux Services et à les utiliser d'une manière sécurisée et conforme aux normes standards.

                            4.2 Informations fournies sur le compte

                            L’Utilisateur doit remplir les informations le concernant sollicitées sur le compte.

                            L’Utilisateur garantie l’exactitude des informations qu’il fournit à l’Association FORMAN, en ce compris sa photographie. Ainsi, l’Utilisateur s’engage à ne jamais transmettre à l’Association FORMAN, directement ou via le Site, de fausses informations, des informations erronées ou des informations appartenant à des tiers.

                            En particulier, s’agissant de l’évolution de son profil, l’Utilisateur s’engage à transmettre à l’Association FORMAN des informations sincères et à jour.

                            Il s’engage à mettre à jour les informations qu’il fournit à l’Association FORMAN et à signaler à l’Association FORMAN tout changement significatif concernant ses informations, notamment l’arrêt ou la suspension de son activité professionnelle, la perte du mandat PRODEMIAL.

                            En cas d’inactivité du compte de l’Utilisateur pendant une durée 6 mois (notamment en cas d’absence de commande de formation), l’Association FORMAN se réserve le droit de solliciter auprès de PRODEMIAL la confirmation de la validité du mandat de l’Utilisateur – ce que l’Utilisateur autorise expressément.

                            Par ailleurs, l’Utilisateur autorise expressément l’Association FORMAN à faire apparaître sur le Site et donc rendre accessible à tous les Utilisateurs :

                            • ses nom, prénom, adresse email, téléphone et photographie, au sein de l’annuaire des Utilisateurs,

                            • La ligne ascendante et descendante de parrainage de l’Utilisateur,

                            • La participation de l’Utilisateur ou l’animation par l’Utilisateur des sessions de formation, des ateliers ou des évènements organisés par l’Association FORMAN – ainsi le Parrain a notamment une visibilité sur les formations suivies ou animées par ces Filleuls.

                            L’Utilisateur peut à tout moment accéder à ses informations et les modifier dans son espace personnel.

                            4.3 Fermeture du Compte à l’initiative de l’Utilisateur

                            L’Utilisateur peut clôturer définitivement son compte à tout moment, de plein droit et sans formalité de la façon suivante : envoi d’un email à l’adresse communication@asso-forman.fr.

                            La fermeture du compte de l’Utilisateur entrera en vigueur dans un délai raisonnable après la réception de la demande par l’Association FORMAN.

                            4.4 Fermeture ou suspension du compte de l’Utilisateur à l’initiative de l’Association FORMAN

                            En cas de non-respect de tout ou partie des Conditions Générales, l’Association FORMAN aura la faculté de suspendre ou clôturer le compte d’un Utilisateur (ou limiter sa capacité à utiliser tout ou partie du Site, de l’Espace de stockage et des Services), à tout moment, de plein droit, TRENTE (30) jours après l'envoi d’une mise en demeure adressée par lettre recommandée avec demande d’accusé de réception à l’Utilisateur et restée infructueuse, et ce sans préjudice des dommages et intérêts pouvant être dus à l’Association FORMAN.

                            En cas d’atteinte grave, répétée et/ou délibérée de l’Utilisateur, ou absence de mandat en cours de validité avec la société PRODEMIAL, l’Association FORMAN pourra faire l’économie de la mise en demeure préalable susvisée et mettre un terme immédiat et de plein droit au compte de l’Utilisateur ainsi qu’à son adhésion à l’Association FORMAN.

                            Cette clôture ou suspension sera notifiée à l’Utilisateur par email sur les coordonnées renseignés par lui au sein de son espace personnel.

                            4.5 Conséquence de la suspension ou de la fermeture du compte de l’Utilisateur

                            Toute suspension ou fermeture du compte d’un Utilisateur entraîne la suppression temporaire ou définitive de l’autorisation d’utilisation et d’accès au Site et/ou à l’Espace de stockage et/ou à tout ou partie des Services. L’Utilisateur devra donc stopper toute exploitation, à quelque titre que ce soit et sous quelque forme que ce soit, de toute ou partie du Site, de l’Espace de stockage et des Services, notamment tels que notifié lors de l’information de la suspension ou de la fermeture de son compte.

                            En cas de suppression définitive du compte de l’Utilisateur, les informations communiquées par l’Utilisateur à l’Association FORMAN lors de l’ouverture ou de la mise à jour dudit compte, tous les documents et autres éléments stockés sur le Site dans son espace personnel relatifs à l’Utilisateur seront supprimés du Site.

                            Toutefois, l’Association FORMAN se réserve le droit de conserver les documents, informations et autres éléments relatifs à l’Utilisateur et/ou publiés par l’Utilisateur à des fins d’archivage, notamment pour répondre à ses obligations légales ou règlementaires ou conformément à sa politique de données personnelles.

                            L’Association FORMAN conservera également les Contenus publiés par l’Utilisateur conformément à l’article 10.2 des Conditions Générales.

                            En cas de fermeture du compte de l’Utilisateur :

                            • à initiative de l’Utilisateur lui-même : dans l’hypothèse où l’Association Forman serait, au moment de la clôture du compte par l’Utilisateur, créditrice de sommes d’argent au bénéfice de  l’Utilisateur relatives à des Services achetés mais non consommés, ces sommes lui seront remboursées, sous réserve que la clôture du compte de l’Utilisateur intervienne plus de sept (7) jours avant la date de réalisation du Service considéré. Ces remboursements interviendront dans un délai de quinze (15) jours à compter de la clôture du compte.

                            • par l’Association FORMAN pour non-respect des Conditions Générales par l’Utilisateur : aucun remboursement, ni total ni partiel, des sommes déjà versées par l’Utilisateur pour l’achat de tout Service. En outre, il est formellement et strictement interdit à l’Utilisateur d’ouvrir un nouveau compte, directement ou indirectement, sans l'autorisation préalable et écrite de l’Association FORMAN.

                            En tout état de cause, les cotisations à l’Association FORMAN ne seront pas remboursées, même partiellement, quelle que soit la date de clôture du compte de l’Utilisateur et le motif.

                            Nonobstant toute fermeture du compte de l’Utilisateur et la résiliation de ces relations contractuelles avec l’Association, il est expressément convenu que les articles 10 (« Propriété Intellectuelle »), 13 (« Confidentialité »), 14 (« Responsabilité »), 15 (« Règlement des litiges ») et 16 (« Notification ») continueront de s'appliquer. Tous les autres droits concédés par les présentes cesseront de s'appliquer à la date d'effet de la résiliation.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            5. OBJET DES SERVICES
                        </h2>
                        <p>
                            Les Services sont destinés à permettre à l’Utilisateur d’avoir accès à des formations en relation avec son activité de gestionnaire ou future gestionnaire de patrimoine, à bénéficier d’un accompagnement de type parrainage et d’augmenter son réseau professionnel.

                            L’Association FORMAN n'est ni partie, ni bénéficiaire, ni garant de la bonne gestion de l’activité professionnelle de l’Utilisateur.
                        </p>
                    </div>


                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            6. COMMANDE DES SERVICES
                        </h2>
                        <p>
                            Les offres de Services proposées sur le Site sont valables, à défaut d’indication de durée particulière, tant que les offres sont proposées sur le Site.

                            Avant de payer la commande d’un Service, l’Utilisateur a la possibilité de vérifier le détail du prix de sa commande et de revenir aux pages précédentes pour, le cas échéant, corriger le contenu de sa commande.

                            Pour effectuer une commande d’un ou plusieurs Services, l’Utilisateur doit :
                            • créer un compte dans les conditions précisées à l’article 4.1 ci-avant,
                            • sélectionner le ou les Service(s) souhaité(s) sur le Site,
                            • confirmer sa commande en cliquant sur « Valider »,
                            • effectuer le paiement en cliquant sur « Payer », dans les conditions prévues à l’article 9 des Conditions Générales.

                            Un email de confirmation de paiement sera adressé à l’Utilisateur.

                            Toute Commande vaut accord exprès de la part de l’Utilisateur du commencement de l'exécution des Services par l’Association FORMAN.

                            Toute modification de commande sera soumise à l’accord préalable de l’Association FORMAN.

                            Le Service annulé par l’Utilisateur au moins sept (7) jours avant la date de réalisation dudit Service (date de la formation ou de l’évènement) fera l’objet d’un avoir au bénéfice de l’Utilisateur valable UN (1) an.

                            Le Service annulé par l’Utilisateur moins de sept (7) jours avant la date de réalisation dudit Service (date de la formation ou de l’évènement) ne pourra faire l’objet d’aucun avoir ni remboursement et les sommes payées par l’Utilisateur seront définitivement acquises à l’Association FORMAN.

                            L’Utilisateur n’étant pas un consommateur au sens du Code de la consommation, il ne bénéficie pas du droit de rétractation.
                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            7. DISPONIBILITE DES SERVICES
                        </h2>
                        <p>
                            L’accès aux Services via le Site est possible 24h sur 24 et 7 jours sur 7, sous réserve de la survenance d’un cas de force majeure, des pannes éventuelles ou de toute opération de maintenance nécessaire au bon fonctionnement du Site et/ou des Services.

                            L’Association FORMAN fera naturellement ses meilleurs efforts pour assurer un fonctionnement optimum du Site et des Services.

                            Le Site et les Services sont accessibles et fonctionnent avec les dernières versions des navigateurs suivants : Google Chrome, Mozilla Firefox, Safari et Internet Explorer et des systèmes d’exploitation suivants : Microsoft Windows (Windows Vista, 7, 8), Android, Mac OS et Apple iOS, Linux. Il est possible que le Site et les Services soient difficilement accessibles en cas d’utilisation des anciennes versions de ces navigateurs et systèmes d’exploitation.

                            L’Utilisateur reconnaît et accepte que la disponibilité du Site et des Services est notamment liée à la disponibilité des services de connexion au réseau Internet et aux autres fonctions du réseau Internet et que les capacités de transmission du réseau Internet, de par leur complexité de fonctionnement, peuvent être inégales et incertaines.

                            En outre, l’accès au Site et/ou aux Services peut être occasionnellement suspendu ou limité pour permettre leur modification, maintenance ou l’ajout d’une nouvelle fonctionnalité ou d’un nouveau Service. L’Association FORMAN s’efforcera de limiter la fréquence et la durée de ses suspensions ou limitations.

                            En tout état de cause, l’Association FORMAN ne pourra être tenue responsable des conséquences directes ou indirectes des éventuelles anomalies, dysfonctionnements, suspension ou limitation du Site et/ou des Services, quelle que soient leurs causes.

                        </p>
                    </div>


                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            8. MODIFICATION DES SERVICES
                        </h2>
                        <p>

                            L’Utilisateur reconnaît et accepte que l’Association FORMAN puisse modifier ou suspendre à tout moment et sans motif à fournir, les Services, notamment leur typologie, leur contenu, leur date et leur prix.

                            Aucune modification ou suspension de l’un quelconque des Services ne donnera lieu à une indemnisation de l’Utilisateur ou remboursement de quelque nature que ce soit.

                        </p>
                    </div>


                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            9. PRIX – PAIEMENT DES PRIX DES SERVICES
                        </h2>
                        <p>

                            9.1 Prix

                            Le prix des Services est disponible sur le Site. Ils sont mentionnés en Euros et toutes taxes comprises.

                            Le prix du Service est celui en vigueur au jour de la commande passée par l’Utilisateur.

                            Le montant total dû par l’Utilisateur et son détail sont indiqués sur son espace personnel au sein du Site.

                            L’Association FORMAN pourra modifier le barème des prix à sa convenance.

                            Ces prix sont exclusifs du montant des cotisations qui sont dues en sus par les membres de l’Association FORMAN.
                            9.2 Paiement des prix des Services - Facturation

                            9.2.1 L’Utilisateur qui commande un Service doit régler ledit Service en ligne via le Site.

                            Sauf mention expresse contraire sur le Site, le prix d’un Service commandé est exigible en totalité dès la confirmation de la commande par l’Utilisateur.

                            Ce paiement est assuré par carte bancaire en ligne via un prestataire Tiers, organisme de paiement, ou Paypal et est sécurisé. Ainsi, l’Association FORMAN n’a pas accès aux données confidentielles de l’Utilisateur, à savoir le numéro de carte bancaire à 16 chiffres, la date d'expiration et le code CVX, lesquelles sont directement transmises cryptées sur le serveur du prestataire.

                            A ce titre, l’Association FORMAN recommande aux Utilisateurs de prendre connaissance des conditions générales d'utilisation du service de paiement proposé par le prestataire Tiers qui figurent ……..  ou Paypal ………… afin qu’il les accepte et s’engage à les respecter.

                            9.2.2 Pour chaque commande, l’Utilisateur recevra, dans son espace, la confirmation de commande et une facture électronique.

                            Il est conseillé à l’Utilisateur d’imprimer et/ou d’archiver sur un support fiable et durable toute facture, à titre de preuve.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            10. PROPRIETE INTELLECTUELLE
                        </h2>
                        <p>

                            10.1 Propriété intellectuelle de l’Association FORMAN

                            L’Association FORMAN est titulaire de droits de propriété intellectuelle (droit d’auteur, marque, dessins et modèles) ou bénéficie des licences d’exploitation requises sur le Site, l’Espace de stockage, le Contenu ainsi que le cas échéant, sur les Services.

                            L’utilisation par l’Utilisateur du Site, de l’Espace de stockage, du Contenu et/ou des Services ne lui confère aucun droit de propriété sur ceux-ci qui restent la propriété exclusive de l’Association FORMAN et, le cas échéant, des Tiers lui ayant concédé les licences y afférentes.

                            L’Utilisateur ne dispose, en vertu des Conditions Générales, que d’une autorisation personnelle, non-exclusive, non cessible d’utilisation du Site, de l’Espace de stockage, du Contenu et des Services à des fins strictement personnelles, ce durant son adhésion à l’Association FORMAN ou durant le temps du parcours découverte.

                            A l’issue de ses relations avec l’Association FORMAN, l’Utilisateur perd tout droit d’utilisation du Site, de l’Espace de stockage, du Contenu et des Services et devra en stopper toute exploitation, à quelque titre que ce soit et sous quelque forme que ce soit.



                            10.2 Cession des droits de propriété intellectuelle par l’Utilisateur

                            Dans l’hypothèse où l’Utilisateur serait l’auteur, en tout ou partie, d’un Contenu (notamment mais non exclusivement texte, support de formation ou d’atelier) qu’il communique ou fourni à l’Association FORMAN, à titre gratuit ou à titre payant, l’Utilisateur reconnait que l’intégralité des droits patrimoniaux de propriété intellectuelle attachés audit Contenu seront cédés, de façon exclusive, gratuite et définitive à l’Association FORMAN, pour toute la durée légale de protection des droits d’auteur y attachés.

                            Les droits ainsi cédés comprennent le droit de reproduction, de représentation, de diffusion, d’adaptation, de modification, de traduction et de commercialisation, sans limitation de nombre, de tout ou partie des contenus établi par l’Utilisateur, en vue de leur utilisation par l’Association FORMAN dans le cadre de ses activités.

                            L’Utilisateur garantie que les Contenus ainsi communiqués ou fournis à l’Association FORMAN pourront être librement exploités par l’Association FORMAN dans les conditions visées ci-avant et que lesdits Contenus ne constituent pas la contrefaçon d’une œuvre préexistante et ne violent, en aucune façon, les droits détenus ou exploités par un tiers.

                            En cas de réclamation ou d’action à ce titre, l’Utilisateur garantira l’Association FORMAN dans les conditions de l’article 14.2 des Conditions Générales.

                            Enfin, l’Utilisateur s’interdit d’exploiter, pour son compte ou pour le compte de tiers, directement ou indirectement, à quelque titre que ce soit, et sous quelque forme que ce soit, tout partie des Contenus communiqués ou fournis à l’Association FORMAN ainsi que de créer, faire créer et/ou exploiter, pour son compte ou pour le compte de tiers, directement ou indirectement, à quelque titre que ce soit, toute création présentant un risque de confusion avec les Contenus communiqués ou fournis à l’Association FORMAN.

                        </p>
                    </div>



                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            11. GENEALOGIE ET QUALIFICATION DE NIVEAU DE L’UTILISATEUR
                        </h2>
                        <p>

                            L’Utilisateur est qualifié au sein de l’Association FORMAN selon un niveau.

                            Ces niveaux ont pour objectifs d’accompagner l’Utilisateur dans le développement de son activité et de lui permettre de suivre des formations adaptées à ses compétences et à son activité.

                            L’Utilisateur peut suivre en temps réel sur le Site son statut, son évolution et ses objectifs atteints ou à atteindre, ainsi que les variations de sa Lignée, ascendante et descendante.

                            11.1 Qualification de niveau de l’Utilisateur

                            Les niveaux sont les suivants (par ordre hiérarchique du plus bas au plus élevé) : NOMAN, ONEMAN, NEOMAN, BEMAN, ADMAN, MAN, DXMAN, DEVMAN et XMAN.

                            A chaque niveau, correspondent des objectifs pour atteindre le niveau ou se maintenir. Ces objectifs sont détaillés sur le Site, notamment au sein de l’espace personnel de l’Utilisateur.


                            11.2 Evolution de la qualification

                            L’Utilisateur remplit sur son espace personnel sur le Site les objectifs qu’il a atteints au fur et à mesure.

                            A chaque passage d’objectif par l’Utilisateur, un email est adressé à son Parrain afin que celui-ci valide l’objectif considéré (le Parrain étant le seul en capacité de valider chacun des objectifs de l’Utilisateur parrainé).

                            Lorsque tous les objectifs correspondants à un niveau sont remplis par l’Utilisateur, un appel au vote est automatiquement provoqué afin d’évaluer l’évolution du niveau de l’Utilisateur.

                            Les personnes participant au vote sont :
                            • Le premier Utilisateur bénéficiant du statut de DevMan de la Lignée ascendante de l’Utilisateur,
                            • Le premier Utilisateur bénéficiant du statut de XMan de la Lignée ascendante de l’Utilisateur.

                            Les options offertes aux votants sont :
                            • maintien de niveau,
                            • passage au niveau supérieur,
                            • rétrogradation au niveau inférieur,
                            • bascule en OneMan en cas d’inactivité prolongée de l’Utilisateur.

                            La décision est prise à l’unanimité des votes exprimés.

                            Les votes peuvent être en suspens si l’ensemble des votants n’ont pas pu s’exprimer. En cas de blocage, le vote final reviendra au premier Utilisateur bénéficiant du statut de XMan de la Lignée ascendante de l’Utilisateur. ……………… .

                            En tout état de cause, le statut de l’Utilisateur est réévalué tous les TROIS (3) mois à compter de la date de son inscription à l’Association FORMAN, ou du dernier vote effectué concernant sa qualification, selon les mêmes conditions que visées ci-avant.

                            Les résultats de chacun des votes est adressé à l’Utilisateur par email. Les éventuels nouveaux objectifs seront alors mis à jour dans son espace personnel sur le Site.


                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            12. PARRAINAGE
                        </h2>
                        <p>

                            12.1 Obligations générales du Parrain

                            Le Parrain s’engage à respecter l’indépendance et l’autonomie de chacun et notamment de ses Filleuls.

                            Le Parrain s’engage à ne jamais rien faire qui puisse nuire, directement ou indirectement, à l’un de ses Filleuls.

                            Toute action du Parrain destinée à développer sa Lignée sera réalisée en conformité avec la réglementation en vigueur ainsi que dans le respect des dispositions des Conditions Générales.

                            12.2 Obligations de vote du Parrain

                            Le Parrain s’engage à procéder aux votes qui lui sont soumis au bénéfice de sa lignée descendante, afin de ne pas bloquer voire nuire à l’évolution de ses Filleuls ou plus généralement des Utilisateurs au sein de l’Association FORMAN.

                            Les appels au vote sont effectués dans son espace personnel sur le Site.

                            Les votes des Parrains doivent être fondés sur l’appréciation objective de l’Utilisateur concerné par le vote et sans partialité ou discrimination.


                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            13. CONFIDENTIALITE
                        </h2>
                        <p>

                            L’Association FORMAN et l’Utilisateur s’engagent réciproquement, à compter de la réception de chacune des Informations Confidentielles qui auront été portées à sa connaissance par l’autre partie dans le cadre de leur relation contractuelle, à traiter avec le plus grand secret les Informations Confidentielles qu'elle aura reçues ou dont elle aura connaissance à l'occasion de l'exécution des Services et notamment à :

                            • ne pas faire usage, directement ou indirectement, des Informations Confidentielles pour des besoins autres que l’exécution des Services et reconnaît que celles-ci restent la propriété de la partie ou de celui de qui elle les tient ;

                            • ne pas révéler à des personnes, communiquer ou divulguer, directement ou indirectement, d'Information Confidentielle à des tiers, ainsi qu'à ne faire aucune copie des Informations Confidentielles sans l'accord préalable écrit de l'autre partie ;

                            • ne communiquer des Informations Confidentielles qu'à ceux de ses employés ou conseils à qui il est strictement nécessaire de les connaître. De plus, chaque Partie ne transmettra à ses employés que les Informations Confidentielles qui leur sont nécessaires ;

                            • prendre toutes les mesures nécessaires pour protéger les Informations Confidentielles avec un degré de précautions au moins égal à celui utilisé pour protéger ses propres informations secrètes et stratégiques ;

                            • prendre toutes les mesures nécessaires et utiles pour faire respecter les dispositions de la présente clause par les membres de son personnel, intervenants extérieurs et, le cas échéant, sous-traitants, qui seraient amenés à connaître ces Informations Confidentielles.

                            En outre, l’Association FORMAN et l’Utilisateur s’interdisent réciproquement :

                            • d’agréger et/ou d’anonymiser et/ou d’effectuer toute autre opération de traitement sur les Informations Confidentielles aux fins notamment de :
                            ◦ les intégrer dans une ou plusieurs bases de données appartenant à la partie réceptrice ;
                            ◦ les utiliser pour développer un outil ou enrichir un modèle numérique ;

                            • d’exploiter pour son compte ou pour le compte d’un tiers les Informations Confidentielles, sans son autorisation préalable et écrite.

                            Il est convenu que l’Association FORMAN comme l’Utilisateur seront relevés de leurs présents engagements vis-à-vis de toute Information Confidentielle dont ils pourront apporter la preuve que :

                            • elles lui ont été communiquées par un tiers, de manière licite et en l'absence de toute violation des présentes ou d’un engagement de confidentialité par le tiers concerné ;

                            • elles lui étaient déjà connues avant leur communication par l’autre partie – la preuve pouvant en être rapportée ;

                            • elles sont tombées dans le domaine public, sans violation des présentes ;

                            • elles ont été expressément et par écrit qualifiées par l’autre partie comme étant non-confidentielle ;

                            • la loi ou la réglementation applicable oblige à les divulguer.

                            Toute Information Confidentielle ayant fait l'objet de copie papier ou électronique reste la propriété de la partie l'ayant divulguée, elle peut en demander la restitution à tout moment.

                            Si une autorité administrative ou judiciaire requiert d'une partie la divulgation d’Informations Confidentielles, au titre d’une obligation incontestable d’ordre légal ou dans le cadre d’une procédure judiciaire, la partie soumise à cette obligation s’engage à en informer l’autre partie par écrit dans les plus brefs délais et à collaborer avec cette dernière afin de limiter la communication des Informations Confidentielles à ce qui est strictement requis de par la loi.

                            Cette obligation de confidentialité entre en vigueur à la date d’acceptation des Conditions Générales par l’Utilisateur et restera en vigueur pendant une durée de CINQ (5) ans à compter de la fin des relations contractuelles entre l’Utilisateur et l’Association FORMAN.

                        </p>
                    </div>


                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            14. RESPONSABILITE
                        </h2>
                        <p>

                            14.1 Responsabilité de l’Association FORMAN

                            Dans le cadre de l’exécution des Services comme de la mise à disposition du Site et de l’Espace de stockage, l’Association FORMAN est soumise à une obligation de moyens. Dans ce cadre, l’Association FORMAN s’engage à apporter tout le soin et la diligence nécessaires à la fourniture des Services de qualité et sécurisé, conformément aux règles de l’art en application des précautions raisonnables pour éviter les erreurs et omissions et aux caractéristiques et modalités prévues dans les présentes Conditions Générales. Ainsi, l’Association FORMAN n’accorde aucune autre garantie expresse ou implicite à l’égard des Contenus et informations éditées sur le Site, sur l’Espace de stockage ou des informations transmises.

                            Toutefois, l’Association FORMAN ne donne aucune garantie sur l’utilisation des Services par l’Utilisateur qui demeure pleinement responsable de son activité professionnelle et de ces agissements à ce titre.

                            En outre, avant de procéder à une commande de Services, il appartient à l’Utilisateur de vérifier que les Services considérés sont adaptés à ses besoins et à sa situation et en particulier, aux exigences légales et règlementaires auxquelles son activité est soumise – l’Association FORMAN rejetant toute responsabilité à ce titre.

                            En tout état de cause, l’Association FORMAN ne sera en aucun cas tenue responsable :
                            • des dommages indirects ou immatériels de toute nature, subis par l’Utilisateur liés à ou découlant de l'exécution ou de l'utilisation du Site et/ou de l’Espace de stockage et/ou des Services tels qu’une perte de profits, bénéfices, contrat, économies espérées, données, clientèle ou dépenses supplémentaires,
                            • des pertes qui n’ont pas été causées par une faute résultant de son fait.

                            L’Association FORMAN sera néanmoins responsable de tout préjudice direct subi par l’Utilisateur et l’indemnisera à ce titre.

                            14.2 Responsabilité de l’Utilisateur

                            L’Utilisateur s'engage à utiliser le Site, l’Espace de stockage et les Services et à assurer son rôle de Parrain avec la plus grande diligence, sans aucune intention de nuire, directement ou indirectement, à l’Association FORMAN et/ou aux autres Utilisateurs et conformément aux Conditions Générales.

                            L’Utilisateur sera responsable de tout préjudice causé par son fait ou par son abstention à l’Association FORMAN et/ou à un autre Utilisateur, du fait notamment de l’utilisation du Site, de l’Espace de stockage ou des Services ou l’exécution de ses obligations de Parrain, et indemnisera la victime pour tout dommage direct de quelque nature qu’il soit.

                            En conséquence, l’Utilisateur s’engage à réparer tout préjudice subi par l’Association FORMAN et/ou à un autre Utilisateur, découlant de ses fautes et/ou manquements à ses obligations telles de découlant des Conditions Générales.

                        </p>
                    </div>




                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            15. DROIT APPLICABLE – REGLEMENT DES LITIGES ET JURIDICTION
                        </h2>
                        <p>

                            Les Conditions Générales sont soumises au droit français.

                            Toute réclamation concernant les Services et/ou le Site et/ou l’Espace de stockage doit être adressée par email à l’adresse suivante : <a
                            href="commnication@asso-forman.fr">commnication@asso-forman.fr</a>.

                            A défaut de règlement amiable dans un délai de QUARANTE CINQ (45) jours, tous litiges ou réclamation liés aux Services et/ou au Site et/ou à l’Espace de stockage seront soumis à la compétence exclusive des tribunaux du ressort de la Cour d’Appel de Paris, nonobstant pluralité de défendeurs ou appel en garantie.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            16. NOTIFICATION
                        </h2>
                        <p>

                            Toute notification ou communication au titre des relations entre l’Association FORMAN et l’Utilisateur sera valablement adressée :

                            • par lettre recommandée avec avis de réception :
                            ◦ pour l’Utilisateur : à l’adresse renseignée au sein de son espace personnel sur le Site,
                            ◦ pour l’Association FORMAN : à son siège social

                            • ou par e-mail :
                            ◦ pour l’Utilisateur : à l’adresse renseignée au sein de son espace personnel sur le Site,
                            ◦ pour l’Association FORMAN : à l’adresse commnication@asso-forman.fr.

                        </p>
                    </div>

                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            17. FORCE MAJEURE
                        </h2>
                        <p>

                            En cas d’impossibilité pour l’Association FORMAN ou l’Utilisateur d’exécuter tout ou partie des obligations lui incombant au titre des Conditions Générales en raison d’un cas de force majeure, du fait d'un tiers ou d’une interdiction administrative ou législative, l’exécution desdites obligations sera suspendue le temps de l’évènement considéré et la responsabilité de la partie concernée ne sera pas engagée.
                            Il est convenu que sera considérée comme un cas de force majeure un événement à caractère imprévisible, insurmontable et irrésistible, résultant d’un fait extérieur à la maîtrise des parties, lequel consiste notamment – mais non exclusivement – en un événement ou une série d’événements de nature climatique, pandémique, bactériologique, militaire, politique ou diplomatique.
                            La partie qui invoque une cause d’exonération devra informer immédiatement l’autre Partie par téléphone ou mail, confirmé immédiatement par lettre recommandée avec accusé réception de l’intervention de cet événement en lui donnant toute précision possible sur l’évènement invoqué permettant à l’autre partie d’apprécier l’incidence sur l’exécution des obligations de la partie invoquant l’événement signalé.

                            La partie concernée devra faire ses meilleurs efforts pour essayer de surmonter dans les meilleurs délais les effets de cet évènement.

                            Pendant la durée de la suspension, l’autre partie sera également dégagée du respect de ses propres obligations.

                        </p>
                    </div>


                    <div className="my-5">
                        <h2 className="font-bold mb-3">
                            18. SUPPORT TECHNIQUE - CONTACT
                        </h2>
                        <p>
                            En cas de difficulté technique de l’Utilisateur pour l’utilisation du Site ou des Services, il peut contacter l’Association FORMAN à l’adresse suivante : communication@asso-forman.fr
                        </p>
                    </div>
                </p>
            </div>
        </>
    );
};

// @ts-ignore
export default Cgu;
