import React, { useEffect, Fragment, useState } from 'react';
import useApi from '../../../hooks/useApi';
import workshop from '../../../api/workshop';
import { Button, LoadingOverlay, Select } from '@mantine/core';
import event from '../../../api/event';
import { Link } from 'react-router-dom';
import { FaBell, FiArrowDownRight, FiArrowUpRight, FiArrowRight } from 'react-icons/all';
import news from '../../../api/news';
import HomeCardNew from '../../../components/News/HomeCardNew';
import storage from '../../../auth/storage';
import HasRoles from '../../../components/Security/hasRoles';
import {
  ROLE_GUEST,
  ROLE_NEOMAN,
  ROLE_NEOMAN_MORE,
  ROLE_XMAN,
} from '../../../constants/roles';
import Card from '../../../components/home/connected/Card';
import users from '../../../api/users';
import WorkshopEventSection from '../../../components/home/connected/WorkshopEventSection';
import MapHome from '../../../assets/images/MapHome.png';
import NotificationCard from '../../../components/notifications/NotificationCard';
import Footer from '../../Footer';
import { FaRegCheckCircle } from 'react-icons/fa';
import { IoMdCloseCircleOutline } from 'react-icons/io';
import Request from '../../../api/request';
import SettingsExported from '../../../api/settings';
import AppMap from "../../../components/Map/AppMap";

const HomeConnectedUser = () => {
  const { data, request } = useApi(workshop.collection);
  const { data : settings, request : requestSettings } = useApi(SettingsExported.getTva);
  const [workshopData, setWorkshopData] = useState<any[]>([]);
  const {
    data: userData,
    loading: userLoading,
    request: userRequest,
  } = useApi(users.infos);
  const [loading, setLoading] = useState(false);
  const { request: requestJD } = useApi(workshop.getWorkshopByThematic);
  const [JDs, setJDs] = useState<any[]>([]);
  const [JdsVisio, setJdsVisio] = useState<any[]>([]);
  const [userDataInfo, setUserDataInfo] = useState<any>(null);
  const [demarrage, setDemarrage] = useState<any[]>([]);
  const [threeJD, setThreeJD] = useState<any[]>([]);
  const [nbAdhérents,setNbAdhérents] = useState(Number)
  const {
    data: dataEvent,
    loading: loadingEvent,
    request: requestEvent,
  } = useApi(event.collection);
  const {
    data: dataNews,
    loading: loadingNews,
    request: requestNews,
  } = useApi(news.collection);
  const {
    data: dataUser,
    loading: loadingUser,
    request: requestUser,
  } = useApi(users.infos);
  const { role, id }: any = storage.getUser();
  const [search, setSearch] = useState<string>('');
  const [guestTitle, setGuestTitle] = useState(String);

  useEffect(() => {
    requestSettings(1);
    userRequest(id).then((responseUser) => {
      const habilitation = responseUser.data?.userInformations ? responseUser.data?.userInformations.habilitation : [];
      setLoading(true);
      setUserDataInfo(responseUser.data);
      if (role[0] == 'ROLE_GUEST') {
        setGuestTitle(
          responseUser.data.isJdEnded
            ? responseUser.data.isDemarrageEnded
              ? responseUser.data.is3JREnded
                ? 'Payez votre adhésion'
                : 'Inscrivez-vous aux 3 jours de la réussite'
              : 'Inscrivez-vous a un atelier démarrage'
            : 'Inscrivez-vous a une journée découverte'
        );
        request(role, habilitation).then((d) => {
          setWorkshopData(
            d?.data?.filter(function (w: any) {
              return responseUser?.data?.isJdEnded
                ? w.title == '3 Jours de la Réussite' ||  w.title == 'Atelier Démarrage' || w.title == 'Découverte métier visio' ||
                  w.title == 'Découverte métier matinée' ||
                  w.title == 'Découverte métier après-midi'
                : w.title == 'Découverte métier visio' ||
                    w.title == 'Découverte métier matinée' ||
                    w.title == 'Découverte métier après-midi';
            })
          );
        });
        //request(role).then((d) => {setWorkshopData(d?.data)})
      }
      requestUser(id).then((r) => {
        console.log(r)
        if (r.data.roles[0] != 'ROLE_GUEST') {
          request(role, habilitation, r.data.endCursus).then((dataRes) => {
            setWorkshopData(dataRes.data);
          });
          requestEvent( r.data.endCursus);
          requestNews(role);
          //@ts-ignore
          Request.get('adherents').then((res:any) => {
            setNbAdhérents(res.data.adherents)
          })
        }
      })
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <>
      <LoadingOverlay visible={loading} />
      <LoadingOverlay visible={loadingEvent} />
      <LoadingOverlay visible={userLoading} />
      <HasRoles role={ROLE_NEOMAN_MORE} checkUser>
        <div
          className="flex flex-col-reverse md:flex-row justify-between w-full px-10 pt-10 pb-28 md:pb-10 "
          style={{ maxWidth: '100vw' }}
        >
          <div className="flex flex-col md:flex-row w-full xl:w-5/6">
            <WorkshopEventSection
              adviseThematics={
                userDataInfo !== null &&
                userDataInfo?.adviseThematic !== null &&
                userDataInfo?.adviseThematic
              }
              dataWokshop={workshopData}
              dataEvent={dataEvent}
            />
            <div className="w-full md:ml-10 xl:mr-10">
              <div className="flex flex-col lg:flex-row justify-between lg:items-center mb-3">
                <h2 className="text-s21 uppercase text-left mb-1 mr-3 lg:mb-0">
                  Actualités
                </h2>
              </div>
              <div
                style={{ boxShadow: '0px 1px 6px #8E8E8E59' }}
              >
                <div
                >
                  {dataNews.length ? (
                    dataNews
                      .slice(0, 10)
                      .map((news: any) => <HomeCardNew data={news} />)
                  ) : (
                    <div className="w-full flex items-center p-4">
                      <div className="w-full text-left text-sm font-medium text-gray-600">
                        Aucune Actualité Trouvé
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex md:flex-col lg:flex-row justify-between my-5">
                <Link to="/auth/news/create">
                  <Button
                    color="blue"
                    size="xs"
                    className="text-ten mb-2 lg:mb-0"
                    style={{ background: '#256094' }}
                  >
                    Créer une actualité
                  </Button>
                </Link>
                <Link to="/auth/news">
                  <Button color="gray" size="xs" className="text-ten">
                    Voir toutes les actualités
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-around md:justify-start md:flex-col lg:w-1/6 md:mr-4 block md:hidden xl:block">
            <div
              className="bg-white shadow text-center py-2 rounded w-5/12 lg:w-full"
              style={{ color: '#2F2F2F' }}
            >
              <span
                className="block sm:w-2/3 pt-2 px-1 mx-auto"
                style={{ fontSize: '13px' }}
              >
                Volume d'affaires mensuel
              </span>
              <div style={{ color: settings?.cAugmente == -1 ? '#BF0000' : settings?.cAugmente == 1 ? '#94C903' : '#333' }}>
              {settings?.cAugmente == -1 && (
                <FiArrowDownRight className="mx-auto mb-1 " size="50" />
                )}
                {settings?.cAugmente == 1 && (
                <FiArrowUpRight className="mx-auto" size="50" />
                )}
                {settings?.cAugmente == 0 && (
                <FiArrowRight className="mx-auto mb-1 " size="50" />
                )}
                <span style={{ fontSize: '13px' }}>{settings?.cA} %</span>
              </div>
              <span
                className="text-xs text-gray-500"
                style={{ fontSize: '10px' }}
              >
                Sur le dernier mois
              </span>
            </div>

            <div
              className="bg-white shadow md:my-6 text-center py-2 rounded ml-2 sm:m-0 w-5/12 lg:w-full"
              style={{ color: '#2F2F2F' }}
            >
              <span
                className="font-bold block w-full"
                style={{ fontSize: '20px' }}
              >
                {}
              </span>
              <span className="block w-full" style={{ fontSize: '13px' }}>
                Adhérents
              </span>
              <div style={{ color: '#94C903' }}>
                <FiArrowUpRight className="mx-auto" size="50" />
                <span style={{ fontSize: '13px' }}>{nbAdhérents + 141}</span>
              </div>
              <span
                className="text-xs text-gray-500"
                style={{ fontSize: '10px' }}
              >
                Sur les 6 derniers mois
              </span>
            </div>
            <AppMap />
          </div>
        </div>
      </HasRoles>
      {role == "ROLE_GUEST" && (
        <div className="flex flex-col sm:flex-row place-content-evenly gap-5 m-6 mb-20 md:mt-12">
          <div className="w-full" style={{ maxWidth: 600 }}>
            <div className="bg-gray-100 shadow p-3 mb-8">
              <h2 className=" text-xl font-bold text-left mb-5">
                Bienvenue
              </h2>
              <p className="text-sm text-gray-700 ">
                Vous êtes invité par un membre de l’association à découvrir notre opportunité professionnelle.
              </p>
            </div>
            {userData && userData?.isJdEnded && (

                <div className="bg-gray-100 shadow p-3 justify-center">
              <h2 className=" text-xl font-bold text-left mb-5">
                Schéma parcours découverte / cursus d'intégration
              </h2>
              <div className={`flex flex-col gap-5 mx-auto justify-center items-center text-white`}>
                <div
                  className={`p-3 ${
                    userDataInfo?.isJdEnded ? 'bg-green-500' : 'bg-gray-500'
                  } flex-col w-2/5 rounded text-center justify-center`}
                  style={{ minWidth: 195 }}
                >
                  {userDataInfo?.isJdEnded ? (
                    <FaRegCheckCircle size={18} className="float-right" />
                  ) : (
                    <IoMdCloseCircleOutline size={20} className="float-right" />
                  )}
                  <p className="">Journée découverte (JD)</p>
                </div>
                <div
                  className={`p-3 ${
                    userDataInfo?.isDemarrageEnded
                      ? 'bg-green-500'
                      : 'bg-gray-500'
                  } flex-col w-2/5 rounded text-center`}
                  style={{ minWidth: 195 }}
                >
                  {userDataInfo?.isDemarrageEnded ? (
                    <FaRegCheckCircle size={18} className="float-right" />
                  ) : (
                    <IoMdCloseCircleOutline size={20} className="float-right" />
                  )}
                  <p className="mb-2">Atelier démarrage (AD)</p>
                </div>
                <div
                  className={`p-3 ${
                    userDataInfo?.is3JREnded ? 'bg-green-500' : 'bg-gray-500'
                  } flex-col w-2/5 rounded text-center`}
                  style={{ minWidth: 195 }}
                >
                  {userDataInfo?.is3JREnded ? (
                    <FaRegCheckCircle size={18} className="float-right" />
                  ) : (
                    <IoMdCloseCircleOutline size={20} className="float-right" />
                  )}
                  <p className="mb-2">3 Jours réussite (3JR)</p>
                </div>
                {userDataInfo?.is3JREnded && (
                <div className="flex items-center">
                  <Link to="/auth/notifications/adhesion">
                    <Button
                      onClick={() => console.log('test')}
                      color="gray"
                      size="sm"
                      className="text-sm mb-2 lg:mb-0"
                    >
                      Adhèrer
                    </Button>
                  </Link>
                </div>)}
              </div>

            </div>
            )}
          </div>
          <div className="lg:mr-10 md:mr-10">
            <Card
              datas={workshopData.slice(0, 6)}
              title={guestTitle}
              isGuest={true}
              type="atelier"
              link="/auth/workshop"
            />
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default HomeConnectedUser;
