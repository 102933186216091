import React, {useEffect, Fragment, useState} from "react";
import useApi from "../../../hooks/useApi";
import request1 from "../../../api/request";
import {
    Button,
    Table,
    LoadingOverlay,
    Select,
    ActionIcon,
    Modal,
    TextInput,
    Badge,
} from "@mantine/core";
import {useForm} from "@mantine/hooks";

import {Dropzone} from "@mantine/dropzone";

import {Link, useNavigate} from "react-router-dom";
import {FiArrowDownRight, FiArrowUpRight} from "react-icons/all";
import requestClient from "../../../api/request";
import thematic from "../../../api/thematic";
import site from "../../../api/site";
import HomeCardNew from "../../../components/News/HomeCardNew";
import storage from "../../../auth/storage";
import HasRoles from "../../../components/Security/hasRoles";
import {ROLE_GUEST, ROLE_NEOMAN_MORE} from "../../../constants/roles";
import Card from "../../../components/home/connected/Card";
import users from "../../../api/users";
import invoice from "../../../api/invoice";
import creditHistory from "../../../api/creditHistory";
import WorkshopEventSection from "../../../components/home/connected/WorkshopEventSection";
import MapHome from "../../../assets/images/MapHome.png";

import Footer from "../../Footer";
import {getCivility} from "../../../constants/civility";
import {FaRegCheckCircle} from "react-icons/fa";
import {FiEdit, FiDelete} from "react-icons/fi";
import ListingComponent from "../../../components/Admin/ListingComponent";
import ReadOnlyListingComponent from "../../../components/Admin/ReadOnlyListingComponent";
import ModalComponent from "../../../components/Admin/ModalComponents";
import SettingsExported from "../../../api/settings";
import {AuthToken} from "../../../entity/AuthToken";
import useAuth from "../../../auth/useAuth";
import authStorage from "../../../auth/storage";
import UserRow from "../../../components/Admin/UserRow";
import InvoiceRow from "../../../components/Admin/InvoiceRow";
import CreditHistoryRow from "../../../components/Admin/CreditHistoryRow";
import CreditHistoryRowTotal from "../../../components/Admin/CreditHistoryRowTotal";
import {CSVLink} from "react-csv";
import {RiFolderSharedLine} from "react-icons/ri";
import workshop from "../../../api/workshop";
import WorkshopRow from "../../../components/Admin/WorkshopRow";
import formation from "../../../api/formation";
import FormationRow from "../../../components/Admin/FormationRow";
import teamMeeting from "../../../api/teamMeeting";
import event from "../../../api/event";
import EventRow from "../../../components/Admin/EventRow";
import TeamMeatingRow from "../../../components/Admin/TeamMeatingRow";
import transactions from "../../../api/transactions";
import { REACT_APP_API_BASE_URL } from "../../../env";
import { format_d_MMMM_YYYY } from "../../../utility/dateUtility";
import isExpertAccountantF from "../../../utility/isExpertAccountant";

type Props = {};

const AdminScreen = (props: Props) => {
    const [selectedYear, setSelectedYear] = useState<number>((new Date()).getFullYear());
    const [page, setPage] = useState<number>(1);
    const [yearInvoiceList, setYearInvoiceList] = useState<number>((new Date()).getFullYear());
    const [pageInvoiceList, setPageInvoiceList] = useState<number>(1);
    const {data, loading, request} = useApi(thematic.all);
    const [isSiteVisible, setIsSiteVisible] = useState(false);
    const [isThematicVisible, setIsThematicVisible] = useState(false);
    const [isOtherVisible, setIsOtherVisible] = useState(false);
    const [isUsersVisible, setIsUsersVisible] = useState(false);
    const [isAccountingModuleVisible, setIsAccountingModuleVisible] = useState(false);
    const [isWorkshopVisible, setIsWorkshopVisible] = useState(false);
    const [isFormationVisible, setIsFormationVisible] = useState(false);
    const [isEventVisible, setIsEventVisible] = useState(false);
    const [isTeamVisible, setIsTeamVisible] = useState(false);
    const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);
    const [isInvoiceListVisible, setIsInvoiceListVisible] = useState(false);
    const [userIdInvoice, setUserIdInvoice] = useState<string>('');
    const [isInvoiceAllVisible, setIsInvoiceAllVisible] = useState(false);
    const [file, setFile] = useState([]);
    const [isCreate, setIsCreate] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [opened, setOpened] = useState(false);
    const [tva, setTva] = useState<any>();
    const [ca1, setCa] = useState<any>();
    const [usersData, setUsersData] = useState([]);
    const [search, setSearch] = useState<string>('');

    const [usersDataInvoice, setUsersDataInvoice] = useState([]);
    const [searchInvoice, setSearchInvoice] = useState<string>('');


    const [caugmente, setCaugmente] = useState<any>();
    const {data: dataFormation, loading : loadingFormation, request : requestFormation } = useApi(formation.collectionAll);
    const {data: dataEvent, loading : loadingEvent, request : requestEvent } = useApi(event.collectionAll);
    const {data: dataTeam, loading : loadingTeam, request : requestTeam } = useApi(teamMeeting.collectionAll);
    const {data: dataInvoice, loading : loadingInvoice, request : requestInvoice } = useApi(transactions.getAllByUser);
    const { request: requestUser } = useApi(users.getUserByLastName);
    const { request: requestUserId } = useApi(users.getUserById);
    const { request: requestUserEmail } = useApi(users.getUserByEmail);
    const [SubusersData, setSubUsersData] = useState([]);
    const [SubusersDataInvoice, setSubUsersDataInvoice] = useState([]);
    const [searchStart, setSearchStart] = useState<string>('');
    const [userUniqueRow, setUserUniqueRow] = useState();
    const [userId, setUserId] = useState<string>('');
    // @ts-ignore
    const { request: transactionGetDetails } = useApi(transactions.getDetailsAdmin);

    // @ts-ignore
    const [transactionDetails, settransactionDetails] = useState({});

    const handleYearChange = (year: string|null) => {
        const parsedYear = year ? parseInt(year, 10) : 0;
        setSelectedYear(parsedYear);
    };
    const handlePageChange = (page: number) => {
        setPage(page);
    };

    const handleYearInvoiceListChange = (year: string|null) => {
        const parsedYear = year ? parseInt(year, 10) : 0;
        setYearInvoiceList(parsedYear);
    };
    const handlePageInvoiceListChange = (page: number) => {
        setPageInvoiceList(page);
    };

    const {
        data: dataSite,
        loading: loadingSite,
        request: requestSite,
    } = useApi(site.collectionAdmin);

    const searchUserEmail = () => {
        requestUser(search).then((res) => {
            const tab: any = [];
            for (let i = 0; i < res.data.length; i++) {
                tab.push({label : res.data[i].lastName + " " + res.data[i].firstName, value : res.data[i].id});
            }
            setSubUsersData(tab);
            setUsersData(tab);
        });
    };

    const searchUserInvoiceEmail = () => {
        requestUser(searchInvoice).then((res) => {
            const tab: any = [];
            for (let i = 0; i < res.data.length; i++) {
                tab.push({label : res.data[i].lastName + " " + res.data[i].firstName, value : res.data[i].id});
            }
            setSubUsersDataInvoice(tab);
            setUsersDataInvoice(tab);
        });
    };


    useEffect(() => {
        if (search?.length > 2) {
            if (search?.length == 3) {
                setSearchStart(search);
                searchUserEmail();
            }
        }
    }, [search]);

    useEffect(() => {
        if (searchInvoice?.length > 2) {
            if (searchInvoice?.length == 3) {
                searchUserInvoiceEmail();
            }
        }
    }, [searchInvoice]);

    useEffect(() => {
        console.log(userId);
        if (userId) {
            requestUserId(userId).then((res) => {
                setUserUniqueRow(res.data);
            });
        }
    }, [userId]);

    const [
        dataUsersPage, setDataUsersPage
    ] = useState<Number>(1);

    const {
        data: dataUsers,
        loading: loadingUsers,
        request: requestUsers,
    } = useApi(users.collectionPaginate);

    useEffect(() => {
        if (dataUsersPage) {
            requestUsers(dataUsersPage);
        }
    }, [dataUsersPage]);

    const {
        data: dataInvoiceList,
        loading: loadingInvoiceList,
        request: requestInvoiceList,
    } = useApi(invoice.getYearlyInvoices);
    // @ts-ignore
    useEffect(() => {
        // @ts-ignore
        let ids = dataInvoiceList.invoices || []
        // @ts-ignore
        ids = ids.map(v => v.id);
        // @ts-ignore
        if(ids.length){
            // @ts-ignore
            transactionGetDetails({transactionIds:ids}).then((r) => {
                console.log({r})
                // @ts-ignore
                settransactionDetails(r?.data ? r.data : {})
            });
        }
    }, [dataInvoiceList]);

    const [
        dataWorkshopsPage, setDataWorkshopsPage
    ] = useState<Number>(1);

    const {
        data: dataWorkshops,
        loading: loadingWorshop,
        request: requestWorkshop
    } = useApi(workshop.collectionPaginate);

    useEffect(() => {
        if (dataWorkshopsPage) {
            requestWorkshop(dataWorkshopsPage);
        }
    }, [dataWorkshopsPage]);

    const {
        data: dataCreditHistory,
        loading: loadingCreditHistory,
        request: requestCreditHistory,
    } = useApi(creditHistory.expertAccountantCollection);
    const navigation = useNavigate();
    const {isAdmin}: any = storage.getUser();
    const isExpertAccountant = isExpertAccountantF(storage.getUser());
    if (!isAdmin && !isExpertAccountant) {
        navigation("/auth/home", {replace: true});
    }
    useEffect(() => {
        requestUsers(dataUsersPage);
        requestCreditHistory(selectedYear, page);
        requestInvoiceList(yearInvoiceList, pageInvoiceList);
        requestWorkshop(dataWorkshopsPage);
        requestFormation();
        requestTeam();
        requestEvent();
        requestSite();
        request();
        SettingsExported.getTva(1).then((res: any) => {
            setTva(Number(res.data.valueTVA) ?? 0);
            setCa(Number(res.data.cA) ?? 0);
            setCaugmente(Number(res.data.cAugmente) ?? 0);
        });
    }, []);
    useEffect(() => {
        setPage(1);
        requestCreditHistory(selectedYear, page);
    }, [selectedYear]);
    useEffect(() => {
        requestCreditHistory(selectedYear, page);
    }, [page]);

    useEffect(() => {
        setPageInvoiceList(1);
        requestInvoiceList(yearInvoiceList, pageInvoiceList);
    }, [yearInvoiceList]);
    useEffect(() => {
        requestInvoiceList(yearInvoiceList, pageInvoiceList);
    }, [pageInvoiceList]);

    useEffect(() => {
        if(userIdInvoice){
            requestInvoice(userIdInvoice).then((res) => {
                setIsInvoiceAllVisible(true)
            })
        } else {
            setIsInvoiceAllVisible(false)
        }
     },[userIdInvoice])


    const handleDesactivate = (isActivate: boolean, id: number) => {
        site.desactivate(isActivate, id).then((res) => {
            requestSite();
        });
    }
    //sort by bool variable
    const rows = dataSite.sort(
        (a: any, b: any) => b.isActive - a.isActive
    ).map((element: any) => (
        <tr key={element.id}>
            <td style={{width: 200}}>{element.name}</td>
            <td style={{width: 200}}>{element.address}</td>
            <td style={{width: 200}}>{element.postalCode}</td>
            <td style={{width: 200}}>{element.city}</td>
            <td style={{width: 200}}>
                {element.gmap.slice(0, 40)}+{"..."}
            </td>
            <td style={{width: 200}}>
                <div className="flex flex-auto">
                    <Button onClick={() => handleDesactivate(!element.isActive,element.id)} size={"xs"}>{element.isActive ? "Désactiver" : "Activer"}</Button>
                    <ActionIcon
                        size={50}
                        className="mx-auto"
                        onClick={() => {
                            setOpened(true);
                            formSite.setValues({
                                id: element.id,
                                name: element.name,
                                address: element.address,
                                postalCode: element.postalCode,
                                city: element.city,
                                gmap: element.gmap,
                            });
                        }}
                        color="blue"
                        variant="transparent"
                    >
                        <FiEdit size={20}/>
                    </ActionIcon>
                    <ActionIcon
                        size={50}
                        onClick={() => handleDeleteSite(element.id)}
                        className="mx-auto"
                        color="red"
                        variant="transparent"
                    >
                        <FiDelete size={20}/>
                    </ActionIcon>
                </div>
            </td>
        </tr>
    ));

    const rowsInvoice = dataInvoice.sort(
        (a: any, b: any) => b.isActive - a.isActive
    ).map((element: any) => (
        <tr key={element.id}>
            <td style={{width: 200}}>{element.user.slice(11)}</td>
            <td style={{width: 200}}>{element.itemName}</td>
            <td style={{width: 200}}>{element.total}</td>
            <td style={{width: 200}}>{element.formation?.id ?? 0}</td>
            <td style={{width: 200}}>{element.idWorkshop ?? 0}</td>
            <td style={{width: 200}}>{format_d_MMMM_YYYY(element.createAt)}</td>
            <td style={{width: 200}}>
                <div className="flex flex-auto">
                    <ActionIcon
                        size={50}
                        className="mx-auto"
                        onClick={() => {
                            setOpened(true);
                            formEditInvoice.setValues({
                                id: element.id+"",
                                user: element.user.slice(11)+"",
                                itemName: element.itemName+"",
                                total: element.total+"",
                                formation: element.formation?.id ?? 0,
                                idWorkshop: element.idWorkshop ?? 0,
                                createdAt: new Date(element.createAt)
                            });
                        }}
                        color="blue"
                        variant="transparent"
                    >
                        <FiEdit size={20}/>
                    </ActionIcon>
                </div>
            </td>
        </tr>
    ));
    const auth = useAuth();

    const handleChangeAccount = (username: string) => {
        request1.headers["x-switch-user"] = username;
        request1.get("/user/token").then(async (r: any) => {
            let token_res = r?.data?.token;
            request1.deleteHeader("x-switch-user");
            authStorage.removeToken();
            authStorage.removeRefreshToken();
            authStorage.storeTokenToken(token_res);
            window.location.reload();
        });
    };

    const onCreateUser = () => {
        setIsCreate(true);
        setIsDelete(false);
        formUserCreate.setValues({
            civilite: "",
            lastName: "",
            firstName: "",
            email: "",
            phoneNumber: "",
            role: "",
            password: "",
            confirmPassword: "",
        });
        setOpened(true);
    };

    const onEditUser = (user: any) => {
        setIsCreate(false);
        setIsDelete(false);
        formUserEdit.setValues({
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
            phoneNumber: user.phoneNumber,
            civilite: getCivility(user.civilite).value,
        });
        setOpened(true);
    };

    const onDeleteUser = (user: any) => {
        setIsCreate(false);
        setIsDelete(true);
        formUserDelete.setValues({
            id: user.id,
            lastName: user.lastName,
            firstName: user.firstName,
            civilite: getCivility(user.civilite).value,
        });
        setOpened(true);
    };

    const rowsInvoiceList = (dataInvoiceList.invoices || []).map((element: any) => (
        <InvoiceRow
            element={element}
            transactionDetails={transactionDetails}
            valueTVA={tva}
        />
    ));

    const rowsUsers = dataUsers?.items?.map((element: any) => (
        <UserRow 
            key={element?.id} 
            element={element} 
            onEditUser={(user: any) => onEditUser(user)}
            onDeleteUser={(user: any) => onDeleteUser(user)}
            onChangeAccount={(email: string) => handleChangeAccount(email)}
        />
    ));

    const rowsCreditHistory = (dataCreditHistory.credit_history || []).map((element: any) => (
        <CreditHistoryRow
            element={element}
            year={selectedYear}
        />
    ));

    const rowsCreditHistoryTotal = <CreditHistoryRowTotal key="totals" element={dataCreditHistory.totals} title={'Total'} />
    const rowsCreditHistoryBigTotal = <CreditHistoryRowTotal
        key="bigTotal"
        element={dataCreditHistory.bigTotal}
        title={'Total Général'}/>

    const maxPage = dataCreditHistory ? dataCreditHistory.maxPage : 1;

    const rowsWorkshop = dataWorkshops?.items?.map((element: any) => (
        <WorkshopRow key={element?.id} element={element}/>
    ));

    const rowsFormation = dataFormation.map((element: any) => (
        <FormationRow key={element?.id} element={element}/>
    ));

    const rowsEvent = dataEvent.map((element: any) => (
        <EventRow key={element?.id} element={element}/>
    ));

    const rowsTeam = dataTeam.map((element: any) => (
        <TeamMeatingRow key={element?.id} element={element}/>
    ));


    const rowsThem = data.map((element: any) => (
        <tr key={element.id}>
            <td>{element.wording}</td>
            <td>{element.prerequisite}</td>
            <td>{element.formerWords}</td>
            <td>
                {element.destined &&
                    element.destined.map((name: string, index: Number) => (
                        <Badge
                            className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                            variant="filled"
                        >
                            {name == "ROLE_GUEST" ? "INVITÉ" : name.slice(5)}
                        </Badge>
                    ))}
            </td>
            <td>
                {element.habilitation &&
                    element.habilitation.map((name: string, index: Number) => (
                        <Badge
                            className="mx-1 md:rounded md:bg-gray-500 bg-white text-black md:text-white"
                            variant="filled"
                        >
                            {name}
                        </Badge>
                    ))}
            </td>
            <td>{element.place}</td>
            <td>{element.placeZoom}</td>
            <td>{element.theme}</td>
            <td>{element.type}</td>
            <td>
                <div className="flex flex-auto">
                    <ActionIcon
                        size={50}
                        className="mx-auto"
                        onClick={() => {
                            setOpened(true);
                            formThematic.setValues({
                                id: element.id,
                                wording: element.wording,
                                prerequisite: element.prerequisite,
                                formerWords: element.formerWords,
                                destined: element.destined,
                                habilitation: element.habilitation,
                                place: element.place,
                                placeZoom: element.placeZoom,
                                theme: element.theme,
                                type: element.type,
                            });
                        }}
                        color="blue"
                        variant="transparent"
                    >
                        <FiEdit size={20}/>
                    </ActionIcon>
                    <ActionIcon
                        size={50}
                        onClick={() => handleDeleteThematic(element.id)}
                        className="mx-auto"
                        color="red"
                        variant="transparent"
                    >
                        <FiDelete size={20}/>
                    </ActionIcon>
                </div>
            </td>
        </tr>
    ));

    const handleEditSite = (values: any) => {
        site
            .edit(values, values.id)
            .then(() => requestSite().then(() => setOpened(false)));
    };

    const handleDeleteSite = (id: number) => {
        site.remove(id).then(() => requestSite());
    };

    const handleCreateSite = (values: any) => {
        site.create(values).then(() => requestSite().then(() => setOpened(false)));
    };

    const handleCreateInvoice = (values: any) => {
       const transactionItem = {
            user: "/api/users/"+values.user,
            email: "email@email.fr",
            lastName: "lastName",
            firstName: "firstName",
            society: "société",
            city: "ville",
            address: "address",
            postalCode: "postalCode",
            createAt: values.createdAt,
            paypalIdTransaction: "0",
            paypalAccountStatus : "status",
            //@ts-ignore
            paymentPaypalDate: new Date(),
            total: values.total,
            quantity: 1,
            itemName: values.itemName,
            workshop: values.idWorkshop > 0 ? "/api/workshops/"+values.idWorkshop : null,
            formation: values.formation > 0 ? "/api/formations/"+values.formation : null,
            idWorkshop: values.idWorkshop,
            IdFormation: values.formation
        }
        transactions.CreateTransaction2(transactionItem).then(() => requestInvoice(userIdInvoice).then(() => setOpened(false)));
    };

    const handleEditInvoice = (values: any) => {
        const transactionItem = {
            user: "/api/users/"+values.user,
            total: values.total,
            itemName: values.itemName,
            workshop: values.idWorkshop > 0 ? "/api/workshops/"+values.idWorkshop : null,
            formation: values.formation > 0 ? "/api/formations/"+values.formation : null,
            idWorkshop: values.idWorkshop,
            IdFormation: values.formation,
            createAt: values.createdAt,
        }
        transactions.EditTransaction2(transactionItem,values.id ).then(() => requestInvoice(userIdInvoice).then(() => setOpened(false)));
    };

    const handleEditThematic = (values: any) => {
        thematic
            .edit(values, values.id)
            .then(() => request().then(() => setOpened(false)));
    };

    const handleEditTva = (values: any) => {
        SettingsExported.edit(
            values.id,
            values.valueTVA,
            values.ca,
            values.caugmente
        ).then(() => {
            SettingsExported.getTva(1).then((res: any) => {
                setTva(Number(res.data.valueTVA) ?? 0);
                setCa(Number(res.data.cA) ?? 0);
                setCaugmente(Number(res.data.cAugmente) ?? 0);
            });
            setOpened(false);
        });
    };

    const handleDeleteThematic = (id: number) => {
        thematic.remove(id).then(() => request());
    };

    const handleCreateThematic = (values: any) => {
        thematic.create(values).then(() => request().then(() => setOpened(false)));
    };

    const handleCreateUser = (values: any) => {
        users.createUser(values).then(({data={}}: any) => {
            if(data?.violations?.length){
                data.violations.map((error: any) => {
                    formUserCreate.setFieldError(
                        error.propertyPath,
                        error.message,
                    );  
                })
            }
            else {
                requestUsers(1).then(() => {
                    setOpened(false)
                    setIsCreate(false)
                })
            }
        });
    };

    const handleEditUser = (values: any) => {
        users.edit(values, values.id).then(({data={}}: any) => {
            if(data?.violations?.length){
                data.violations.map((error: any) => {
                    formUserEdit.setFieldError(
                        error.propertyPath,
                        error.message,
                    );  
                })
            }
            else {
                requestUsers(dataUsersPage).then(
                    () => setOpened(false)
                )
            }
        });
    };

    const handleDeleteUser = (values: any) => {
        users.remove(values.id).then(() => requestUsers(dataUsersPage).then(() => {
            setOpened(false);
            setIsDelete(false);
        }));
    };

    const formSite = useForm({
        initialValues: {
            id: "",
            name: "",
            address: "",
            postalCode: "",
            city: "",
            gmap: "",
        },
    });

    const formEditInvoice = useForm({
        initialValues: {
            id: "",
            user : "",
            itemName : "",
            total : "",
            formation : 0,
            idWorkshop: 0,
            createdAt: new Date(),
        },
    });


    const formThematic = useForm({
        initialValues: {
            id: "",
            wording: "",
            prerequisite: "",
            formerWords: "",
            destined: "",
            habilitation: "",
            place: "",
            placeZoom: "",
            theme: "",
            type: "",
        },
    });

    const formUserDelete = useForm({
        initialValues: {
            id: "",
            civilite: "",
            lastName: "",
            firstName: "",
        },
    });

    const formUserEdit = useForm({
        initialValues: {
            id: "",
            civilite: "",
            lastName: "",
            firstName: "",
            phoneNumber: "",
        },
        validationRules: {
            phoneNumber: (value) => /^\d+$/.test(value) && value.length === 10,
        },
        errorMessages: {
            phoneNumber: "Le numéro de téléphone est invalide.",
        },
    });

    const formUserCreate = useForm({
        initialValues: {
            civilite: "",
            lastName: "",
            firstName: "",
            email: "",
            phoneNumber: "",
            role: "",
            password: "",
            confirmPassword: "",
        },
        validationRules: {
            email: (value) => /^\S+@\S+$/.test(value),
            phoneNumber: (value) => /^\d+$/.test(value) && value.length === 10,
            password: (value) => value.length >= 8,
            confirmPassword: (confirmPassword, values) => confirmPassword === values?.password,
        },
        errorMessages: {
            email: "L'adresse email est invalide.",
            phoneNumber: "Le numéro de téléphone est invalide.",
            password: "Le mot de passe doit contenir au minimum 8 caractères.",
            confirmPassword: "Les mots de passe ne correspondent pas.",
        },
    });

    const formTVA = useForm({
        initialValues: {
            id: 1,
            valueTVA: 0,
            CA: 0,
            CAugmente: 0,
        },
    });

    function UpdateImage(value: []) {
        // @ts-ignore
        setFile(value);
    }

    const handleSubmitProdemial = (arrValues: any) => {
        if (arrValues.length > 0) {
            const data = new FormData();
            // @ts-ignore
            data.append("file", arrValues[0]);
            const headers = {
                "Content-Type": "multipart/form-data",
            };
            requestClient.post("/upload/prodemial", data);
        }
    };

    return (
        <>
            <LoadingOverlay visible={loading}/>
            <LoadingOverlay visible={loadingSite}/>
            {isSiteVisible && (
                <ModalComponent
                    type="site"
                    formSite={formSite}
                    handleSubmit={(e: any) =>
                        isCreate ? handleCreateSite(e) : handleEditSite(e)
                    }
                    isSiteVisible={isSiteVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}

            {isInvoiceAllVisible && (
                <ModalComponent
                    type="invoice"
                    formSite={formEditInvoice}
                    handleSubmit={(e: any) =>
                        isCreate ? handleCreateInvoice(e) : handleEditInvoice(e)
                    }
                    isSiteVisible={isInvoiceAllVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}

            {isAdmin && (
                <div>
                    <div className="p-10">
                        <h2>Upload prodémial</h2>
                        <div className="mt-5">
                            <Dropzone onDrop={(value: []) => handleSubmitProdemial(value)} multiple={false}>
                                {() => (
                                    <span>
                                        {file && file.length > 0
                                            ? "Fichier séléctionné, envoie en cours (temps entre 5 secondes jusqu'a 2 minutes)"
                                            : "Cliquez pour séléctionner un fichier ( envoie automatique dès la séléction du fichier)"}
                                    </span>
                                )}
                            </Dropzone>
                        </div>
                    </div>
                    <div className="p-10">
                        <h2>Extraction Formations, Ateliers, Evenements, Réunions d'équipes</h2>
                        <div className="mt-5">
                            <a href={`${REACT_APP_API_BASE_URL}/export/activities`} target="_blank" className="relative mt-4 bg-white rounded-md p-2 w-full">
                                Extraire les données des Formations,Ateliers, Evenements, Réunions d'équipes
                            </a>
                        </div>

                        <h2>Extraction des fichiers utilisateurs</h2>
                        <div className="mt-5">
                            <a href={`${REACT_APP_API_BASE_URL}/export_user`}  target="_blank" className="relative mt-4 bg-white rounded-md p-2 w-full">
                                Extraire les données utilisateurs
                            </a>
                        </div>
                    </div>

                    <div className="p-10">
                        <h2>Rechercher une personne</h2>
                        <Select
                            rightSection={<></>}
                            searchable
                            placeholder="Recherchez un utilisateur"
                            data={usersData}
                            onSearchChange={(value) => setSearch(value)}
                            onChange={(value: string) => setUserId(value)}
                            clearable
                        ></Select>
                        {userUniqueRow && (
                            <div className="mt-5">
                                <div className="mx-auto bg-white shadow-xl p-5 overflow-auto">
                                    <>
                                        <div className=" mx-10 text-xl text-center">
                                            <span className=" text-2xl text-center">Utilisateur</span>
                                        </div>
                                        <div className="mt-3 overflow-auto md:mt-3 text-xl ">
                                            <Table className={"overflow-auto "} highlightOnHover>
                                                <thead className="flex-grow">
                                                <tr>
                                                    <th style={{width: 50}}>ID</th>
                                                    <th style={{width: 200}}>Noms prénoms</th>
                                                    <th style={{width: 200}}>Email</th>
                                                    <th style={{width: 300}}>Fin du cursus</th>
                                                    <th style={{width: 200}}>Téléphone</th>
                                                    <th style={{width: 100}}>Niveau</th>
                                                    <th style={{width: 50}}>Pilote</th>
                                                    <th style={{width: 75}}>Journée Découverte</th>
                                                    <th style={{width: 75}}>Journée Démarrage</th>
                                                    <th style={{width: 75}}>3 JR</th>
                                                    <th style={{width: 100}}>Crédits</th>
                                                    <th style={{width: 200}}>Adhésion payé</th>
                                                    <th style={{width: 200}}>Adresse</th>
                                                    <th style={{width: 200}}>Parrain</th>
                                                    <th style={{width: 200}}>ID du nouveau parrain</th>
                                                    <th style={{width: 200}}>Manager</th>
                                                    <th style={{width: 200}}>ID du nouveau manager</th>
                                                    <th style={{width: 200}}>Action</th>
                                                </tr>
                                                </thead>
                                                <tbody className={"overflow-auto "}>
                                                    <UserRow 
                                                        element={userUniqueRow} 
                                                        onEditUser={(user: any) => onEditUser(user)}
                                                        onDeleteUser={(user: any) => onDeleteUser(user)}
                                                        onChangeAccount={(email: string) => handleChangeAccount(email)}
                                                    />
                                                </tbody>
                                            </Table>
                                        </div>
                                    </>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}

            {isThematicVisible && (
                <ModalComponent
                    type="thematic"
                    formSite={formThematic}
                    handleSubmit={(e: any) =>
                        isCreate ? handleCreateThematic(e) : handleEditThematic(e)
                    }
                    isSiteVisible={isThematicVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}

            {isAccountingModuleVisible && (
                <ModalComponent
                    type="thematic"
                    formSite={formThematic}
                    handleSubmit={(e: any) =>
                        isCreate ? handleCreateThematic(e) : handleEditThematic(e)
                    }
                    isSiteVisible={isThematicVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}

            {isUsersVisible && !isDelete && (
                <ModalComponent
                    type="user"
                    subType={isCreate ? "create" : "edit"}
                    formSite={isCreate ? formUserCreate : formUserEdit}
                    handleSubmit={isCreate ? handleCreateUser : handleEditUser}
                    isSiteVisible={isUsersVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}
            
            {isUsersVisible && isDelete && (
                <ModalComponent
                    type="user"
                    subType="delete"
                    formSite={formUserDelete}
                    handleSubmit={handleDeleteUser}
                    isSiteVisible={isUsersVisible}
                    onClose={() => setOpened(false)}
                    opened={opened}
                />
            )}

            <div className="grid grid-cols-4 gap-5 justify-between w-full px-10 pt-10 pb-28 md:pb-10 ">
                
                {isAdmin && ([
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 mr-5 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsSiteVisible(!isSiteVisible);
                            setIsThematicVisible(false);
                            setIsOtherVisible(false);
                            setIsWorkshopVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interfaces des sites
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 mr-5 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsOtherVisible(false);
                            setIsWorkshopVisible(!isWorkshopVisible);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interfaces des ateliers
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 mr-5 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsOtherVisible(false);
                            setIsFormationVisible(!isFormationVisible);
                            setIsWorkshopVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interfaces des formations
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 mr-5 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsThematicVisible(!isThematicVisible);
                            setIsSiteVisible(false);
                            setIsOtherVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);

                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interfaces des thématiques
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2  mr-5"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(!isOtherVisible);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interface tva
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(!isUsersVisible);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interface utilisateurs
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(!isEventVisible);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interface évènements
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsInvoiceVisible(false)
                            setIsInvoiceListVisible(false);
                            setIsTeamVisible(!isTeamVisible);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interface réunion d'équipe
                    </button>
                ])}

                {(isAdmin || isExpertAccountant) && ([
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(!isInvoiceVisible);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Interface des factures
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(!isInvoiceListVisible);
                            setIsAccountingModuleVisible(false);
                        }}
                    >
                        Liste des factures
                    </button>,
                    <button
                        className="bg-white shadow-lg rounded text-2xl h-24 px-2"
                        onClick={() => {
                            setIsUsersVisible(false);
                            setIsOtherVisible(false);
                            setIsSiteVisible(false);
                            setIsThematicVisible(false);
                            setIsWorkshopVisible(false);
                            setIsFormationVisible(false);
                            setIsEventVisible(false);
                            setIsTeamVisible(false);
                            setIsInvoiceVisible(false);
                            setIsInvoiceListVisible(false);
                            setIsAccountingModuleVisible(!isAccountingModuleVisible);
                        }}
                    >
                        Liste des avoirs
                    </button>
                ])}

            </div>
            
            <div
                className="grid grid-cols-1 justify-between w-full px-10 pt-10 pb-28 md:pb-10 overflow-auto"
            >
                {isSiteVisible && (
                    <ListingComponent
                        onCreate={() => {
                            setIsCreate(true);
                            formSite.setValues({
                                id: "",
                                name: "",
                                address: "",
                                postalCode: "",
                                city: "",
                                gmap: "",
                            });
                            setOpened(true);
                        }}
                        rows={rows}
                        title="LES SITES"
                        thead={
                            <thead className="flex-wrap">
                            <tr>
                                <th>Nom</th>
                                <th>Adresse</th>
                                <th>Code postal</th>
                                <th>Ville</th>
                                <th>Gmap</th>
                            </tr>
                            </thead>
                        }
                    />
                )}
                {isInvoiceVisible && (
                    <>
                        <h2>Rechercher les factures pour un utilisateur</h2>
                        <Select
                            rightSection={<></>}
                            searchable
                            placeholder="Recherchez un utilisateur"
                            data={usersDataInvoice}
                            onSearchChange={(value) => setSearchInvoice(value)}
                            onChange={(value: string) => setUserIdInvoice(value)}
                            clearable
                        ></Select>
                        {isInvoiceAllVisible && (
                            <ListingComponent
                                onCreate={() => {
                                    setIsCreate(true);
                                    formEditInvoice.setValues({
                                        id: "",
                                        user: "",
                                        itemName:"",
                                        total: "",
                                        formation: 0,
                                        idWorkshop: 0,
                                        createdAt: new Date(),
                                    });
                                    setOpened(true);
                                }}
                                rows={rowsInvoice}
                                title="LES FACTURES"
                                thead={
                                    <thead className="flex-wrap">
                                    <tr>
                                        <th>Utilisateur</th>
                                        <th>Description</th>
                                        <th>Coût</th>
                                        <th>Formation</th>
                                        <th>Atelier</th>
                                        <th>Date de création</th>
                                    </tr>
                                    </thead>
                                }
                            />
                        )}
                    </>
                )}
                {isThematicVisible && (
                    <ListingComponent
                        onCreate={() => {
                            setIsCreate(true);
                            formThematic.setValues({
                                id: "",
                                wording: "",
                                prerequisite: "",
                                formerWords: "",
                                destined: "",
                                habilitation: "",
                                place: "",
                                placeZoom: "",
                                theme: "",
                                type: "",
                            });
                            setOpened(true);
                        }}
                        rows={rowsThem}
                        title="LES THEMATICS"
                        thead={
                            <thead className="flex-wrap">
                            <tr>
                                <th>Nom</th>
                                <th>Pré-requis</th>
                                <th>Mot du formateur</th>
                                <th>Destinée</th>
                                <th>Habilitations</th>
                                <th>Places</th>
                                <th>Places Zoom</th>
                                <th>Thême</th>
                                <th>Type</th>
                            </tr>
                            </thead>
                        }
                    />
                )}
                {isUsersVisible ? (
                    loadingUsers ? (
                        <LoadingOverlay visible={loadingUsers}/>
                    ) : (
                        <ListingComponent
                            onCreate={onCreateUser}
                            rows={rowsUsers}
                            pagination={dataUsers?.pagination}
                            onPageChange={setDataUsersPage}
                            title="LES UTILISATEURS"
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 50}}>ID</th>
                                    <th style={{width: 200}}>Noms prénoms</th>
                                    <th style={{width: 200}}>Email</th>
                                    <th style={{width: 300}}>Fin du cursus</th>
                                    <th style={{width: 200}}>Téléphone</th>
                                    <th style={{width: 100}}>Niveau</th>
                                    <th style={{width: 50}}>Pilote</th>
                                    <th style={{width: 75}}>Journée Découverte</th>
                                    <th style={{width: 75}}>Journée Démarrage</th>
                                    <th style={{width: 75}}>3 JR</th>
                                    <th style={{width: 100}}>Crédits</th>
                                    <th style={{width: 200}}>Adhésion payé</th>
                                    <th style={{width: 200}}>Adresse</th>
                                    <th style={{width: 200}}>Parrain</th>
                                    <th style={{width: 200}}>Manager</th>
                                    <th style={{width: 200}}>Action</th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                ) : null}
                {isInvoiceListVisible ? (
                    loadingInvoiceList ? (
                        <LoadingOverlay visible={loadingInvoiceList}/>
                    ) : (
                        <ReadOnlyListingComponent
                            title="Liste des factures"
                            selectedYear={yearInvoiceList}
                            onYearChange={handleYearInvoiceListChange} 
                            onPageChange={handlePageInvoiceListChange} 
                            rows={rowsInvoiceList}
                            page={pageInvoiceList} 
                            exportLink={`${REACT_APP_API_BASE_URL}/export/invoice/${yearInvoiceList}`}
                            maxPage={(dataInvoiceList.maxPage > 0) ? 
                                dataInvoiceList.maxPage : 1
                            } 
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 100, whiteSpace: "nowrap"}}>ID Utilisateur</th>
                                    <th style={{width: 100}}>Noms prénoms</th>
                                    <th style={{width: 100}}>Date</th>
                                    <th style={{width: 100}}>Numéro facture</th>
                                    <th style={{width: 100}}>Montant</th>
                                    <th style={{width: 100}}></th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                ) : null}
                {isAccountingModuleVisible ? (
                    loadingCreditHistory ? (
                        <LoadingOverlay visible={loadingCreditHistory}/>
                    ) : (
                        <ReadOnlyListingComponent
                            selectedYear={selectedYear}
                            onYearChange={handleYearChange} 
                            onPageChange={handlePageChange} 
                            page={page} 
                            maxPage={maxPage} 
                            rows={[rowsCreditHistory, rowsCreditHistoryTotal, rowsCreditHistoryBigTotal]}
                            exportLink={`${REACT_APP_API_BASE_URL}/export/credit-history/${selectedYear}`}
                            exportGlobalLink={`${REACT_APP_API_BASE_URL}/export/credit-detail-all/${selectedYear}`}
                            title="Historique des avoirs"
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 100, whiteSpace: "nowrap"}}>ID Utilisateur</th>
                                    <th style={{width: 100}}>Janvier</th>
                                    <th style={{width: 100}}>Février</th>
                                    <th style={{width: 100}}>Mars</th>
                                    <th style={{width: 100}}>Avril</th>
                                    <th style={{width: 100}}>Mai</th>
                                    <th style={{width: 100}}>Juin</th>
                                    <th style={{width: 100}}>Juillet</th>
                                    <th style={{width: 100}}>Août</th>
                                    <th style={{width: 100}}>Septembre</th>
                                    <th style={{width: 100}}>Octobre</th>
                                    <th style={{width: 100}}>Novembre</th>
                                    <th style={{width: 100}}>Décembre</th>
                                    <th style={{width: 100}}>{"Détails"}</th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                ) : null}
                {isOtherVisible && (
                    <ModalComponent
                        type="tva"
                        formSite={formTVA}
                        handleSubmit={(e: any) => handleEditTva(e)}
                        isSiteVisible={isOtherVisible}
                        onClose={() => setOpened(false)}
                        opened={opened}
                    />
                )}
                {isWorkshopVisible && (
                    loadingWorshop ? (
                            <LoadingOverlay visible={loadingWorshop}/>
                        ) : (
                    <ListingComponent
                        onCreate={() => {
                        }}
                        rows={rowsWorkshop}
                        pagination={dataWorkshops?.pagination}
                        onPageChange={setDataWorkshopsPage}
                        title="LES Ateliers"
                        thead={
                            <thead className="flex-grow">
                            <tr>
                                <th style={{width: 50}}>ID</th>
                                <th style={{width: 200}}>Thematique</th>
                                <th style={{width: 200}}>Titre</th>
                                <th style={{width: 200}}>Pilot</th>
                                <th style={{width: 200}}>Date</th>
                                <th style={{width: 50}}>Prix</th>
                                <th style={{width: 200}}>Lieu</th>
                                <th style={{width: 200}}>Participants</th>
                                <th style={{width: 200}}>inscriptions réservées liste d'attente</th>
                            </tr>
                            </thead>
                        }
                    />
                    )
                )}
                {isFormationVisible && (
                    loadingFormation ? (
                        <LoadingOverlay visible={loadingFormation}/>
                    ) : (
                        <ListingComponent
                            onCreate={() => {
                            }}
                            rows={rowsFormation}
                            title="Les Formations"
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 50}}>ID</th>
                                    <th style={{width: 200}}>Thematique</th>
                                    <th style={{width: 200}}>Titre</th>
                                    <th style={{width: 200}}>Pilot</th>
                                    <th style={{width: 200}}>Date</th>
                                    <th style={{width: 50}}>Prix</th>
                                    <th style={{width: 200}}>Lieu</th>
                                    <th style={{width: 200}}>Participants</th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                )}
                {isEventVisible && (
                    loadingEvent ? (
                        <LoadingOverlay visible={loadingEvent}/>
                    ) : (
                        <ListingComponent
                            onCreate={() => {
                            }}
                            rows={rowsEvent}
                            title="Les Evenements"
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 50}}>ID</th>
                                    <th style={{width: 200}}>Thematique</th>
                                    <th style={{width: 200}}>Titre</th>
                                    <th style={{width: 200}}>Pilot</th>
                                    <th style={{width: 200}}>Date</th>
                                    <th style={{width: 50}}>Prix</th>
                                    <th style={{width: 200}}>Lieu</th>
                                    <th style={{width: 200}}>Participants</th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                )}
                {isTeamVisible && (
                    loadingTeam ? (
                        <LoadingOverlay visible={loadingTeam}/>
                    ) : (
                        <ListingComponent
                            onCreate={() => {
                            }}
                            rows={rowsTeam}
                            title="Les réunions d'équipes"
                            thead={
                                <thead className="flex-grow">
                                <tr>
                                    <th style={{width: 50}}>ID</th>
                                    <th style={{width: 200}}>Thematique</th>
                                    <th style={{width: 200}}>Titre</th>
                                    <th style={{width: 200}}>Pilot</th>
                                    <th style={{width: 200}}>Date</th>
                                    <th style={{width: 50}}>Prix</th>
                                    <th style={{width: 200}}>Lieu</th>
                                    <th style={{width: 200}}>Participants</th>
                                </tr>
                                </thead>
                            }
                        />
                    )
                )}
                {isOtherVisible && (
                    <div className="mx-auto w-full bg-white shadow-xl p-5">
                        <>
                            <div className=" mx-10 text-xl text-left">
                                <div className="flex gap-x-5 items-center">
                                    <p className="flex flex-wrap gap-x-1">
                                        TVA : <p>{tva} %</p>
                                    </p>
                                    <p className="flex flex-wrap gap-x-1">
                                        Chiffre d'affaire : <p>{ca1 ?? 0} %</p>
                                    </p>
                                    <p>Status CA : {caugmente ?? 0} </p>
                                    <Button
                                        onClick={() => {
                                            setOpened(true);
                                            formTVA.setValues({
                                                id: 1,
                                                valueTVA: tva,
                                                CA: ca1,
                                                CAugmente: caugmente,
                                            });
                                        }}
                                        className="ml-3 bg-gray-500 rounded"
                                        size="xs"
                                        compact
                                    >
                                        Modifier
                                    </Button>
                                </div>
                            </div>
                        </>
                    </div>
                )}
            </div>

            <Footer/>
        </>
    );
};

export default AdminScreen;
