import React, { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import users from "../../../api/users";
import {
  ActionIcon,
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  LoadingOverlay,
  ScrollArea,
} from "@mantine/core";
import RoleCardAccount from "../../../components/account/RoleCardAccount";
import { AiOutlineCheck, AiOutlineRight, GiSandsOfTime , AiOutlineArrowRight} from "react-icons/all";
import nextRole from "../../../utility/nextRole";
import probatoire from "../../../utility/probatoire";
import dayjs from "dayjs";
import GoalItem from "../../../components/goals/GoalItem";
import goals from "../../../api/goals";
import finishedGoals from "../../../api/finishedGoals";
import Footer from "../../Footer";
import evolution from "../../../api/evolution";
import { ROLE_WITHOUT_TOUS } from "../../../constants/roles";
import storage from "../../../auth/storage";

const Goals = () => {
  const { id } = useParams();
  const StorageId: any = storage.getUser();
  const { data, loading, request } = useApi(users.infos);
  const {
    data: dataGoals,
    loading: loadingGoals,
    request: requestGoal,
  } = useApi(goals.getByLevel);
  const {
    data: dataALLGoals,
    loading: loadingALLGoals,
    request: requestALLGoals,
  } = useApi(goals.getAll);
  const { data: dataEvo, request: requestEvo } = useApi(
      evolution.collectionByUser,
  );
  const {
    data: dataFiniGoals,
    loading: loadingFiniGoals,
    request: requestFiniGoal,
  } = useApi(finishedGoals.getByGoalIdAndUserId);

  let namesToDeleteSet: any[];
  namesToDeleteSet = [];
  const test = dataFiniGoals.forEach((goal: any) => {
    namesToDeleteSet.push(goal.goal);
  });
  const [requestLoading, setRequestLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<any>(null);
  const role = nextRole(userInfo?.roles && userInfo?.roles[0]);
  const index = probatoire.indexOfMonth(userInfo?.roles && userInfo?.roles[0]);
  const monthProbatoire = probatoire.getMonthProbatoire(
      userInfo?.roles && userInfo?.roles[0],
  );
  const dateProbatoire = probatoire.getDateProbatoire(
      userInfo?.roles && userInfo?.roles[0],
      new Date(
          Date.parse(
              userInfo?.userRoleInformation &&
              userInfo?.userRoleInformation[probatoire.ROLE_DATE[index]],
          ),
      ),
  );

//@ts-ignore
  useEffect(() => {
    window.scrollTo(0, 0);
    request(id).then((res) => {

    });
    requestEvo(id);
    //@ts-ignore
  }, []);


  useEffect(() => {
    setUserInfo(data);
    requestALLGoals();
    requestGoal(data?.roles ? data.roles[0] : "").then((res) => {
        console.log("ALL GOALS MAN",res)
    });
    requestFiniGoal(data?.roles ? data.roles[0] : "", id).then((res) => {
      namesToDeleteSet = res.data;
    });
  }, [data]);

  const idRole = ROLE_WITHOUT_TOUS.indexOf(
      userInfo?.roles && userInfo?.roles[0],
  );

  const RôleScrollView = useRef<HTMLDivElement>(null);
  const RôleView = useRef<HTMLDivElement>(null);
  // @ts-ignore
  RôleScrollView?.current?.scrollTo({left: RôleView?.current?.offsetLeft - RôleScrollView?.current?.offsetWidth / 2 + RôleView?.current?.offsetWidth / 2,});

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Adhérents", href: "/auth/adherents" },
    { title: "Mon Profil", href: `/auth/adherents/profil/${id}` },
    { title: "Mes objectifs", href: `/auth/adherents/profil/goals/${id}` },
  ].map((item, index) => (
      <Anchor
          component={Link}
          to={item.href}
          key={index}
          style={{ color: "#333", fontSize: 14 }}
      >
        {item.title}
      </Anchor>
  ));

  const handlePost = async (goal: any) => {
    setRequestLoading(true);
    const result = await finishedGoals.post({ goalId: goal.id, userId: id });
    if (result.ok) {
      await requestGoal(data?.roles ? data.roles[0] : "");
      await requestFiniGoal(data?.roles ? data.roles[0] : "", id);
      setRequestLoading(false);
    }
    if (!result.ok) {
      setRequestLoading(false);
    }
  };

  const handleValidate = async (goal: any) => {
    setRequestLoading(true);
    const result = await finishedGoals.validate({
      goalId: goal.goal.id,
      userId: id,
      validatedAt: new Date(),
      id: goal.id,
    });
    if (result.ok) {
      await requestFiniGoal(data?.roles ? data.roles[0] : "", id);
      setRequestLoading(false);
    }
    if (!result.ok) {
      setRequestLoading(false);
    }
  };

  const handleRefuse = async (goal: any) => {
    setRequestLoading(true);
    const result = await finishedGoals.refuse({
      goalId: goal.goal.id,
      userId: id,
      validatedAt: new Date(),
      id: goal.id,
    });
    if (result.ok) {
      await requestGoal(data?.roles ? data.roles[0] : "");
      await requestFiniGoal(data?.roles ? data.roles[0] : "", id);
      setRequestLoading(false);
    }
    if (!result.ok) {
      setRequestLoading(false);
    }
  };

  const handleDelete = async (goal: any) => {
    setRequestLoading(true);
    const result = await finishedGoals.deleteGoal(goal.id);
    if (result.ok) {
      await requestGoal(data?.roles ? data.roles[0] : "");
      await requestFiniGoal(data?.roles ? data.roles[0] : "", id);
      setRequestLoading(false);
    }
    if (!result.ok) {
      setRequestLoading(false);
    }
  };

  return (
      <>
        <LoadingOverlay visible={loading} />
        <LoadingOverlay visible={loadingGoals} />
        <LoadingOverlay visible={loadingFiniGoals} />
        <LoadingOverlay visible={requestLoading} />
        <div className="absolute left-60 top-5">
          <Link to={`/auth/adherents/profil/${id}`}>
            <Button
                className="text-sm hover:text-gray-600 hover:bg-gray-200 w-auto bg-gray-100 text-gray-400 shadow"
                color="lime"
                size="xs"
                variant="filled"
            >
              Retour
            </Button>
          </Link>
        </div>
        <div className="w-screen md:w-auto px-6 py-2 md:p-8">
          <Breadcrumbs
              className="my-5 text-gray-800"
              separator="/"
              styles={{ separator: { color: "#333" } }}
          >
            {items}
          </Breadcrumbs>
          <h1 className="font-bold uppercase">Objectifs </h1>
          <div className="flex space-x-2">
            <p className="capitalize text-2xl font-light break-all">
              {userInfo?.firstName} {userInfo?.lastName}
            </p>
          </div>
          <div className="mt-6 mb-6">
            <ScrollArea viewportRef={RôleScrollView} className={"mx-auto"} style={{maxWidth: 1100}}>
              <div className="flex justify-around items-center my-10 ">
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_NEOMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Neoman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_NEOMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.neomanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_NEOMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_NEOMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_BEMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Beman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_BEMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.bemanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_BEMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_BEMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_ADMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Adman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_ADMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.AdmanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_ADMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_ADMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_MAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Man"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_MAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.manCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_MAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_MAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_DEVMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Devman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_DEVMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.devmanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_DEVMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_DEVMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_DXMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="DXman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_DXMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.dxmanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_DXMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_DXMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
                <div className="flex-none justify-center text-center my-5">
                  <AiOutlineRight size={25} />
                </div>
                <div
                    ref={
                      userInfo?.roles && userInfo?.roles.includes("ROLE_XMAN")
                          ? RôleView
                          : null
                    }
                >
                  <RoleCardAccount
                      role="Xman"
                      Goals={dataALLGoals.filter(function (item: any) {
                        if (item.level === "ROLE_XMAN") {
                          return item;
                        }
                      })}
                      date={
                        userInfo?.userRoleInformation
                            ? userInfo?.userRoleInformation?.xmanCreatedAt
                            : null
                      }
                      isActive={
                        userInfo?.roles
                            ? userInfo?.roles.includes("ROLE_XMAN")
                            : false
                      }
                      isRole={
                        idRole > ROLE_WITHOUT_TOUS.indexOf("ROLE_XMAN") &&
                        userInfo?.id == StorageId.id
                            ? false
                            : true
                      }
                      goalFinish={dataGoals.length}
                      goalAtFinish={dataFiniGoals.length}
                  />
                </div>
              </div>
            </ScrollArea>
          </div>
            {window.innerWidth > 768 ? (
                <div className="hidden">
                    {userInfo?.roles &&
                    userInfo?.roles.length > 0 &&
                    userInfo?.roles[0] === "ROLE_XMAN" ? (
                        <h2 className="text-center  font-light text-2xl">
                            Pour se maintenir au niveau "XMAN" -&gt;
                            <span className="font-bold">
                  {" "}
                                vous avez {dataGoals.length}  objectifs à réaliser
                </span>
                        </h2>
                    ) : (
                        <h2 className="text-center uppercase font-light text-2xl">
                            Pour passer à l'échelon "{role?.substring(5)}"{" "}
                            <span className="font-bold">
                vous avez {dataGoals.length} objectifs à réaliser
                </span>
                        </h2>
                    )}
                </div>
            ) : null}
          <div className=" text-yellow-500  justify-center flex mt-3">
            <div className="justify-center bg-white shadow rounded-md p-3 mx-2 flex">
              <GiSandsOfTime size={30} className="float-left mr-3 my-auto" />{" "}
              <span className="font-bold text-thirteen md:text-thirteen my-auto">

              Fin de période d'atteinte d'objectifs : {dayjs(dateProbatoire).locale("fr").format("DD ") +
                  dayjs(dateProbatoire)
                      .locale("fr")
                      .format("MMMM YYYY")
                      .charAt(0)
                      .toUpperCase() +
                  dayjs(dateProbatoire).locale("fr").format("MMMM YYYY").slice(1)}
            </span>
            </div>
          </div>
          <span className="flex justify-center text-thirteen mt-5  mx-auto">Pour valider un objectif, déplacez-le dans la colonne de droite</span>

          <div className="md:flex md:justify-evenly mt-16 md:mx-16 gap-10">
            <div className="md:w-1/2">
              {dataFiniGoals.length > 0 ? (
                  <div className="mb-5">
                    <ActionIcon
                        size="lg"
                        className="flex w-12 h-12 mx-auto"
                        color="gray"
                        variant="filled"
                    >
                      <AiOutlineCheck size={40} />
                    </ActionIcon>
                    <h2 className="text-center uppercase font-bold text-s16 mt-3">
                      Plus que{" "}
                      {
                        dataGoals.filter(function (obj: any) {
                          return !namesToDeleteSet.find(function (obj2: any) {
                            return obj.id === obj2.id;
                          });
                        }).length
                      }{" "}
                      objectifs
                    </h2>
                  </div>
              ) : (
                  <h2 className="text-center uppercase font-bold text-base mt-3">
                    {
                      dataGoals.filter(function (obj: any) {
                        return !namesToDeleteSet.find(function (obj2: any) {
                          return obj.id === obj2.id;
                        });
                      }).length
                    }{" "}
                    objectif à réaliser
                  </h2>
              )}

              <ul className="flex flex-col">
                {dataGoals
                    .filter(function (obj: any) {
                      return !namesToDeleteSet.find(function (obj2: any) {
                        return obj.id === obj2.id;
                      });
                    })
                    .map((goal: any) => (
                        <GoalItem
                            onUserGodFatherClose={handleDelete}
                            onRealised={handlePost}
                            isCompleted={false}
                            goal={goal}
                            onClose={(value) => console.log(value)}
                            onComplete={(value) => console.log(value)}
                            userInfo={userInfo}
                        />
                    ))}
                {dataGoals.filter(function (obj: any) {
                      return !namesToDeleteSet.find(function (obj2: any) {
                        return obj.id === obj2.id;
                      });
                    }).length == 0 &&
                    dataEvo.length == 0 && <span></span>}
              </ul>
            </div>

            <div className="md:w-1/2 my-24 md:mt-0">
              {dataFiniGoals.length > 0 ? (
                  <div className="mb-5">
                    <ActionIcon
                        size="lg"
                        className="flex mx-auto w-12 h-12"
                        color="green"
                        variant="filled"
                    >
                      <AiOutlineCheck size={40} />
                    </ActionIcon>
                    <h2 className="text-center uppercase font-bold text-s16 mt-3">
                      {dataFiniGoals.length} objectifs déjà réalisés
                    </h2>
                  </div>
              ) : (
                  <h2 className="text-center uppercase font-bold text-base mt-3">
                    Aucun objectif validé
                  </h2>
              )}

              <ul className="flex flex-col">
                {dataFiniGoals.map((goal: any) => (
                    <GoalItem
                        onUserGodFatherClose={handleDelete}
                        onRealised={() => console.log("value")}
                        isCompleted
                        goal={goal}
                        onClose={handleRefuse}
                        onComplete={handleValidate}
                        userInfo={userInfo}
                    />
                ))}
              </ul>
            </div>
          </div>
          <Footer />
        </div>
      </>
  );
};

export default Goals;
