import { Navigate, Outlet } from 'react-router-dom';

import storage from '../../../auth/storage';
import useAuth from '../../../auth/useAuth';

const LayoutSimulation = () => {

    const user: any = storage.getUser();
    const isAuth: any = useAuth().isAuthenticated();

    if (isAuth && user.isAdmin) {
        return (
            <Outlet />
        );
    }

    return (
        <Navigate to="/" />
    );
}

export default LayoutSimulation;
