import React, { useEffect, useState } from 'react';
import {ActionIcon, Button, Modal} from "@mantine/core";
import {
    AiOutlineSearch,
    BsArrowRight,
    FaUserAlt, GiTicket,
    ImArrowDownRight2,
    ImArrowRight2,
    ImArrowUpRight2, IoRefreshSharp
} from "react-icons/all";
import dayjs from "dayjs";
import {Link, useNavigate} from "react-router-dom";
import storage from "../../auth/storage";
import ButtonWrapper from '../paypal/ButtonWrapper';
import useApi from '../../hooks/useApi';
import transactions from '../../api/transactions';
import users from "../../api/users";
import {useNotifications} from "@mantine/notifications";
import { format_ddMMYYYY } from "../../utility/dateUtility";
import { formatItemName } from '../../utility/formatItemName';

interface ItemJdInterface {
    workshop: any;
}

const ItemJd = (props: ItemJdInterface) => {
    const navigation = useNavigate();
    const [openModalPaypal, setOpenModalPaypal] = useState<boolean>(false);
    const { request} = useApi(transactions.getAllByUser);
    const { data: dataUser,request: requestUser} = useApi(users.me);
    const [isPaid, setIsPaid] = useState<boolean>(false);
    const {id}: any = storage.getUser();
    const {request: requestTransaction} = useApi(transactions.CreateTransaction)
    const notifications = useNotifications();


    useEffect(() => {
        request(id).then((res: any) => {
            res.data.find((item: any) => {
                if (item.idWorkshop === props.workshop.id) {
                    setIsPaid(true);
                    }})
        });
        requestUser(id);
    },[]);

    const handlePaid = () => {
        const date = new Date();
        requestUser(id).then((res) => {

            requestTransaction({
                user: res?.data?.id ?? null,
                email: res?.data?.email ?? '',
                lastName: res?.data?.lastName ?? '',
                firstName: res?.data?.firstName ?? '',
                society: res?.data?.society ?? null,
                city: res?.data?.city ?? null,
                address: res?.data?.address ?? null,
                postalCode: res?.data?.postalCode ?? null,
                createAt: (date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()),
                paypalIdTransaction: "STRIPE PAYMENT",
                paypalAccountStatus: "COMPLETED",
                //@ts-ignore
                paymentPaypalDate: (date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate()),
                paymentMethod: "PAYMENT_BY_CREDIT",
                total: props.workshop.price.toString(),
                quantity: 1,
                itemName: formatItemName("PAYMENT_BY_CREDIT", `Journée Découverte parrain #${id}`),
                workshop: parseInt(props.workshop.id),
                formation: undefined,
                idWorkshop: parseInt(props.workshop.id),
                IdFormation: undefined
            })
                .then((res) => {
                    // @ts-ignore
                    setIsPaid(true)
                    navigation(`/auth/workshop/detail/${props.workshop.id}`)
                    notifications.showNotification({
                        title: "Nice !",
                        message: "Vous venez de finaliser l'inscription avec vos crédits !",
                        color: "green",
                    });
                })
        })
    }


    return (
        <div key={props.workshop.id} className="flex cursor-pointer justify-between flex-wrap bg-white gap-y-5 rounded my-5 shadow p-5">
            <div onClick={() => navigation(`/auth/workshop/detail/${props.workshop.id}`)} className='flex gap-4 flex-wrap'>
                <div className="flex flex-col gap-2">
                    <h2 className="uppercase font-bold">JOURNÉE DÉCOUVERTE</h2>
                    <div className="">
                                    <span className="text-gray-500 text-sm mr-1">Le <span
                                        className="font-bold uppercase">{format_ddMMYYYY(props.workshop?.startDate)}</span></span>
                        {props.workshop.site?.name && <span className="text-gray-500 text-sm">
                            à <span className="font-bold uppercase">{props.workshop.site?.name}</span>
                        </span>}
                    </div>
                    <div className="flex">
                        <span className="text-gray-500 text-sm">PILOTE  <span
                            className="font-bold uppercase flex gap-x-1 flex-wrap"><p>{props.workshop.former.firstName}</p><p>{props.workshop.former.lastName}</p></span> </span>
                        {props.workshop.copilotApprouved?.map((copilot: any) => (
                            <div className="ml-4">
                  <span className="text-ten leading-3 text-gray-600 font-bold block">
                    COPILOTE
                  </span>
                                <span className="text-thirteen text-gray-600">
                    {copilot.firstName}{" "}
                                    <span className="uppercase text-thirteen">
                      {copilot.lastName}
                    </span>
                  </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='flex flex-col'>
                    <h3 className="font-bold text-sm text-gray-800 block mb-1">INVITÉ(S)</h3>
                    <div className="flex mt-1">
                        {props.workshop.guests.filter(
                            (guest: any) => (guest.godFather?.id === id )
                        ).map((guest: any) => (
                            <div key={guest.id} className="flex-1 mr-2">
                                <span className="text-gray-500 text-sm">{guest.firstName} {guest.lastName}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {!isPaid ?
                dataUser?.credit < 5 ? (
                <>
                    <Button style={{zIndex: 1000000}} color="pink" onClick={() => setOpenModalPaypal(true)}>
                        <GiTicket size={18} className="text-white"/> <span
                        className="ml-3">Finalisez votre inscription de 5 €</span>
                    </Button>
                    <Modal title="Paiement par Paypal ou Carte Bancaire" opened={openModalPaypal} onClose={() => setOpenModalPaypal(false)}>
                        <ButtonWrapper
                            currency="EUR"
                            showSpinner={false}
                            amount={props.workshop?.price ?? 0}
                            itemName={`Journée Découverte parrain #${id}`}
                            Completed={() => {setIsPaid(true)
                                navigation(`/auth/workshop/detail/${props.workshop.id}`)}}
                            WorkshopId={parseInt(props.workshop.id)}
                            workshop={parseInt(props.workshop.id)}
                        />
                    </Modal>
                </>) :
                    (
                        <Button style={{zIndex: 1000000}} color="pink" onClick={() => handlePaid()}>
                            <GiTicket size={18} className="text-white"/> <span
                            className="ml-3">Finalisez votre inscription de 5 €</span>
                        </Button>
                    ) : (
                <Button color="green">
                <GiTicket size={18} className="text-white"/> <span
                className="ml-3">Inscription finalisée</span>
            </Button>
            )}
        </div>
    );
};

export default ItemJd;
