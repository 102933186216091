import { useForm } from "@mantine/hooks";
import { Button, TextInput, Col, LoadingOverlay, PasswordInput, SimpleGrid, Select, Text, Checkbox } from "@mantine/core";
import React, { useEffect, useState } from "react";
import usersApi from "../../api/users"
import useApi from "../../hooks/useApi";
import { useNotifications } from '@mantine/notifications';
import { Link, useNavigate } from "react-router-dom";
import authApi from "../../api/auth";
import { AuthToken } from "../../entity/AuthToken";
import useAuth from "../../auth/useAuth";
import { useModals } from "@mantine/modals";
import { CheckIcon } from "@heroicons/react/solid";
import { prefixTelephone } from "../../constants/telephonePrefix";
import illus_3 from "../../assets/images/illus-header2-1.png";
import illus_2 from "../../assets/images/illus-header1-3.png";
import illus_1 from "../../assets/images/illus-header1-1.png";
import logo from "../../assets/images/picto_FM.png";
import logo_fm_white from "../../assets/images/logo_FM-white.png";
import CountUp from "react-countup";
import { BiChevronLeft } from "react-icons/bi"
import { GrLinkTop } from "react-icons/gr";
import Storage from "../../auth/storage";
import userinformations from "../../api/userInformations"
import userRoleinformations from "../../api/userRoleInformations"
import illus_6 from "../../assets/images/logo_FM-white2.svg";
import Request from "../../api/request";
//@ts-ignore
import pdf from "../../assets/images/Politique.pdf"

export default function RegisterPage() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [nbAdhérents,setNbAdhérents] = useState(Number)
    const [loading, setLoading] = useState(false);
    const notifications = useNotifications();
    const modals = useModals();
    const navigation = useNavigate();
    const auth = useAuth();
    const { request } = useApi(
        usersApi.getUserByPhone
    );
    const { request: requestMan, data: dataMan } = useApi(
        usersApi.getUserManager
    );

    const { request: registerUser } = useApi(
        usersApi.register
    );

    const handleSubmit = async (values: any) => {
        setLoading(true)
        await request(values.phoneGodFather).then(async r => {
            if (r.data.length > 0) {   
                
                    const data = {
                        ...values,
                        idGodFather: r.data[0].id,
                        dynasty: r.data[0].dynasty != null ? r.data[0].dynasty : null 
                    };
                
                requestMan(r.data[0].id).then(async res => {
                    const registerUserData =  {
                        ...data,
                        manager: "api/users/"+res.data
                    };
                    const response = await registerUser(registerUserData);
                    console.log(response);
                    if (response.status === 422) {
                        response.data.violations.forEach((violation: any) => {
                            form.setFieldError(violation.propertyPath, true);
                            notifications.showNotification({
                                title: 'Une erreur est survenue.',
                                message: 'Votre ' + (violation.propertyPath === "phoneNumber" ? "Téléphone" : "Email") + ' est déjà présent dans la base ! 🤥',
                                color: "red"
                            })
                        });
                        setLoading(false)
                    }
                    if (response.status === 204) {
                        setLoading(false)
                        const response = await authApi.login(values.email, values.password);
                        // @ts-ignore
                        const token = new AuthToken(response?.data?.token, response?.data?.refresh_token)
                        await auth.logIn(token)
                        const user:any = Storage.getUser()
                        await userinformations.post(user.id)
                        await userRoleinformations.post(user.id)
                        if (auth.isAuthenticated()) {
                            navigation('/register/success', { replace: true })
                        }
                        setLoading(false)
                    }
                })
            }
            if (r.data.length === 0) {
                form.setFieldError('phoneGodFather', true);
                setLoading(false)
            }
        }
        );
    }
    useEffect(() => {
        Request.get('adherents/roles').then((res:any) => {
            setNbAdhérents(res.data.adherents)
        })
    }, []);
    // @ts-ignore
    function diff_years(dt2, dt1)
    {
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= (60 * 60 * 24);
        return Math.abs(Math.round(diff/365.25));
    }



    const firstLogin = () => {
        const id = modals.openModal({
            title: "REUNION D'INFORMATIONS",
            transition: "fade",
            transitionDuration: 600,
            transitionTimingFunction: "ease",
            children: (
                <>
                    <Text size="sm" className="my-5 text-center">
                        Votre inscription s'est parfaitement déroulée !
                        La personne qui vous a invité (votre parrain/marraine) a été prévenue de votre inscription.
                    </Text>
                    <CheckIcon className="flex mx-auto " color="#10B981" width="200" />
                    <Text size="sm" className="font-bold text-center mt-2">
                        VOUS ÊTES CONNECTÉ !
                    </Text>
                    <Text size="sm" className="text-center mb-5">
                        Ne perdez pas de temps, accèder au site pour suivre votre parcours de la réusite !
                    </Text>
                    <Button color="dark" fullWidth onClick={() =>
                        modals.closeModal(id)
                    }>
                        SÉLECTIONNER VOTRE RÉUNION D'INFORMATIONS
                    </Button>
                </>
            ),
        });
    };

    const form = useForm({
        initialValues: {
            civility: '',
            lastName: '',
            firstName: '',
            phoneNumber: '',
            email: '',
            confirm_email: '',
            password: '',
            confirm_password: '',
            lastNameGodFather: '',
            firstNameGodFather: '',
            phoneGodFather: '',
            asso: false,
            id: 0,
            telPrefix: prefixTelephone[0],
            telPrefix2: prefixTelephone[0],
        },

        validationRules: {
            civility: (value) => /^[A-Za-z]+$/.test(value) && value.length >= 1,
            lastName: (value) => value.length >= 1,
            firstName: (value) => value.length >= 1,
            phoneNumber: (value) => /^\d+$/.test(value) && value.length === 10,
            email: (value) => /^\S+@\S+$/.test(value),
            password: (value) => value.length >= 8,
            confirm_email: (confirm_email, values) => confirm_email === values?.email,
            confirm_password: (confirm_password, values) => confirm_password === values?.password,
            lastNameGodFather: (value) => value.length >= 1,
            firstNameGodFather: (value) => value.length >= 1,
            phoneGodFather: (value) => /^\d+$/.test(value) && value.length === 10,
            asso: (value) => value === true,
            telPrefix: (value) => value.length >= 1,
            telPrefix2: (value) => value.length >= 1,
        },
    });

    return (
        <>
            <div className="py-12 bg-white relative">

                <img src={illus_3} className="absolute hidden lg:block bottom-20 left-0 w-1/12"
                    alt="" />
                <img src={illus_1} className="absolute hidden lg:block top-40 right-2 w-1/12"
                    alt="" />
                <div className="md:relative max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="lg:text-center">
                        <img src={logo} className="w-2/6 md:w-1/12 mx-auto md:float-left" alt="" />
                        <p className="mt-2 text-lg md:text-2xl text-center tracking-tight text-gray-900 ">
                            Je m'inscris à ma première réunion d'information
                        </p>
                    </div>

                    <div className="flex md:absolute justify-center md:justify-start">
                        <Link to="/">
                            <Button className="uppercase mb-5 mt-5 md:mt-10 justify-center bg-gray-400" size="xs"
                                variant="filled">
                                <BiChevronLeft />Retour
                            </Button>
                        </Link>
                    </div>

                    <div className="mt-28">
                        <LoadingOverlay visible={loading} />
                        <form className="z-20" onSubmit={form.onSubmit((values) => handleSubmit(values))}>
                            <h2 className="text-2xl font-medium">MON IDENTITÉ</h2>
                            <SimpleGrid spacing="lg"
                                breakpoints={[
                                    { maxWidth: 980, cols: 2, spacing: 'md' },
                                    { maxWidth: 755, cols: 1, spacing: 'sm' },
                                    { maxWidth: 600, cols: 1, spacing: 'sm' },
                                ]} cols={2}>
                                <Col span={6}>
                                    <Select
                                        required
                                        placeholder="Civilité"
                                        error={form.errors.civility && 'Civilité contient une erreur !'}
                                        value={form.values.civility}
                                        data={[
                                            { value: 'Monsieur', label: 'Monsieur' },
                                            { value: 'Madame', label: 'Madame' },
                                        ]}
                                        onChange={(event: string) => form.setFieldValue('civility', event)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <TextInput
                                        required
                                        placeholder="Nom"
                                        error={form.errors.lastName && 'Nom invalide !'}
                                        value={form.values.lastName}
                                        onChange={(event) => form.setFieldValue('lastName', event.currentTarget.value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <TextInput
                                        required
                                        placeholder="Prénom"
                                        error={form.errors.firstName && 'Prénom invalide !'}
                                        value={form.values.firstName}
                                        onChange={(event) => form.setFieldValue('firstName', event.currentTarget.value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <div className="grid grid-cols-7 md:grid-cols-6 gap-4">
                                        <Select
                                            className="col-span-2 md:col-span-2"
                                            required
                                            placeholder="Prefix"
                                            data={prefixTelephone}
                                            error={form.errors.telPrefix && 'Prefix invalide !'}
                                            defaultValue="+33"
                                            value={form.values.telPrefix}
                                            onChange={(event: string) => form.setFieldValue('telPrefix', event)}
                                        />

                                        <TextInput className="col-span-5 lg:col-span-4"
                                            required
                                            placeholder="Téléphone"
                                            error={form.errors.phoneNumber && 'Téléphone invalide !'}
                                            value={form.values.phoneNumber}
                                            onChange={(event) => form.setFieldValue('phoneNumber', event.currentTarget.value)}
                                        />
                                    </div>
                                </Col>
                            </SimpleGrid>

                            <h2 className="text-2xl font-medium mt-10">MES INFORMATIONS DE CONNEXION</h2>
                            <p className="font-light mt-3 mb-3 md:mb-0 text-sm">Votre mot de passe doit inclure au minimum 8
                                caractères
                                dont au moins 1 lettre minuscule, 1 majuscule, 1 chiffre et 1 caractère spécial.</p>
                            <SimpleGrid spacing="lg"
                                breakpoints={[
                                    { maxWidth: 980, cols: 2, spacing: 'md' },
                                    { maxWidth: 755, cols: 1, spacing: 'sm' },
                                    { maxWidth: 600, cols: 1, spacing: 'sm' },
                                ]} cols={2}>
                                <Col span={6}>
                                    <TextInput
                                        required
                                        placeholder="Email"
                                        error={form.errors.email && 'Email déjà utilisé !'}
                                        value={form.values.email}
                                        onChange={(event) => form.setFieldValue('email', event.currentTarget.value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <PasswordInput
                                        placeholder="Mot de passe"
                                        error={form.errors.password && 'Mot de passe invalide !'}
                                        value={form.values.password}
                                        onChange={(event) => form.setFieldValue('password', event.currentTarget.value)}
                                        required
                                    />
                                </Col>
                                <Col span={6}>
                                    <TextInput
                                        required
                                        placeholder="Confirmation Email"
                                        error={form.errors.confirm_email && 'Confirm Email invalide !'}
                                        value={form.values.confirm_email}
                                        onChange={(event) => form.setFieldValue('confirm_email', event.currentTarget.value)}
                                    />
                                </Col>
                                <Col span={6}>
                                    <PasswordInput
                                        placeholder="Confirmation Mot de passe"
                                        error={form.errors.confirm_password && 'Mot de passe invalide !'}
                                        value={form.values.confirm_password}
                                        onChange={(event) => form.setFieldValue('confirm_password', event.currentTarget.value)}
                                        required
                                    />
                                </Col>
                                <Col span={6}>
                                    <p className="mt-2 text-2xl font-medium tracking-tight text-gray-900 sm:text-lg">
                                        INFORMATIONS SUR LA PERSONNE QUI M'A INVITÉ
                                    </p>
                                    <p className="mt-4 z-20 max-w-2xl text-base text-gray-500 lg:mx-auto">
                                        Pour permettre d'accéder à la prochaine page, merci de remplir les champs
                                        suivants.
                                    </p>
                                </Col>
                                <Col span={6}>
                                    <TextInput
                                        mt={5}
                                        required
                                        placeholder="Nom de la personne qui vous a invité"
                                        error={form.errors.lastNameGodFather && 'Nom invitant invalide !'}
                                        value={form.values.lastNameGodFather}
                                        onChange={(event) => form.setFieldValue('lastNameGodFather', event.currentTarget.value)}
                                    />
                                    <TextInput
                                        mt={5}
                                        required
                                        placeholder="Prénom de la personne qui vous a invité"
                                        error={form.errors.firstNameGodFather && 'Prénom invitant invalide !'}
                                        value={form.values.firstNameGodFather}
                                        onChange={(event) => form.setFieldValue('firstNameGodFather', event.currentTarget.value)}
                                    />
                                    <div className="grid grid-cols-7 md:grid-cols-6 gap-4">
                                        <Select
                                            className="col-span-2 md:col-span-2"
                                            mt={5}
                                            required
                                            data={prefixTelephone}
                                            error={form.errors.telPrefix2 && 'Prefix invalide !'}
                                            defaultValue="+33"
                                            value={form.values.telPrefix2}
                                            onChange={(event: string) => form.setFieldValue('telPrefix2', event)}
                                        />

                                        <TextInput
                                            mt={5}
                                            required
                                            className="col-span-5 lg:col-span-4"
                                            placeholder="Téléphone de la personne qui vous a invité"
                                            error={form.errors.phoneGodFather && 'Téléphone invitant invalide !'}
                                            value={form.values.phoneGodFather}
                                            onChange={(event) => {
                                                form.setFieldValue('phoneGodFather', event.currentTarget.value)
                                            }}
                                        />
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <Checkbox checked={form.values.asso}
                                        required={true}
                                        onChange={(event) => form.setFieldValue('asso', event.currentTarget.checked)}
                                        className="mt-5"
                                        label={
                                            <>
                                                <span>Je reconnais avoir pris connaissance de <a target="_blank" className="underline text-xs" href={pdf}>la politique de confidentialité </a>de l'Association FORMAN</span>
                                            </>
                                        } />
                                </Col>
                            </SimpleGrid>
                            <div className="flex">
                                <Button mt={5} mr={5} className="ml-auto hidden md:block" color="pink" type="submit">JE
                                    VALIDE</Button>
                                <Button mt={5} mr={5} className="ml-auto md:hidden bg-pink-400 block" fullWidth
                                    type="submit">JE VALIDE</Button>
                            </div>
                        </form>
                        <GrLinkTop className='mx-auto text-3xl mt-16 mb-8' />
                    </div>
                </div>
            </div>
            <div className="bg-gray-500 relative">
                <img src={illus_3} className="absolute top-0 transform left-0  lg:w-1/12 opacity-10" alt="" />
                <img src={illus_6}
                     className="absolute bottom-24 lg:bottom-10 transform right-12 lg:right-48 lg:w-46"
                     alt="" />
                <div className="container mx-auto p-5">
                    <div className="flex flex-col lg:flex-row items-center lg:items-start space-y-10 md:space-y-0 py-10">
                        <div className="flex-1">
                            <img className="w-1/2 mx-auto" src={logo_fm_white} alt="logo-forman" />
                        </div>
                        <div className="flex-1 mt-3 lg:mt-0 text-center lg:text-center">
                            <span className="text-white text-4xl font-bold">
                                <CountUp
                                    end={nbAdhérents}
                                    duration={2}
                                    className={"text-white text-4xl font-bold"}
                                /> +</span>
                            <br />
                            <span className="text-gray-200 text-sm">Adhérents</span>
                        </div>
                        <div className="flex-1 mt-3 lg:mt-0 text-center lg:text-center">
                            <span className="text-white text-4xl font-bold"><CountUp
                                end={diff_years(new Date(2009,9,10), new Date())}
                                duration={2}
                                className={"text-white text-4xl font-bold"}
                            /></span>
                            <br />
                            <span className="text-gray-200 text-sm">Ans</span>
                        </div>
                    </div>
                    <div className="flex flex-col lg:flex-row my-3 items-center lg:items-start justify-center">
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/contact">
                                Contactez-nous
                            </Link>
                        </div>
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100" to="/auth/cgu">
                                CGU
                            </Link>
                        </div>
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/cnil">
                                CNIL
                            </Link>
                        </div>
                        <div className="flex mx-1 mt-3">
                            <Link className="text-gray-100 hover:underline" to="/mentions-legales">
                                Mentions Légales
                            </Link></div>
                    </div>
                    <span
                        className=" flex justify-center text-gray-200 mb-5 text-xs">Développé par
                        <a className='mx-1' title='Agence Digitale Innovante » Solutions, applications, sites' target="_blank" href="https://www.appyness.fr/">Appyness</a>
                        et designé par
                        <a className='mx-1' title='Agence de création graphique et digitale à Bordeaux' target="_blank" href="https://caleis.fr">Caléis</a></span>
                </div>
            </div>
        </>
    )
}