import React, { useEffect } from "react";
import { Anchor, Breadcrumbs, LoadingOverlay, Table } from "@mantine/core";
import { Link } from "react-router-dom";
import storage from "../../../auth/storage";
import useApi from "../../../hooks/useApi";
import finishedGoals from "../../../api/finishedGoals";
import GoalNeedValidationItem from "../../../components/goals/GoalNeedValidationItem";

const ObjectifsScreen = () => {
  const { id }: any = storage.getUser();
  const { data, request } = useApi(finishedGoals.getGoalsNotValidatedLigne);

  useEffect(() => {
    request().then((res) => {
      console.log(res);
    });
  }, []);

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Objectifs", href: `/auth/adherents/profil/goals/${id}` },
    { title: "Notifications", href: `/auth/home` },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));
  return (
    <>
      <LoadingOverlay visible={false} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl ">
          <span className="font-bold text-xl">NOTIFICATIONS</span>
          <br />
          <span className="text-3xl">OBJECTIFS</span>
        </div>
        <div className="mt-6 md:mt-10 text-xl ">
          <span className=" text-2xl">
            LISTE DES OBJECTIFS ATTENDANT UNE VALIDATION
          </span>
        </div>
        <div className="mt-3 md:mt-3 text-xl ">
          {data?.length > 0 &&
            data.map((item: any) => <GoalNeedValidationItem user={item} />)}
        </div>
      </div>
    </>
  );
};

export default ObjectifsScreen;
