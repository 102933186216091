import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import workshop from "../../../api/workshop";
import CardCollection from "../../../components/formation/CardCollection";
import {
  Anchor,
  Breadcrumbs,
  Button,
  Checkbox,
  LoadingOverlay,
  Select,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { DateRangePicker } from "@mantine/dates";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import formation from "../../../api/formation";
import HasRoles from "../../../components/Security/hasRoles";
import { ROLE_DXMAN_MORE, ROLE_XMAN } from "../../../constants/roles";
import { ImCross, BsPlusLg } from "react-icons/all";
import Pagination from "@mui/material/Pagination";
import Footer from "../../Footer";
import thematic from "../../../api/thematic";
import storage from "../../../auth/storage";
import site from "../../../api/site";
import users from "../../../api/users";
import exportedStorageFilters from "../../../filters/storage";

const ListingFormation = () => {
  const { role }: any = storage.getUser();
  const { data, loading, request } = useApi(formation.collection);
  const { request: requestMe } = useApi(users.me);
  const {
    data: dataUser,
    loading: loadingUser,
    request: requestUser,
  } = useApi(users.infos);
  const [search, setSearch] = useState<string>("");
  const [searchPlace, setSearchPlace] = useState<string>("");
  const [finalDate, setFinalDate] = useState<any>([0, 999999999999999999]);
  const [copilot, setCopilot] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const [thematics, setThematics] = useState([]); //petite erreur, les "thématiques" sont les "titres"
  const [page, setPage] = useState<number>(1);
  const [sites, setSites] = useState([]);
  const perPage = 10;
  const ResetSearch = () => {
    setSearch("");
    setSearchPlace("");
    setDate(null);
  };
  const { request: requestThematics, data: dataThematics } = useApi(
    thematic.collection,
  );
  const { request: requestSites, data: dataSites } = useApi(site.collection);

  const [date, setDate] = useState<any>(null);
  const [filters, setFilters] = useState(false);

  useEffect(() => {

    if(search && search.length > 0){
      exportedStorageFilters.storeTitleF(search);
    }else{
      if (isRemove){
        exportedStorageFilters.removeTitleF()
      }
    }
    if(searchPlace && searchPlace.length > 0){
      exportedStorageFilters.storePlaceF(searchPlace);
    }else{
      if (isRemove){
        exportedStorageFilters.removePlaceF()
      }
    }
    if (date !== null && date[0] !== null && date[1] !== null) {
      exportedStorageFilters.storeDateF({date1 : date[0], date2: date[1]});
    }else{
      if (isRemove){
        exportedStorageFilters.removeDateF()
      }
    }
  },[search,searchPlace, date])

  // number of character displayed in description

  // ts-ignore
  useEffect(() => {
    requestMe().then((responseUserMe) => {
      requestUser(responseUserMe.data?.id).then((responseUser) => {
        const habilitation = responseUser.data?.userInformations
          ? responseUser.data?.userInformations.habilitation
          : [];

        request(role[0], habilitation, responseUserMe?.data?.endCursus);
        requestThematics("FORMATION").then((value) => {
          setThematics(
            value.data.map((thematic: any) => {
              return {
                value: thematic.wording,
                label: thematic.wording,
              };
            }),
          );
        });
        requestSites().then((value) => {
          setSites(
            value.data.map((site: any) => {
              return {
                label: site.name,
                value: "" + site.id,
              };
            }),
          );
        });
      });
    });
    let storage = exportedStorageFilters.getF()
    console.log(storage)
    if(storage){
      if(storage.title){
        setSearch(storage.title);
      }
      if(storage.place){
        setSearchPlace(storage.place);
      }
      if(storage.date1 && storage.date2){
        setDate([new Date(storage.date1), new Date(storage.date2)]);
      }
    }
    setIsRemove(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (date !== null && date[0] !== null && date[1] !== null) {
      setFinalDate([date[0].getTime(), date[1].getTime()]);
    }
    if (date && date[0] == null && date[1] == null) {
      setFinalDate([0, 999999999999999999]);
    }
  }, [date]);

  //filter workshops by thematic
  const filteredWorkshopByThematic = data.filter(function (w: any) {
    if (w.site == null) {
      if (copilot) {
        return (
          w &&
          w.title.toLowerCase().includes(search.toLowerCase()) &&
          w &&
          new Date(w.startDate).getTime() >= finalDate[0] &&
          new Date(w.endDate).getTime() <= finalDate[1] &&
          w.wantCopilot == true
        );
      } else {
        return (
          w &&
          w.title.toLowerCase().includes(search.toLowerCase()) &&
          new Date(w.startDate).getTime() >= finalDate[0] &&
          new Date(w.endDate).getTime() <= finalDate[1]
        );
      }
    }

    if (w.site != null) {
      if (copilot) {
        return (
          w &&
          w.title.toLowerCase().includes(search.toLowerCase()) &&
          w &&
          w.site?.id?.toString().includes(searchPlace) &&
          w &&
          new Date(w.startDate).getTime() >= finalDate[0] &&
          new Date(w.endDate).getTime() <= finalDate[1] &&
          w.wantCopilot == true
        );
      } else {
        return (
          w &&
          w.title.toLowerCase().includes(search.toLowerCase()) &&
          w &&
          w.site?.id?.toString().includes(searchPlace) &&
          w &&
          new Date(w.startDate).getTime() >= finalDate[0] &&
          new Date(w.endDate).getTime() <= finalDate[1]
        );
      }
    }
  });

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Formations", href: "/auth/formation" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  return (
    <>
      <LoadingOverlay visible={loading} />
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl">
          {filteredWorkshopByThematic.length < 2 ? "FORMATION" : "FORMATIONS"}
          <Link to="/auth/formation/create">
            <HasRoles role={ROLE_DXMAN_MORE}>
              <Button className="ml-5 bg-gray-500 rounded" size="sm" compact>
                Créer
              </Button>
            </HasRoles>
          </Link>
        </div>
        <div className="md:max-w-3xl mt-3 md:my-6 md:pt-8 relative">
          <p className="text-sm md:text-md mr-6">
            Bienvenue sur la page Formations. L'association vous permet de poursuivre votre professionnalisation : choisissez les modules qui vous intéressent et inscrivez-vous ! Nos formateurs bénévoles seront ravis de partager leur savoir.
          </p>
        </div>

        {/* MOBILE FILTER */}
        <div className="flex justify-end mt-8 md:hidden">
          <button
            onClick={() => setFilters(!filters)}
            className="flex bg-gray-500 px-2 py-1 rounded text-white text-sm"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
              />
            </svg>
          </button>
        </div>

        {filters ? (
          <div className="bg-gray-50 p-2 shadow-md mt-2 mb-4 rounded md:hidden">
            <div className="flex-1 m-2 relative">
              <Select
                  searchable
                  nothingFound="No options"
                clearable
                placeholder="Sélectionner un titre"
                data={[...thematics]}
                value={search}
                onChange={(value) => setSearch(value === null ? "" : value)}
              />
            </div>
            <div className="flex-1 m-2">
              <Select
                  searchable
                  nothingFound="No options"
                clearable
                placeholder="Sélectionner un lieu"
                data={[...sites]}
                value={searchPlace}
                onChange={(value) =>
                  setSearchPlace(value === null ? "" : value)
                }
              />
            </div>
            <div className="flex-1 m-2">
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                locale="fr"
                clearable
                placeholder="Sélectionner une période"
                value={date}
                onChange={(value) => setDate(value)}
              />
            </div>
            <div className="flex justify-end p-2">
              <Checkbox
                size="xs"
                className="text-xs"
                checked={copilot}
                onChange={() => setCopilot(!copilot)}
                label="Formation qui recherche un copilote"
              />
            </div>
            <div className="flex justify-end px-2">
              <button className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2">
                Tout afficher ({filteredWorkshopByThematic.length})
              </button>
            </div>
          </div>
        ) : null}
        {/* END MOBILE FILTER */}

        {/* DESKTOP FILTER */}
        <div className="md:flex justify-between hidden">
          <div className="flex flex-wrap p-3 pr-0">
            <div className="flex">
              <div className="m-2">
                <Select
                    searchable
                    nothingFound="No options"
                  style={{ minWidth: 185 }}
                  clearable
                  placeholder="Sélectionner un titre"
                  data={[...thematics]}
                  value={search}
                  onChange={(value) => setSearch(value === null ? "" : value)}
                />
              </div>
              <div className="m-2">
                <Select
                    searchable
                    nothingFound="No options"
                  clearable
                  placeholder="Sélectionner un lieu"
                  data={[...sites]}
                  value={searchPlace}
                  onChange={(value) =>
                    setSearchPlace(value === null ? "" : value)
                  }
                />
              </div>
            </div>
            <div className="m-2">
              <DateRangePicker
                inputFormat="DD/MM/YYYY"
                labelFormat="MM/YYYY"
                locale="fr"
                clearable
                placeholder="Sélectionner une période"
                value={date}
                onChange={(value) => setDate(value)}
              />
            </div>
          </div>
          <div className="m-2">
            <button
              onClick={ResetSearch}
              className="bg-gray-500 text-white text-xs rounded my-3 px-2 py-2"
              style={{ width: 120 }}
            >
              Tout afficher ({filteredWorkshopByThematic.length})
            </button>
          </div>
        </div>
        {/* END OF DESKTOP FILTER */}

        <div className="md:flex-1 hidden mx-2 mb-6">
          <Checkbox
            className="text-xs"
            checked={copilot}
            onChange={() => setCopilot(!copilot)}
            label="Formation qui recherche un copilote"
          />
        </div>
        <div className="mt-2 md:mt-8 md:m-2">
          {filteredWorkshopByThematic.length ? (
            filteredWorkshopByThematic
              .slice(page * perPage - perPage, perPage * page)
              .map((item: any) => <CardCollection data={item} />)
          ) : (
            <span>Aucune Formation n'a été trouvé</span>
          )}
        </div>
        {filteredWorkshopByThematic.length > perPage ? (
          <div className="flex justify-center md:justify-end p-4">
            <Pagination
              count={Math.ceil(filteredWorkshopByThematic.length / perPage)}
              page={page}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              color="primary"
              hidePrevButton
              hideNextButton
              showFirstButton
              showLastButton
            />
          </div>
        ) : null}

        <Footer />
      </div>
    </>
  );
};

export default ListingFormation;
