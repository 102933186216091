import useApi from "../../hooks/useApi"
import settings from "../../api/settings"
import { useEffect, useState } from "react";
import { Button, NumberInput,TextInput } from "@mantine/core";
import {useForm} from "@mantine/hooks";

const AdminSettings = () => {

    const {data : dataTva , request: requestTva } = useApi(settings.getTva);
    const {request: requestEdit} = useApi(settings.edit)
    const id = 1; 
    const [dataTvaSelected , setTvaSelected] = useState<number>(); 
    
    useEffect(() => {
        requestTva(id).then(r => {
            console.log('response', r); 
        });

    }, []);

    const handleSubmit = () => {
        requestEdit(id,dataTvaSelected)
        
    }         
    return (
        <> 
            <form className="w-30">
                <NumberInput
                label="TVA"
                placeholder="TVA"
                precision={2}
                min={0}
                step={0.5}
                value={dataTvaSelected}
                onChange={(value:number) => setTvaSelected(value)}
                />
                <Button onClick={handleSubmit} type="submit" className="m-2">Valider La nouvelle TVA</Button>
            </form>
        </>
    )
}   

export default AdminSettings;








