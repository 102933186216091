import React, { useEffect, useState } from "react";
import useApi from "../../../hooks/useApi";
import {
  Anchor,
  Breadcrumbs,
  Button,
  LoadingOverlay,
  Transition,
} from "@mantine/core";
import { Link } from "react-router-dom";
import Footer from "../../Footer";
import storage from "../../../auth/storage";
import { ImCross, BsPlusLg } from "react-icons/all";
import { ROLE_XMAN } from "../../../constants/roles";
import HasRoles from "../../../components/Security/hasRoles";
import faq from "../../../api/faq";

const ListingFAQ = () => {
  const { request, data } = useApi(faq.getSections);

  // number of character displayed in description
  const [nbrCharacters, setNbrCharacters] = useState(true);
  const truncate = (text: string) => {
    if (nbrCharacters) {
      return text.substring(0, 60) + "... (cliquez sur + pour voir la suite)";
    }
    return text;
  };

  const [displayButton, setDisplayButton] = useState(false);

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "FAQ", href: "/auth/FAQ" },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));

  window.onscroll = () => {
    //@ts-ignore
    if (window.scrollY > document.getElementById("SubmitFix")?.offsetTop + document.getElementById("SubmitFix")?.offsetHeight
    ) {
      setDisplayButton(true);
    } else {
      setDisplayButton(false);
    }
  };

  useEffect(() => {
    request();
  }, []);

  return (
    <>
      {/* <LoadingOverlay visible={loading} /> */}
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <Breadcrumbs separator="/" styles={{ separator: { color: "#333" } }}>
          {items}
        </Breadcrumbs>
        <div className="mt-6 md:mt-10 text-3xl">FAQ</div>
        <div className="flex justify-between mb-5">
          <div className="md:max-w-3xl mt-3 md:my-6 relative">
            {nbrCharacters ? (
              <BsPlusLg
                onClick={() => setNbrCharacters(!nbrCharacters)}
                className="absolute right-0 top-0 h-3 w-3 font-extrabold text-gray-600 hover:text-gray-800 cursor-pointer"
              />
            ) : (
              <ImCross
                onClick={() => setNbrCharacters(!nbrCharacters)}
                className="absolute right-0 top-0 h-3 w-3 font-extrabold text-gray-600 hover:text-gray-800 cursor-pointer"
              />
            )}
            <p className="text-sm md:text-md mr-6">
              {truncate(
                "Vous avez une question sur l'association ? Voici quelques réponses, dans lesquelles vous trouverez peut-être votre bonheur. Si ce n'est pas le cas, tournez-vous vers votre parrain, et sinon contactez-nous !",
              )}
            </p>
          </div>
          <div id="SubmitFix" className="ml-5 flex justify-center md:block">
            <HasRoles role={ROLE_XMAN}>
              <Link to="/auth/ContactApp">
                <Button
                  mt={20}
                  mr={5}
                  className="px-6"
                  size="md"
                  color="pink"
                  type="button"
                >
                 Proposer une question / et sa réponse
                </Button>
              </Link>
            </HasRoles>
          </div>
        </div>
        <div className="flex flex-wrap justify-between">
          {data.length ? (
            data.map((section: any) => (
              <div className="flex-1 mr-10 mt-5" style={{ minWidth: 350 }}>
                <h2 className="text-xl">{section.title}</h2>
                <div
                  className="bg-white rounded mt-4"
                  style={{ boxShadow: "0px 1px 6px #8E8E8E59" }}
                >
                  {section.subCategories.length ? (
                      section.subCategories.map((category: any, index: number) => (
                      <Link to={"/auth/FAQ/category/" + category.id}>
                        <h4
                          className="hover:bg-gray-50 duration-150 flex-wrap"
                          style={{ padding: "12px 14px" }}
                        >
                          {index+1}. {category.title}
                        </h4>
                      </Link>
                    ))
                  ) : (
                    <div className="w-full flex items-center p-4">
                      <div className="w-full text-left text-sm font-medium text-gray-600">
                        Aucune sous catégorie n'a été trouvé à cette catégorie
                        de FAQ
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div className="w-full flex items-center p-4">
              <div className="w-full text-left text-sm font-medium text-gray-600">
              Vous n'avez pas trouvé votre réponse ? Contactez nous 
              </div>
            </div>
          )}
        </div>
        <div
          className={`hidden md:${
            displayButton ? "block" : "hidden"
          } flex justify-end mb-5`}
        >
          <HasRoles role={ROLE_XMAN}>
            <Link to="/auth/ContactApp">
              <Button
                mt={20}
                mr={5}
                className="px-6"
                size="md"
                color="pink"
                type="button"
              >
                Proposer une question / et sa réponse
              </Button>
            </Link>
          </HasRoles>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default ListingFAQ;
