import React, {useEffect, useState} from 'react';
import {ROLE_GUEST} from "../../../constants/roles";
import HomeCardWorkshop from "../../workshop/HomeCardWorkshop";
import {Link} from "react-router-dom";
import {Button} from "@mantine/core";
import HasRoles from "../../Security/hasRoles";

interface IProps {
    datas: any[],
    title: string,
    type: string,
    link: string,
    button?: boolean
    children?: any,
    isGuest?: boolean
}

const Card = (props: IProps) => {
    const [datas, setDatas] = useState(props.datas);
    console.log('datas',datas);
    
    useEffect(() => {
        setDatas(props.datas);
    }, [props.datas]);

    return (
        <div className="w-full p-3" style={{minWidth:240}}>
            <h2 className="uppercase text-xl text-left mb-5">{props.title}</h2>
            <div className="rounded overflow-hidden "
                 style={{boxShadow: "0px 1px 6px #8E8E8E59"}}>
                {datas.length  ? (
                        datas.map((workshop: any) => (
                            <HomeCardWorkshop data={workshop} className="bg-orange-50 border border-b-2 hover:bg-white duration-150"/>
                        ))
                    )
                    :
                    <div className="w-full flex items-center p-4">
                        <div className="w-full text-left text-sm font-medium text-gray-600">
                            {props.isGuest ? "Adhérez pour voir les Atelier" : `Aucun ${props.type} trouvé`}
                        </div>
                    </div>
                }
            </div>
            {props.button &&(
            <Button color="blue" size="xs" className="float-left my-5 text-ten"
                    style={{background: "#256094"}}>
                Plan de formation personnalisé
            </Button>
            )}
            <div className="flex justify-end my-5">
                <Link to={props.link}>
                    <Button color="gray" size="xs" className="text-ten">
                        Voir tous les {props.type}
                    </Button>
                </Link>
            </div>
            {props.children}
        </div>
    );
};

export default Card;
