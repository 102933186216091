import { Button, MultiSelect, Select } from '@mantine/core';
import { useEffect, useState } from 'react';
import thematic from '../../../api/thematic';
import useApi from '../../../hooks/useApi';
import dynasty from '../../../api/dynasty';
import users from '../../../api/users';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ImCross, BsPlusLg } from 'react-icons/all';
import Footer from '../../Footer';
import storage from '../../../auth/storage';

const TrainingPlan = () => {
  const { data: dataDynasty, request: requestDynasty } = useApi(
    dynasty.getDynasty
  );

  const { data: dataUserInfo, request: requestUserInfo } = useApi(users.infos);

  const user: any = storage.getUser();
  const { id } = useParams();
  const navigation = useNavigate();
  const { request: requestThematics, data: dataThematics } = useApi(
    thematic.collection
  );
  const [thematics, setThematics] = useState([]);
  const [thematicSelected, setThematicSelected] = useState([]);

  useEffect(() => {
    requestUserInfo(id).then((r) => {
      requestThematics('WORKSHOP').then((value) => {
        setThematics(
          value.data.map((thematic: any) => {
            return {
              label: thematic.wording,
              value: thematic.id,
            };
          })
        );
      });
      requestDynasty(1);
    });
  }, []);

  const HandleClick = (user: any, thematic: any) => {
    //console.log("thematic",thematic);

    users.advise_thematic(user, thematic).then((res) => {
      navigation('/auth/home', { replace: true })
    });
  };
  const [nbrCharacters, setNbrCharacters] = useState(true);
  const truncate = (text: string) => {
    if (nbrCharacters) {
      return text.substring(0, 60) + '... (cliquez sur + pour voir la suite)';
    }
    return text;
  };
  return (
    <>
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <h1 className=" text-3xl uppercase">Plan De Formation Personnalisé</h1>
        <div className="md:max-w-3xl mt-3 md:my-6 relative">
          {nbrCharacters ? (
            <BsPlusLg
              onClick={() => setNbrCharacters(!nbrCharacters)}
              className="absolute right-0 top-0 h-3 w-3 font-extrabold text-gray-600 hover:text-gray-800 cursor-pointer"
            />
          ) : (
            <ImCross
              onClick={() => setNbrCharacters(!nbrCharacters)}
              className="absolute right-0 top-0 h-3 w-3 font-extrabold text-gray-600 hover:text-gray-800 cursor-pointer"
            />
          )}
          <p className="text-sm md:text-md mr-6">
            {truncate(
              "Vous avez la possibilité de suggérer à votre filleul certains ateliers, formations, évènements, réunions d'équipe : suivez le guide !"
            )}
          </p>
        </div>

        <h2 className="uppercase m-2">
          plan de formation Pour <span></span>
          {dataUserInfo.firstName} {dataUserInfo.lastName}{' '}
        </h2>

        <Link className="m-2" to={`/auth/adherents/profil/${id}`}>
          <Button
            color="blue"
            size="xs"
            className="text-ten mb-2 lg:mb-0"
            style={{ background: '#256094' }}
          >
            Voir le profil
          </Button>
        </Link>
        <Link to={`/auth/adherents/profil/goals/"${id}`}>
          <Button
            color="blue"
            size="xs"
            className="text-ten mb-2 lg:mb-0"
            style={{ background: '#256094' }}
          >
            Voir ses objectifs
          </Button>
        </Link>

        <MultiSelect
          className="m-2 w-96"
          label="Selectionnez les thématiques que vous lui conseillez"
          data={[...thematics]}
          placeholder="Sélectionner les thématiques"
          nothingFound="Aucun résulat"
          clearable
          onChange={(value: []) => setThematicSelected(value)}
        />
        <Button
          style={{ background: '#256094' }}
          className="text-ten m-2 lg:mb-0"
          onClick={() => HandleClick(id, thematicSelected)}
        >
          Valider
        </Button>
        <Footer></Footer>
      </div>
    </>
  );
};

export default TrainingPlan;
