import ItemFormation from '../../../../templates/Connected/Formation/ItemFormation';

const FormationPaymentSimulation = () => {

    return (
        <div>
            <ItemFormation />
        </div>
    )

}

export default FormationPaymentSimulation;
