import request from "./request";

const endpoint = "/formation_dates";

const create = (dateAt : any, formation : any, endAt : any) => {
  return request.post(endpoint, {
      dateAt : dateAt,
      formation : "/api/formations/" + formation,
      endAt : endAt,
  });
};



export default {
  create
};