import {ROLE_WITHOUT_TOUS} from "../constants/roles";

const nextRole = (currentRole : string) => {
    const index = ROLE_WITHOUT_TOUS.indexOf(currentRole);
    if(index >= 0 && index < ROLE_WITHOUT_TOUS.length - 1){
        return ROLE_WITHOUT_TOUS[index + 1];
    }
}


export default nextRole;