import axios, { AxiosInstance } from 'axios';
import { 
    REACT_APP_EMAIL_SERVICE_BASE_URL, 
    REACT_APP_EMAIL_SERVICE_API_KEY 
} from "../env";

interface IEmail {
    sender: {
        name: string;
        email: string;
    };
    to: {
        name: string;
        email: string;
    }[];
    subject: string;
    htmlContent: string;
    attachment?: { content: string; name: unknown }[];
}

export class EmailService {
    private _client!: AxiosInstance;

    constructor() {
        this.init();
    }

    public async sendEmail(payload: { attachment?: { name: string; content: unknown }[]; sender: { name: string; email: string }; subject: string; to: { name: string; email: string }[]; htmlContent: string }) {
        return (await this._client.post('smtp/email', payload)).data;
    }

    private init() {
        if (!this._client) {
            this._client = axios.create({
                baseURL: REACT_APP_EMAIL_SERVICE_BASE_URL,
                headers: {
                    'api-key': REACT_APP_EMAIL_SERVICE_API_KEY,
                },
            });
        }
        return this._client;
    }
}