import useApi from "../../../hooks/useApi";
import users from "../../../api/users";
import { useEffect, useState } from "react";
import storage from "../../../auth/storage";
import { Link, useParams } from "react-router-dom";
import workshop from "../../../api/workshop";
import CardCollection from "../../../components/workshop/CardCollection";
import { Anchor, Breadcrumbs, Button } from "@mantine/core";
const TraningPlanUser = () => {
  const { id } = useParams();
  const { data: dataUser, request: requestUser } = useApi(users.infos);
  const { data: dataworkshop, request: resquestWorkshop } = useApi(
    workshop.getWorkshopByTitle,
  );
  const { request: requestSendMail } = useApi(users.sendMailTrainingPlan);
  const [title, seTitle] = useState<any>([]);
  const [isSend, setIsSend] = useState<Boolean>(false);

  const handleCLick = () => {
    requestSendMail();
    setIsSend(true);
  };
  useEffect(() => {
    requestUser(id).then((r) => {
      const tab = [];
      for (let i = 0; i < r.data.adviseThematic.length; i++) {
        tab.push(r?.data?.adviseThematic[i]?.wording);
      }

      seTitle(tab);
      resquestWorkshop(...tab);
    });
  }, []);

  const items = [
    { title: "Accueil", href: "/auth/home" },
    { title: "Mon Profil", href: `/auth/adherents/profil/${id}` },
    {
      title: `Plan Formation ${dataUser?.firstName} ${dataUser?.lastName}`,
      href: `/auth/TrainingPlanUser/${id}`,
    },
  ].map((item, index) => (
    <Anchor
      component={Link}
      to={item.href}
      key={index}
      style={{ color: "#333", fontSize: 14 }}
    >
      {item.title}
    </Anchor>
  ));
  return (
    <>
      <Breadcrumbs
        className="m-2"
        separator="/"
        styles={{ separator: { color: "#333" } }}
      >
        {items}
      </Breadcrumbs>
      <div className="w-screen md:w-auto px-6 py-2 md:p-12">
        <h1 className="uppercase text-2xl">
          Mon Plan de Formation personnalisé :
        </h1>
        <div className="mt-2 md:mt-8 md:m-2">
          {dataworkshop.length > 0 ? (
            dataworkshop.map((workshop: any) => (
              <CardCollection data={workshop} />
            ))
          ) : (
            <div className="flex flex-col">
              <span>Aucun Plan De Formation conseiller,</span>
              <span>Vous pouvez le demander a votre Parrain </span>
              {isSend === false ? (
                <Button
                  onClick={handleCLick}
                  color="blue"
                  size="xs"
                  className="text-ten mb-2 lg:w-96 lg:mb-0 mb:w-30 "
                  style={{ background: "#256094" }}
                >
                  Demandez plan de formation personnalisé
                </Button>
              ) : (
                <Button
                  disabled
                  color="gray"
                  size="xs"
                  className="text-ten mb-2 lg:mb-0"
                  style={{ background: "#808080" }}
                >
                  En Attente plan de formation personnalisé
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TraningPlanUser;
