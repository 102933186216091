import request from "./request";

const endpoint = "/goal_finisheds";

const post = (goal: any) => {
    return request.post(endpoint, {
        goal: "/api/goals/"+goal.goalId,
        user: "/api/users/"+goal.userId,
        status: "EN ATTENTE",
        validatedAt : null
    });
};

const validate = (goal: any) => {
    return request.put(endpoint+"/"+goal.id, {
        goal: "/api/goals/"+goal.goalId,
        user: "/api/users/"+goal.userId,
        status: "VALIDÉ",
        validatedAt : goal.validatedAt
    });
};

const refuse = (goal: any) => {
    return request.put(endpoint+"/"+goal.id, {
        goal: "/api/goals/"+goal.goalId,
        user: "/api/users/"+goal.userId,
        status: "REFUSÉ",
        validatedAt : goal.validatedAt
    });
};

const deleteGoal = (id: number) => {
    return request.delete(endpoint + "/" + id);
}

const getByUserId = (userId: Number) => {
    return request.get(endpoint + "?user=" + userId);
}

const getByGoalIdAndUserId = (goalLevel: string, userId: Number) => {
    return request.get(endpoint + "?goal.level=" + goalLevel + "&user=" + userId);
}

const getByGoalIdAndUserIdAndStatus = (goalLevel: string, userId: Number, status: string = "VALIDÉ") => {
    return request.get(endpoint + "?goal.level=" + goalLevel + "&user=" + userId + "&status=" + status);
}

const getGoalsNotValidatedLigne = () => {
    return request.get(endpoint+"/ligne" );
}

const exportedApiUsers = {
    post, getByUserId,getByGoalIdAndUserId, validate, refuse, deleteGoal, getByGoalIdAndUserIdAndStatus, getGoalsNotValidatedLigne
}

export default exportedApiUsers;