import ItemEvent from '../../../../templates/Connected/Event/ItemEvent';

const EventPaymentSimulation = () => {

    return (
        <div>
            <ItemEvent />
        </div>
    )

}

export default EventPaymentSimulation;
