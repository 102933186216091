import React, { useEffect, useRef, useState } from 'react';
import { ActionIcon, Button } from '@mantine/core';
import {
  AiOutlineSearch,
  BsArrowRight,
  FaUserAlt,
  ImArrowDownRight2,
  ImArrowRight2,
  ImArrowUpRight2,
  IoRefreshSharp,
} from 'react-icons/all';
import nextRole from '../../utility/nextRole';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import probatoire from '../../utility/probatoire';
import useApi from '../../hooks/useApi';
import goals from '../../api/goals';
import finishedGoals from '../../api/finishedGoals';
import evolutionApi from '../../api/evolution';
import users from "../../api/users";


interface Props {
  evolution: any;
  user: any;
  onEnd: (id: number) => void;
}

const Item = (props: Props) => {
  const [evolution, setEvolution] = useState(props.evolution);
  const {data, loading: loadingUser, request} = useApi(users.infos);
  const [isGodFatherDevmanOrMore, setIsGodFatherDevmanOrMore] = useState(false);
  const [godFatherRole, setGodFatherRole] = useState("");
  const [godFather, setGodFather] = useState();
  const {
    data: dataGoals,
    loading: loadingGoals,
    request: requestGoal,
  } = useApi(goals.getByLevel);
  const {
    data: dataFiniGoals,
    loading: loadingFiniGoals,
    request: requestFiniGoal,
  } = useApi(finishedGoals.getByGoalIdAndUserIdAndStatus);
  const navigation = useNavigate();
  const [goalsResults, setGoalsResults] = useState({ main: 0, up: 0 });
  const [goalsFiniResults, setGoalsFiniResults] = useState({ main: 0, up: 0 });
  const [result, setResult] = useState<any>();

  
const isDevOrMore = props.user.roles == 'ROLE_DEVMAN' || props.user.roles == 'ROLE_DXMAN' || props.user.roles == 'ROLE_XMAN';

  const role = nextRole(evolution?.user?.roles[0]);
  const index = probatoire.indexOfMonth(evolution?.user?.roles[0]);
  const dateProbatoire = probatoire.getDateProbatoire(
    evolution?.user?.roles[0],
    new Date(
      Date.parse(
        evolution?.user?.userRoleInformation &&
          evolution?.user?.userRoleInformation[probatoire.ROLE_DATE[index]]
      )
    )
  );

  useEffect(() => {
    requestGoal(evolution?.user?.roles[0]);
    requestFiniGoal(evolution?.user?.roles[0], evolution?.user?.id, 'VALIDÉ');
    request(evolution?.user?.godFather.slice(11));
  }, []);
  

  useEffect(() => {
    if(data && data.id){
      setGodFather(data?.id ?? 0);
      setGodFatherRole(data?.roles[0]);
      setIsGodFatherDevmanOrMore(data?.roles[0] == 'ROLE_DEVMAN' ||
      data?.roles[0] == 'ROLE_DXMAN' ||
      data?.roles[0] == 'ROLE_XMAN');
    }

  }, [data]);

  useEffect(() => {
    if(godFather){
      //console.log(props.user.id == godFather, evolution.user.firstName)
    }

  }, [godFather]);
  

  useEffect(() => {
    if (!loadingGoals) {
      setGoalsResults({
        main: dataGoals?.filter(
          (goal: any) => goal.type.toLowerCase() === 'maintien'
        )?.length,
        up: dataGoals?.filter(
          (goal: any) => goal.type.toLowerCase() === 'promotion'
        )?.length,
      });
    }
  }, [dataGoals]);

  useEffect(() => {
    if (!loadingFiniGoals) {
      setGoalsFiniResults({
        main: dataFiniGoals?.filter(
          (goal: any) => goal.goal.type.toLowerCase() === 'maintien'
        )?.length,
        up: dataFiniGoals?.filter(
          (goal: any) => goal.goal.type.toLowerCase() === 'promotion'
        )?.length,
      });
    }
  }, [dataFiniGoals]);

  const handleVote = async (
    voteParrain: number | null,
    voteDevman: number | null,
    voteXman: number | null
  ) => {
    await evolutionApi
      .put(evolution.id, voteParrain, voteDevman, voteXman)
      .then((result) => {
        setEvolution(result.data);
      });
  };

  const handleClose = async (
    voteParrain: number | null,
    voteDevman: number | null,
    voteXman: number | null
  ) => {
    await evolutionApi
      .end(evolution.id, voteParrain, voteDevman, voteXman)
      .then((result: any) => {
        setEvolution(result.data);
        props.onEnd(result.data.id);
      });
  };

  return (
    <div
      key={evolution.id}
      className="flex flex-wrap justify-between bg-white rounded mt-5 shadow gap-4 p-5"
    >
      <div className="flex flex-col gap-2">
        <div className="inline-flex">
          <span>
            {evolution.user.firstName}{' '}
            <span className="uppercase">{evolution.user.lastName}</span>
          </span>
          <Button className="ml-3" size="xs" color="gray">
            {evolution.user.roles[0] == 'ROLE_XMAN' ? (
              evolution.user?.roles[0].substring(5)
            ) : (
              <>
                {evolution.user?.roles[0].substring(5)}
                <BsArrowRight className="mx-2 text-gray-200" />{' '}
                {role?.substring(5)}
              </>
            )}
          </Button>
          <ActionIcon
            onClick={() =>
              navigation('/auth/adherents/profil/' + evolution.user.id, {
                replace: true,
              })
            }
            color="blue"
            className="ml-3 bg-blue-800"
            variant="filled"
          >
            <FaUserAlt size={15} className="text-white" />
          </ActionIcon>
        </div>
        <div className="flex flex-wrap gap-2">
          <span className="text-gray-500 text-sm flex gap-1">
            Fin période probatoire
            <span className="font-bold">
              {dateProbatoire
                ? dayjs(dateProbatoire).locale('fr').format('DD ') +
                  dayjs(dateProbatoire)
                    .locale('fr')
                    .format('MMMM YYYY')
                    .charAt(0)
                    .toUpperCase() +
                  dayjs(dateProbatoire)
                    .locale('fr')
                    .format('MMMM YYYY')
                    .slice(1)
                : 'Aucune donnée disponible'}
            </span>
          </span>
          <span className="text-gray-500 text-sm flex gap-1">
            Raison vote
            <span className="font-bold">{evolution.raison}</span>
          </span>
        </div>
        <div className="inline-flex flex-wrap gap-y-2 gap-x-4">
          <span className="text-gray-500 text-xs bg-gray-200 rounded p-2 flex gap-1">
            OBJECTIFS MAINTIEN
            <span className="font-bold">
              {goalsFiniResults.main}/{goalsResults.main}
            </span>
          </span>
          <span className="text-gray-500 text-xs rounded bg-gray-200 p-2 flex gap-1">
            OBJECTIFS PROMOTION
            <span className="font-bold">
              {goalsFiniResults.up}/{goalsResults.up}
            </span>
          </span>
          <ActionIcon
            onClick={() =>
              navigation('/auth/adherents/profil/goals/' + evolution.user.id, {
                replace: true,
              })
            }
            color="blue"
            className="bg-blue-800"
            variant="filled"
          >
            <AiOutlineSearch size={18} className="text-white" />
          </ActionIcon>
        </div>
      </div>
      <div className="text-center justify-center">
        <span className="font-bold text-sm text-gray-800 block">
          {godFather == props.user.id
            ? 'Votez !'
            : 'Vote parrain'}
        </span>
        <div className="flex mt-3">
          {evolution.voteGodFather == null ? (
            <>
              {godFather == props.user.id ? (
                <>
                  <ActionIcon
                    color="gray"
                    onClick={() => isGodFatherDevmanOrMore ? handleClose(-1, -1, -1) : handleVote(-1, null, null)}
                    className="ml-3 hover:bg-red-500  flex-1"
                    variant="filled"
                  >
                    <ImArrowDownRight2 size={15} className="text-white" />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => isGodFatherDevmanOrMore ? handleClose(0, 0, 0) : handleVote(0, null, null)}
                    color="gray"
                    className="ml-3  flex-1"
                    variant="filled"
                  >
                    <ImArrowRight2 size={15} className="text-white" />
                  </ActionIcon>
                  <ActionIcon
                    onClick={() => isGodFatherDevmanOrMore ? handleClose(1, 1, 1) : handleVote(1, null, null)}
                    color="gray"
                    className="ml-3  hover:bg-green-500  flex-1"
                    variant="filled"
                  >
                    <ImArrowUpRight2 size={15} className="text-white" />
                  </ActionIcon>
                </>
              ) : (
                <span className="text-gray-500 text-xs text-center mx-auto">
                  En attente du vote parrain
                </span>
              )}
            </>
          ) : (
            <>
              {evolution.voteGodFather == -1 && (
                <>
                  <Button color="red">
                    <ImArrowDownRight2 size={18} className="text-white" />{' '}
                    <span className="ml-3">Rétrograder</span>
                  </Button>
                  {godFather == props.user.id && (
                    <ActionIcon
                      onClick={() =>
                        handleVote(
                          null,
                          evolution.voteDevMan,
                          evolution.voteXman
                        )
                      }
                      color="gray"
                      className="ml-3 my-auto hover:bg-red-500 flex-1"
                      variant="filled"
                    >
                      <IoRefreshSharp size={15} className="text-white" />
                    </ActionIcon>
                  )}
                </>
              )}
              {evolution.voteGodFather == 0 && (
                <>
                  <Button color="orange">
                    <ImArrowRight2 size={18} className="text-white" />{' '}
                    <span className="ml-3">Maintien</span>
                  </Button>
                  {godFather == props.user.id && (
                    <ActionIcon
                      onClick={() =>
                        handleVote(
                          null,
                          evolution.voteDevMan,
                          evolution.voteXman
                        )
                      }
                      color="gray"
                      className="ml-3 my-auto hover:bg-red-500 flex-1"
                      variant="filled"
                    >
                      <IoRefreshSharp size={15} className="text-white" />
                    </ActionIcon>
                  )}
                </>
              )}
              {evolution.voteGodFather == 1 && (
                <>
                  <Button color="lime">
                    <ImArrowUpRight2 size={18} className="text-white" />{' '}
                    <span className="ml-3">Promotion</span>
                  </Button>
                  {godFather == props.user.id && (
                    <ActionIcon
                      onClick={() =>
                        handleVote(
                          null,
                          evolution.voteDevMan,
                          evolution.voteXman
                        )
                      }
                      color="gray"
                      className="ml-3 my-auto hover:bg-red-500 flex-1"
                      variant="filled"
                    >
                      <IoRefreshSharp size={15} className="text-white" />
                    </ActionIcon>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      {!isGodFatherDevmanOrMore && (
        <>
          <div className="text-center justify-center mx-auto my-auto">
            <span className="font-bold text-sm text-gray-800 block">
              {props.user.role == 'ROLE_DEVMAN' ? 'Votez !' : 'Vote Devman'}
            </span>
            <div className="flex mt-3 text-center">
              {evolution.voteDevMan == null ? (
                <>
                  {evolution.voteGodFather != null &&
                  isDevOrMore ? (
                    <>
                      <ActionIcon
                        onClick={() =>
                          handleVote(
                            evolution.voteGodFather,
                            -1,
                            evolution.voteXman
                          )
                        }
                        color="gray"
                        className="ml-3 hover:bg-red-500  flex-1"
                        variant="filled"
                      >
                        <ImArrowDownRight2 size={15} className="text-white" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() =>
                          handleVote(
                            evolution.voteGodFather,
                            0,
                            evolution.voteXman
                          )
                        }
                        color="gray"
                        className="ml-3  flex-1"
                        variant="filled"
                      >
                        <ImArrowRight2 size={15} className="text-white" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() =>
                          handleVote(
                            evolution.voteGodFather,
                            1,
                            evolution.voteXman
                          )
                        }
                        color="gray"
                        className="ml-3 hover:bg-green-500 flex-1"
                        variant="filled"
                      >
                        <ImArrowUpRight2 size={15} className="text-white" />
                      </ActionIcon>
                    </>
                  ) : (
                    <span className="text-gray-500 text-xs text-center mx-auto">
                      En attente du vote devman
                    </span>
                  )}
                </>
              ) : (
                <>
                  {evolution.voteDevMan == -1 && (
                    <>
                      <Button color="red">
                        <ImArrowDownRight2 size={18} className="text-white" />{' '}
                        <span className="ml-3">Rétrograder</span>
                      </Button>
                      {isDevOrMore && (
                        <ActionIcon
                          onClick={() =>
                            handleVote(
                              evolution.voteGodFather,
                              null,
                              evolution.voteXman
                            )
                          }
                          color="gray"
                          className="ml-3 my-auto hover:bg-red-500 flex-1"
                          variant="filled"
                        >
                          <IoRefreshSharp size={15} className="text-white" />
                        </ActionIcon>
                      )}
                    </>
                  )}
                  {evolution.voteDevMan == 0 && (
                    <>
                      <Button color="orange">
                        <ImArrowRight2 size={18} className="text-white" />{' '}
                        <span className="ml-3">Maintien</span>
                      </Button>
                      {isDevOrMore && (
                        <ActionIcon
                          onClick={() =>
                            handleVote(
                              evolution.voteGodFather,
                              null,
                              evolution.voteXman
                            )
                          }
                          color="gray"
                          className="ml-3 my-auto hover:bg-red-500 flex-1"
                          variant="filled"
                        >
                          <IoRefreshSharp size={15} className="text-white" />
                        </ActionIcon>
                      )}
                    </>
                  )}
                  {evolution.voteDevMan == 1 && (
                    <>
                      <Button color="lime">
                        <ImArrowUpRight2 size={18} className="text-white" />{' '}
                        <span className="ml-3">Promotion</span>
                      </Button>
                      {isDevOrMore && (
                        <ActionIcon
                          onClick={() =>
                            handleVote(
                              evolution.voteGodFather,
                              null,
                              evolution.voteXman
                            )
                          }
                          color="gray"
                          className="ml-3 my-auto hover:bg-red-500 flex-1"
                          variant="filled"
                        >
                          <IoRefreshSharp size={15} className="text-white" />
                        </ActionIcon>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="text-center justify-center mx-auto my-auto">
            <span className="font-bold text-sm text-gray-800 block">
              {props.user.role == 'ROLE_XMAN' ? 'Votez !' : 'Vote Xman'}
            </span>
            <div className="flex mt-3">
              {evolution.voteXman == null ? (
                <>
                  {evolution.voteDevMan != null &&
                  props.user.roles[0] == 'ROLE_XMAN' ? (
                    <>
                      <ActionIcon
                        onClick={() =>
                          handleClose(
                            evolution.voteGodFather,
                            evolution.voteDevMan,
                            -1
                          )
                        }
                        color="gray"
                        className="ml-3 hover:bg-red-500 flex-1"
                        variant="filled"
                      >
                        <ImArrowDownRight2 size={15} className="text-white" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() =>
                          handleClose(
                            evolution.voteGodFather,
                            evolution.voteDevMan,
                            0
                          )
                        }
                        color="gray"
                        className="ml-3  flex-1"
                        variant="filled"
                      >
                        <ImArrowRight2 size={15} className="text-white" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() =>
                          handleClose(
                            evolution.voteGodFather,
                            evolution.voteDevMan,
                            1
                          )
                        }
                        color="gray"
                        className="ml-3 hover:bg-green-500  flex-1"
                        variant="filled"
                      >
                        <ImArrowUpRight2 size={15} className="text-white" />
                      </ActionIcon>
                    </>
                  ) : (
                    <span className="text-gray-500 text-xs text-center mx-auto">
                      En attente du vote Xman
                    </span>
                  )}
                </>
              ) : (
                <>
                  {evolution.voteXman == -1 && (
                    <>
                      <Button color="red">
                        <ImArrowDownRight2 size={18} className="text-white" />{' '}
                        <span className="ml-3">Rétrograder</span>
                      </Button>
                    </>
                  )}
                  {evolution.voteXman == 0 && (
                    <Button color="orange">
                      <ImArrowRight2 size={18} className="text-white" />{' '}
                      <span className="ml-3">Maintien</span>
                    </Button>
                  )}
                  {evolution.voteXman == 1 && (
                    <Button color="lime">
                      <ImArrowUpRight2 size={18} className="text-white" />{' '}
                      <span className="ml-3">Promotion</span>
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Item;
