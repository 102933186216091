import React, { useEffect } from "react";
import { formatNumberWithSpaceSeparator } from '../../utility/numberFormatting';
import {
    Button
} from "@mantine/core";
import storage from "../../auth/storage";
import {FiList} from "react-icons/fi";
import { Link } from "react-router-dom";
import { IoEyeSharp } from "react-icons/io5";

interface Props {
    element: any;
    year: any;
}

type MonthMap = {
  [key: string]: string;
};

const monthMaps: MonthMap = {
    Jan: "Janvier",
    Feb: "Février",
    Mar: "Mars",
    Apr: "Avril",
    May: "Mai",
    Jun: "Juin",
    Jul: "Juillet",
    Aug: "Août",
    Sep: "Septembre",
    Oct: "Octobre",
    Nov: "Novembre",
    Dec: "Décembre",
};

const CreditHistoryRow = (props: Props) => {

    const {isAdmin}: any = storage.getUser();
    const userCode = isAdmin ? "admin" : "expert-comptable";

    return (
        <tr key={props.element.id}>
            <td key={`${props.element.id}-td`} style={{ width: 100, textAlign:'center' }}>
                {props.element.user_id ? props.element.user_id : "0"}
            </td>
            {Object.keys(monthMaps).map((key, index) => {
                const amount = props.element[key];
                return (
                    <td key={`${props.element.id}-td-${index}`} style={{ width: 100, textAlign:'center' }}>
                        {amount ? formatNumberWithSpaceSeparator(amount) : "0"}
                    </td>
                );
            })}
            <td key={`${props.element.id}-td-action`} style={{ width: 100, textAlign:'center' }}>
                <Link target="_blank" to={`/auth/${userCode}/user/${props.element.user_id}/avoirs/${props.year}`}>
                    <Button
                        className="bg-transparent text-2xl hover:bg-blue-200"
                        radius="xl"
                    >
                        <IoEyeSharp style={{color: "#1e3a8a"}}/>
                    </Button>
                </Link>
            </td>
        </tr>
    );
};

export default CreditHistoryRow;
