import { REACT_APP_API_BASE_URL } from "../env";
import profilePictureTest from "../assets/images/profile-picture-test.png";

export function getUserRole(user: any): string {
    if (!user || !user.role) {
        return "";
    }

    if (user.role.slice(5) === "GUEST") {
        return "INVITÉ";
    } else {
        return user.role.slice(5);
    }
}

export function getUserFullName(user: any, defaultName: string = ""): string {
    if (!user) {
        return defaultName;
    }

    return `${user.firstName} ${user.lastName}`;
}

export function getInverseUserFullName(user: any, defaultName: string = ""): string {
    if (!user) {
        return defaultName;
    }

    return `${user.lastName} ${user.firstName}`;
}

export function getUserPicturePath(user: any): string {
    if (!user) {
        return profilePictureTest;
    }

    if (user.picture) {
        return `${REACT_APP_API_BASE_URL}/images/users/${user.picture}`;
    } else {
        return profilePictureTest;
    }
}