import React, {useEffect, useState} from 'react';
import {ActionIcon, Button} from "@mantine/core";
import {AiOutlineArrowLeft, AiOutlineCheck, AiOutlineClose, BsFillArrowLeftSquareFill} from "react-icons/all";
import dayjs from "dayjs";
import {STATUS} from "../../constants/goals_status";
import storage from "../../auth/storage";
import finishedGoals from "../../api/finishedGoals";
import { formatPeriodeDate2 } from "../../utility/dateUtility";

interface GoalItemProps {
    goal: any;
    userInfo: any;
    onClose: (goal: any) => any;
    onComplete: (goal: any) => any;
    onRealised: (goal: any) => any;
    onUserGodFatherClose: (goal: any) => any;
    isCompleted: boolean;
}

const GoalItem = (props: GoalItemProps) => {
    const user: any = storage.getUser();
    const [canVote, setCanVote] = useState(false);

    useEffect(() => {
        if(props.userInfo){
            if(props.userInfo.id == user.id){
                setCanVote(true);
            }
            if(props.userInfo.godFather && props.userInfo.godFather.id == user.id){
                setCanVote(true);
            }
        }
    },[props.userInfo])
    
    return (
        <>
            <li className={`border-gray-400 flex flex-row ${!props.isCompleted ? "mb-5" : ""}`}>
                <div
                    className="bg-white bg-opacity-70 hover:bg-opacity-100 rounded-md shadow p-3  shadow select-none bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-3">
                    <div className="flex flex-col text-base justify-start items-start mr-4 w-5/6">
                        <span className="text-gray-500 font-bold text-thirteen mb-1">{props.isCompleted ? props.goal.goal.description : props.goal.description}</span>
                       <div className="inline">
                           <span className="bg-gray-200 text-gray-400 font-bold text-ten px-2 py-1 m-1 rounded mt-2">{props.isCompleted ? props.goal.goal.type : props.goal.type}</span>
                           <span className="text-gray-400 font-bold text-ten px-2 py-1 m-1 rounded-md mt-2">{props.isCompleted ?  (<span className="text-xs text-yellow-600">
                                {props.goal.status == STATUS[0] && "En attente de validation"}
                                </span>) : null}
                           </span>
                       </div>
                    </div>
                    {props.isCompleted && canVote && (
                        <>
                            {(props.goal.status == STATUS[0] && (props.userInfo?.godFather && props.userInfo?.godFather?.id != user.id ) &&  props.userInfo?.id != 3) && (
                                <>
                                    <ActionIcon onClick={() => {
                                        props.onUserGodFatherClose(props.goal);
                                    }} className=" ml-auto mr-5" color="gray"
                                                variant="filled"><AiOutlineArrowLeft/></ActionIcon>
                                </>
                            )}
                            {(props.goal.status == STATUS[0] && ((props.userInfo?.godFather && props.userInfo?.godFather?.id == user.id) || props.userInfo?.id == 3)) &&  (
                                <>
                                    <ActionIcon onClick={() => {
                                        props.onUserGodFatherClose(props.goal);
                                    }} className=" ml-auto mr-5" color="gray"
                                                variant="filled"><AiOutlineArrowLeft/></ActionIcon>
                                    {/* <ActionIcon onClick={() => {
                                        props.onClose(props.goal);
                                    }} className="flex-1 ml-2" color="red"
                                                variant="filled"><AiOutlineClose/></ActionIcon> */}
                                    <ActionIcon onClick={() => {
                                        props.onComplete(props.goal);
                                    }} className="flex-1 ml-2 w-1 bg-lime-500" color="green"
                                                variant="filled"><AiOutlineCheck/></ActionIcon>
                                </>
                            )}
                            {props.goal.status == STATUS[1] && (
                                <ActionIcon className="flex-1 ml-2 w-1 bg-lime-500" color="green"
                                            variant="filled"><AiOutlineCheck/></ActionIcon>
                            )}
                            {props.goal.status == STATUS[2] && (
                                <ActionIcon className="flex-1 ml-2 w-1" color="red"
                                            variant="filled"><AiOutlineClose/></ActionIcon>
                            )}
                        </>)
                    }

                    {!props.isCompleted && canVote && (
                        <>
                            <Button onClick={() => {
                                props.onRealised(props.goal);
                            }} size="xs" compact className="bg-lime-500" color="green">Valider</Button>
                        </>
                    )}
                </div>
            </li>

            {props.isCompleted && canVote && (
                <span className="text-right block mb-2">
                    <span className="text-ten font-bold text-green-500">
                        {props.goal.status == STATUS[1] && "Objectif validé le " + formatPeriodeDate2(props.goal.validatedAt)}
                    </span>
                    <span className="text-ten  font-bold text-red-500">
                        {props.goal.status == STATUS[2] && "Objectif refusé le " + formatPeriodeDate2(props.goal.validatedAt)}
                    </span>
                </span>
            )}
        </>
    );
};

export default GoalItem;
